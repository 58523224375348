import React, { useState } from 'react'
import { InputItem, Button, WhiteSpace } from 'antd-mobile'
import { validateEmail, showAlert } from '../../../helpers/utils'
import { useTranslation } from 'react-i18next'
import classes from './CustomLogin.module.css'

const CustomLogin = ({ loading, authenticateUser, SSOEnabled, goBackToLoginOptions }) => {
  const { t } = useTranslation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleCustomLogin = () => {
    
    // This can be used later on to disable emails from being a username
    //const isValidEmail = validateEmail(username)
    // if (isValidEmail) {
    //   showAlert(t('warning'), t('usernameCanNotBeEmailError'), t('close'))
    //   return
    // }

    if (!password || !username) {
      showAlert(t('error'), t('no_empty_user'), t('close'))
      return false
    }

    let formData = {
      _type: 'login',
      _device: 'mobile',
      username: username,
      password: password
    }

    authenticateUser(formData)
  }

  return (
    <>
      <InputItem
        className='w-p100'
        type='text'
        placeholder={t('username')}
        onChange={username => setUsername(username)}
        value={username}
      />
      <WhiteSpace size='lg' />
      <div className={`w-p100 ${classes.passwordContainer}`}>
        <InputItem
          className='w-p100'
          type={showPassword ? 'text' : 'password'}
          placeholder={t('password')}
          onChange={password => setPassword(password)}
          value={password}
        />
        <i
          className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} fa-2x ${
            classes.eyeIcon
          }`}
          style={{ color: '#333' }}
          aria-hidden='true'
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      <WhiteSpace size='lg' />
      <Button
        loading={loading}
        className='w-p100'
        style={{
          backgroundColor: SSOEnabled ? '#2ecc71' : '#f035e0',
          color: '#fff'
        }}
        onClick={handleCustomLogin}
      >
        {t('login')}
      </Button>
      {SSOEnabled && <p className='fs-1rem' onClick={goBackToLoginOptions}>Geri</p>}
    </>
  )
}

export default CustomLogin
