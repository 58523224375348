import { useState } from 'react'

const multiTapCount = 2
const multiTapDelay = 300

export const useDoubleClick = doubleTapHandler => {
  const [lastPress, setLastPress] = useState(null)
  const [tapCount, setTapCount] = useState(0)

  const onDoubleTap = () => {
    const now = Date.now()

    setLastPress(now)
    if (now - lastPress <= multiTapDelay) {
      if (tapCount < multiTapCount - 1) {
        setTapCount(tapCount + 1)
      } else {
        setTapCount(0)

        doubleTapHandler && doubleTapHandler()
      }
    } else {
      setTapCount(1)
    }
  }

  return { onDoubleTap }
}
