import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from './custom-hooks/useQuery'
import './App.css'
import LoginPage from './pages/LoginPage/LoginPage'
import ShowcaseListPage from './pages/ShowcaseListPage/ShowcaseListpage'
import DashboardListPage from './pages/DashboardListPage/DashboardListPage'
import ThemePage from './pages/ThemePage/ThemePage'
import ShowcasePage from './pages/ShowcasePage/ShowcasePage'
import DashboardPage from './pages/DasboardPage/DashboardPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import ChangePasswordPage from './pages/profile/ChangePasswordPage/ChangePasswordPage'
import PersonalInfoPage from './pages/profile/PersonalInfoPage/PersonalInfoPage'
import ResetApplicationPage from './pages/profile/ResetApplicationPage/ResetApplicationPage'
import SessionControlPage from './pages/profile/SessionControlPage/SessionControlPage'
import NotificationSettings from './pages/profile/NotificationSettingsPage/NotificationSettingsPage'
import LanguageSettingPage from './pages/profile/LanguageSettingPage/LanguageSettingPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import { fetchThemes } from './store/actions/theme'
import { client } from './store/configureStore'
import { fetchSettings } from './store/actions/settings'
import { setBarsFalse } from './store/actions/showBars'
import { createUser } from './store/actions/auth'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function ScrollToTop ({ children }) {
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])
  return children
}

function App () {
  let directToDashboard = false;
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)
  const location=useLocation()
  const history = useHistory()
  const query = useQuery()
  
  client.interceptors.response.use(
    response => response,
    error => {
      if(get(error,'response.status') === 401 && get(error,'response.config.url') === '/users/currentUser/'){
        dispatch({
          type: 'LOGOUT'
        })
      }
      return Promise.reject(error);
    }
  )
  if (token) {
    client.defaults.headers.common['Authorization'] = `Token ${token}`
  }

  useEffect(() => {
    if (!token && location.pathname !== '/login' && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password' && !location.pathname.includes('public')) {
      history.push('/login')
    }
  }, [token, location.pathname, history, location])

  useEffect(() => {
    dispatch(fetchThemes())
    dispatch(fetchSettings())
  }, [dispatch])

  useEffect(() => {
    if(query.get('pwa_hide_bars') === 'true') {
      dispatch(setBarsFalse())
    }
    if(query.get('token')) {
      dispatch(createUser(query.get('token')))
      directToDashboard = true;
    }
  }, [dispatch, query.get('token')])

  useEffect(() => {
    if (token) {
      dispatch(createUser(token))
    }
  }, [dispatch, token])

  let routes = (
    <Switch>
      <Route path='/login'>
        <LoginPage />
      </Route>
      <Route path='/sso-login'>
        <LoginPage />
      </Route>
      <Route path='/forgot-password'>
        <ForgotPasswordPage />
      </Route>
      <Route path='/reset-password'>
        <ResetPasswordPage/>
      </Route>
      <Route path='/public/showcase/:showcaseId/:showcaseItemId/:dashletId'>
        <div className='pt-45'>
          <DashboardPage />
        </div>
      </Route>
      <Route path='/public/showcase/:showcaseId/:showcaseItemId/'>
        <div className='pt-45'>
          <DashboardPage />
        </div>
      </Route>
      <Route path='/public/showcase/:showcaseId/'>
        <div className='pt-45'>
         <ShowcasePage />
        </div>
      </Route>
      <Route path='/dashboard/:dashboardId'>
        <DashboardPage />
      </Route>
      <Route path='/public/dashboard/:dashboardId'>
        <DashboardPage />
      </Route>
      <Route path='/showcase/:showcaseId/:showcaseItemId/'>
        <DashboardPage />
      </Route>
      <Route path='/showcase/:showcaseId/:dashboardId/'>
        <DashboardPage />
      </Route>
      {!directToDashboard &&
        <Redirect to='/login' />
      } 
    </Switch>
  )

  if (token) {
    routes = (
      <div className='pt-45'>
        <Switch>
          <Route path='/themes'>
            <ThemePage />
          </Route>
          <Route path='/public/showcase/:showcaseId/:showcaseItemId/:dashletId'>
            <DashboardPage />
          </Route>
          <Route path='/public/showcase/:showcaseId/:showcaseItemId/'>
            <DashboardPage />
          </Route>
          <Route path='/showcase/:showcaseId/:showcaseItemId/:dashletId'>
            <DashboardPage />
          </Route>
          <Route path='/showcase/:showcaseId/:showcaseItemId/'>
            <DashboardPage />
          </Route>
          <Route path='/showcase/:showcaseId/:dashboardId/'>
            <DashboardPage />
          </Route>
          <Route path='/showcase/:showcaseId/'>
            <ShowcasePage />
          </Route>
          <Route path='/showcases'>
            <ShowcaseListPage />
          </Route>
          <Route path='/dashboards'>
            <DashboardListPage />
          </Route>
          <Route path='/profile/language-setting'>
            <LanguageSettingPage />
          </Route>
          <Route path='/profile/notification-settings'>
            <NotificationSettings />
          </Route>
          <Route path='/profile/session-control'>
            <SessionControlPage />
          </Route>
          <Route path='/profile/reset-application'>
            <ResetApplicationPage />
          </Route>
          <Route path='/profile/change-password'>
            <ChangePasswordPage />
          </Route>
          <Route path='/profile/personal-info'>
            <PersonalInfoPage />
          </Route>
          <Route path='/profile'>
            <ProfilePage />
          </Route>
          <Route path='/dashboard/:dashboardId'>
            <DashboardPage />
          </Route>
          <Route path='/public/dashboard/:dashboardId'>
            <DashboardPage />
          </Route>
           {!directToDashboard &&
            <Redirect to='/showcases' />
          } 
        </Switch>
      </div>
    )
  }

  return <ScrollToTop>{routes}</ScrollToTop>
}

export default App
