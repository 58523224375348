import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { NavBar, List } from 'antd-mobile'
import { dataStore } from '../../../store/configureStore'

const FilterList = ({ showcases, filterHandlers, showFilters }) => {
  const dashboardId = useSelector(state =>
    _.get(state, 'showcases.currentDashboard.id') || _.get(state, 'singledashboard.currentDashboard.id')
  )
  const { t } = useTranslation()

  const [groupedFilters, setGroupedFilters] = useState([])
  const [group_name, set_group_name] = useState(null)

  useEffect(() => {
    dataStore.sync(showcases.entries)
    let dashboard = dataStore.find('dashboards', dashboardId)

    let filters = _.filter(_.get(dashboard, 'filters') || [], filter => {
      return !_.get(filter, 'parent_filter.id')
    })

    let merged_filters = _.get(dashboard, 'merged_filters') || []

    let variables = _.map(_.get(dashboard, 'variable_filters', []), function (
      variable
    ) {
      _.set(variable, 'type', 'V')
      _.set(variable, '_id', _.get(variable, 'variable.id'))
      _.set(variable, 'viewId', _.get(variable, 'variable.view.id'))

      return variable
    })
    let customs = _.map(_.get(dashboard, 'expression_filters', []), function (
      custom
    ) {
      _.set(custom, 'type', 'F')
      _.set(custom, '_id', _.get(custom, 'expression.id'))
      _.set(custom, 'viewId', _.get(custom, 'expression.view.id'))

      return custom
    })
    filters = _.concat(filters, merged_filters, variables, customs)
    if (group_name) {
      filters = _.filter(filters, { group_name: group_name })
    }
    let groupedFilters = _.groupBy(filters, 'group_name')
    groupedFilters = _.sortBy(groupedFilters, [
      function (group) {
        return _.get(_.minBy(group, 'order'), 'order')
      }
    ])
    setGroupedFilters(groupedFilters)
  }, [dashboardId, group_name, showcases.entries])

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[showFilters &&
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => filterHandlers.setShowFilters(false)}
          />
        ]}
      >
        {t('Filters')}
      </NavBar>
      <List>
        {groupedFilters.map(group => (
          <FilterListGroup
            group={group}
            key={_.get(group, '0.group_name')}
            group_name={!group_name && _.get(group, '0.group_name')}
            dashboardId={dashboardId}
            filterHandlers={filterHandlers}
            set_group_name={set_group_name}
          />
        ))}
      </List>
    </>
  )
}

// eslint-disable-next-line react/display-name
const FilterListGroup = memo(({ group, group_name, dashboardId, filterHandlers, set_group_name }) => {
  const filters = group.filter(filter => !filter.is_hidden)

  if (!group_name) {
    return filters.map(filter => (
      <FilterListItem
        key={filter.id}
        filter={filter}
        dashboardId={dashboardId}
        filterHandlers={filterHandlers}
      />
    ))
  } else {
    return (
      <FilterListItem
        key={group_name}
        group_name={group_name}
        dashboardId={dashboardId}
        filterHandlers={filterHandlers}
        set_group_name={set_group_name}
      />
    )
  }
})

// eslint-disable-next-line react/display-name
const FilterListItem = memo(({ filter, group_name, set_group_name, filterHandlers: {setShowFilterItems, setFilter, setShowFilters }}) => {
  return (
    <List.Item
      arrow='horizontal'
      onClick={() => {
        if (group_name) {
          set_group_name(group_name)
        } else {
          setShowFilterItems(true)
          setFilter(filter)
          setShowFilters(false)
        }
      }}
    >
      {group_name || (filter && filter.custom_alias) || filter.alias}
    </List.Item>
  )
})

export default memo(FilterList)
