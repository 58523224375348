import _ from 'lodash'
import { dataStore, client } from '../configureStore'
import { addToRecentShowcases } from './recentShowcases'
import { setCurrentShowcaseMenu } from './showcasemenu'
import { fetchView } from './views'
import { all } from 'rsvp'
import { storyboardService } from "../../custom-hooks/useStoryBoard";

export const fetchShowcase = (id, refresh) => {
  const refreshString = refresh ? 'true' : 'false'
  return async (dispatch, getState) => {
    const { auth } = getState()
    const { userId, groups: userGroups } = auth
    let showcase = null
    try {
      const response = await client.get(
        `/showcaseviews/${id}/?refresh_cache=${refreshString}`
      )
      showcase = _.get(response, 'data')
      if (!showcase) {
        throw new Error('noDashboardExists')
      }

      if (showcase) {
        const title = _.get(showcase, 'data.attributes.title')
        const imageSource = _.get(showcase, 'data.attributes.default_thumbnail')
        dispatch(addToRecentShowcases(id, title, imageSource))

        const showcases = {
          entries: {
            data: [showcase.data],
            included: showcase.included
          }
        }
        dispatch({ type: 'CREATE_SHOWCASE', showcases })
      }
    } catch (error) {
      throw new Error(error)
    }

    const createShowcaseMenu = showcase => {
      let _children = []
      if (_.get(showcase, 'children.length') > 0) {
        _children = _.map(showcase.children, createShowcaseMenu)
      }

      const hideFromUsers = _.get(showcase, 'hide_from_users')
      if (hideFromUsers && hideFromUsers.includes(userId)) {
        return
      }

      const hideFromGroups = _.get(showcase, 'hide_from_groups')
      if (hideFromGroups) {
        const isInHiddenGroups = hideFromGroups.some(i =>
          userGroups.includes(_.toNumber(i))
        )
        if (isInHiddenGroups) {
          return
        }
      }

      return {
        id: showcase.id,
        title: showcase.title ? showcase.title : 'Child 1',
        item_display_type: showcase?.item_display_type,
        _children: _children,
        theme: _.get(showcase, 'showcase.theme_config.theme')
      }
    }

    const { showcases } = getState()
    dataStore.syncWithMeta(showcases.entries)
    showcase = dataStore.find('showcases', _.get(showcase, 'data.id'))

    let menu = _.filter(showcase._children, { parent: null })

    if (showcase.is_storyboard) {
      storyboardService.flushNavigation()
      storyboardService.setModel(showcase)
      storyboardService.generateNavigation(menu)
    }
    menu = _.map(menu, createShowcaseMenu)
    menu = _.filter(menu, item => item)

    dispatch(setCurrentShowcaseMenu(menu))
  }
}

export const fetchShowcaseItem = (id, appliedFilters) => {
  return async (dispatch, getState) => {
    const appendFilters = (filters, customs, variables) => {
      dispatch({
        type: 'FILTER_SERVICE_CALL',
        func: 'createFilters',
        params: {
          filters: filters,
          customs: customs,
          variables: variables,
          clone: 1
        }
      })
    }

    const { showcases } = getState()
    dataStore.syncWithMeta(showcases.entries)

    let showcaseitem = dataStore.find('showcaseitems', id)
    if(!showcaseitem) {
      return
    }
    while (showcaseitem?.item_display_type !== 'box' && !showcaseitem?.dashboard) {
      showcaseitem = showcaseitem.children[0]
    }
    storyboardService.setCurrent(showcaseitem)

    const dashboard = showcaseitem.dashboard
    let viewFilters = []

    const { auth } = getState()
    if (auth?.token) {
      client.defaults.headers.common['Authorization'] = `Token ${auth.token}`
    }

    if(!dashboard) {
      return
    }

    try {
      const response = await all(
        _.map(
          _.compact(_.uniq(_.map(dashboard.dashlets, 'dashlet.view.id'))),
          id => {
            return dispatch(fetchView(id))
          }
        )
      )

      if (response) {
        _.forEach(response, payload => {
          dataStore.syncWithMeta(_.get(payload, 'payload.data'))
        })

        let filters = _.map(_.get(dashboard, 'filters') || [], filter => {
          filter.info = _.get(filter, 'node.filter.info')
          if (_.isArray(_.get(filter, 'parent_filter.default_values'))) {
            filter.default_values = _.get(
              filter,
              'parent_filter.default_values'
            )
          }
          if (_.isArray(appliedFilters)) {
            let applied = _.find(appliedFilters, appliedFilter => {
              if (
                (_.get(filter, 'custom_alias') &&
                  _.get(filter, 'custom_alias') ===
                    _.get(appliedFilter, 'customAlias')) ||
                (_.get(filter, 'custom_alias') &&
                  _.get(filter, 'custom_alias') ===
                    _.get(appliedFilter, 'alias')) ||
                (_.get(filter, 'alias') &&
                  _.get(filter, 'alias') ===
                    _.get(appliedFilter, 'customAlias')) ||
                (_.get(filter, 'alias') &&
                  _.get(filter, 'alias') === _.get(appliedFilter, 'alias'))
              ) {
                return appliedFilter
              }
            })
            if (applied) {
              filter.default_values = _.get(applied, 'defaultValues')
            }
          }
          return filter
        })
        let merged_filters = _.get(dashboard, 'merged_filters', [])
        let variables = _.map(
          _.get(dashboard, 'variable_filters', []),
          function (variable) {
            _.set(variable, 'type', 'V')
            _.set(variable, '_id', _.get(variable, 'variable.id'))
            _.set(variable, 'viewId', _.get(variable, 'variable.view.id'))

            return variable
          }
        )

        let customs = _.map(
          _.get(dashboard, 'expression_filters', []),
          function (custom) {
            _.set(custom, 'type', 'F')
            _.set(custom, '_id', _.get(custom, 'expression.id'))
            _.set(custom, 'viewId', _.get(custom, 'expression.view.id'))

            return custom
          }
        )

        appendFilters(merged_filters)
        appendFilters(filters, customs, variables)

        _.each(_.uniq(_.map(dashboard.dashlets, 'dashlet.view')), view => {
          if (view) {
            viewFilters.push(...view._filters)
          }
        })

        appendFilters(viewFilters)
        dispatch({ type: 'CREATE_DASHBOARD', dashboard })
      } else {
        throw new Error()
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}
