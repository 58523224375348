import { Component } from 'react'
import { Modal } from 'antd-mobile'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    const { history, t } = this.props
    Modal.alert(t('error'), t('globalErrorAlert'), [
      {
        text: t('close'),
        onPress: () => {
          history.push('/showcases')
          window.location.reload()
        }
      }
    ])
  }

  render () {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default withRouter(withTranslation()(ErrorBoundary))
