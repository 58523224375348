import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, NavBar, Checkbox } from 'antd-mobile'
import axios from 'axios'
import { client } from '../../../store/configureStore'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { showAlert } from '../../../helpers/utils'

const NotificationSettingsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const user = useSelector(state => state.auth)

  const [appNotifications, setAppNotifications] = useState(
    user.allow_in_app_notifications || false
  )
  const [emailNotifications, setEmailNotifications] = useState(
    user.allow_email_notifications || false
  )

  useEffect(() => {
    const handleChangeSettings = async () => {
      user.allow_email_notifications = emailNotifications
      user.allow_in_app_notifications = appNotifications
      const url = `${client.defaults.baseURL}/users/${user.userId}/`
      const data = {
        id: user.userId,
        type: 'users',
        attributes: user
      }
      const headers = {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json'
      }
      axios.defaults.headers.common = { Authorization: `Token ${user.token}` }

      try {
        const response = await axios.patch(
          url,
          {
            data
          },
          {
            headers
          }
        )

        if (_.get(response, 'status') === 200) {
          const userData = {
            allow_in_app_notifications: appNotifications,
            allow_email_notifications: emailNotifications
          }
          dispatch({ type: 'CREATE_USER', userData })
        } else {
          showAlert('error', 'editNotificationSettingsError', 'close')
        }
      } catch (error) {
        showAlert('error', 'editNotificationSettingsError', 'close')
      }
    }

    handleChangeSettings()
  }, [appNotifications, dispatch, emailNotifications, user])

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => history.push('/profile')}
          />
        ]}
      >
        {t('notificationSettings')}
      </NavBar>
      <List>
        <Checkbox.CheckboxItem
          key={1}
          checked={appNotifications}
          onChange={() => setAppNotifications(!appNotifications)}
        >
          {t('allowAppNotifications')}
        </Checkbox.CheckboxItem>
        <Checkbox.CheckboxItem
          key={2}
          checked={emailNotifications}
          onChange={() => setEmailNotifications(!emailNotifications)}
        >
          {t('allowEmailNotifications')}
        </Checkbox.CheckboxItem>
      </List>
    </>
  )
}

export default NotificationSettingsPage
