export function addToFavoriteShowcases (id, title, imageSource) {
  return {
    type: 'ADD_TO_FAVORITE_SHOWCASES',
    id, 
    title, 
    imageSource
  }
}

export function removeFromFavoriteShowcases (id) {
  return {
    type: 'REMOVE_FROM_FAVORITE_SHOWCASES',
    id
  }
}
