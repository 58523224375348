import _ from 'lodash'

const defaultState = {'fetching': false, 'entries': {'data': []}}

export default function reducer (state = defaultState, action) {
  switch (action.type) {
    case 'LOAD_DATA':
      state.fetching = true
      return {...state}
    case 'CREATE_DATA':
      let hasDataKey = _.has(action.data, 'data')
      let data = _.get(action.data, 'data') || []
      if (hasDataKey && data.length) {
        _.forEach(action.data.data, function (data) {
          let hasData = _.find(state.entries.data, ['id', _.get(data, 'id')])
          if (data) {
            if (!hasData) {
              state.entries.data.push(data)
            } else {
              let dataIndex = _.findIndex(state.entries.data, hasData)
              if (dataIndex !== -1) {
                state.entries.data[dataIndex] = data
              }
            }
          }
        })
      }
      state.fetching = false
      return {...state}
    case 'PURGE_STATE':
      return defaultState
    default:
      return state
  }
}
