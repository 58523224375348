const initialState = {
  entries: { data: [], included: [] }
}

export default function showcase (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_SINGLE_DASHBOARD':
      return { ...state, currentDashboard: action.dashboard }
    case 'DESTROY_SINGLE_DASHBOARD':
      return { ...state, currentDashboard: null }
    case 'PURGE_SINGLE_DASHBOARD':
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
