const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_TO_FAVORITE_DASHBOARDS':
      return [
        ...state.filter(item => item?.id),
        {
          id: action.id,
          title: action.title,
          default_thumbnail: action.imageSource
        }
      ]
    case 'REMOVE_FROM_FAVORITE_DASHBOARDS':
      return state.filter(item => item?.id).filter(dashboard => dashboard?.id !== action.id)
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
