const initialState = {
    Values: null,
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'FILTERED_ALL_VALUES':
        return {
          ...state,
          ...action.Values
        }
      default:
        return state 
  } 
}