import _ from 'lodash'
export function setCurrentShowcaseMenu (showcase) {

  let flat = []
  let flatten = function(items) {
    _.each(items, (item) => {
      flat.push(item)
      flatten(_.get(item, '_children'))
    })
  }
  flatten(showcase)
  return {
    type: 'SET_CURRENT_SHOWCASE_MENU',
    payload: {items: showcase, flat:flat}
  }
}
