import React, { useState, useEffect, useMemo } from 'react'
import { NavBar, Grid, ActivityIndicator } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import MenuSidebar from '../../components/menu-sidebar/MenuSidebar'
import { useTranslation } from 'react-i18next'
import BottomTab from '../../components/showcase-list/BottomTab/BottomTab'
import useWindowDimensions from '../../custom-hooks/useWindowDimenisons'
import Card from '../../components/showcase-list/Card/Card'
import { fetchShowcases } from '../../store/actions/listofshowcases'
import InfiniteScroll from 'react-infinite-scroll-component'
import Searchbar from '../../components/showcase-list/SearchBar/SearchBar'
import classes from './ShowcaseListpage.module.css'

const ShowcaseList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let { width } = useWindowDimensions()
  const listofshowcases = useSelector(state => state.listofshowcases)
  const { showcaseList } = listofshowcases

  const {
    searchValue,
    currentPage,
    hasMoreShowcases,
    owner,
    ordering
  } = listofshowcases.searchParameters

  const favoriteShowcaseList = useSelector(state => state.favoriteShowcases)
  const recentShowcaseList = useSelector(state => state.recentShowcases)

  const [headerBarTitle, setHeaderBarTitle] = useState('allShowcases')
  const [showSidebar, setShowSidebar] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filteredShowcaseList, setFilteredShowcaseList] = useState(showcaseList)
  const [noData, setNoData] = useState(false)
  const [tabButton, setTabButton] = useState('all')
  const [showSearchbar, setShowSearchbar] = useState(false)

  const showBars = useSelector((state) => {
    return _.get(state, 'showBars.showBars')
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [tabButton])

  let pageSize = 10
  if (width >= 960) {
    pageSize = 20
  }

  useEffect(() => {
    if (tabButton === 'all') {
      setHeaderBarTitle('allShowcases')
      const fetchShowcaseList = async () => {
        setLoading(true)
        dispatch({ type: 'DESTROY_SHOWCASE_LIST' })
        dispatch({ type: 'SET_CURRENT_PAGE', currentPage: 1 })
        try {
          await dispatch(fetchShowcases(pageSize))
        } catch (error) {
          setNoData(true)
        } finally {
          setLoading(false)
        }
      }

      fetchShowcaseList()
    }
  }, [searchValue, dispatch, tabButton, pageSize, owner, ordering])

  useEffect(() => {
    if (tabButton === 'favorites') {
      setHeaderBarTitle('favoriteShowcases')
      dispatch({ type: 'DESTROY_SHOWCASE_LIST' })
      const filteredList = favoriteShowcaseList
        .filter(item => item?.title)
        .filter(item =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      setFilteredShowcaseList(filteredList)
    } else if (tabButton === 'recent') {
      setHeaderBarTitle('recentlyOpenedShowcases')
      dispatch({ type: 'DESTROY_SHOWCASE_LIST' })
      const filteredList = recentShowcaseList
        .filter(item => item?.title)
        .filter(item =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      setFilteredShowcaseList(filteredList)
    }
  }, [
    searchValue,
    dispatch,
    favoriteShowcaseList,
    recentShowcaseList,
    tabButton
  ])

  useEffect(() => {
    if (showcaseList) {
      setLoading(false)
    }
    if (Array.isArray(showcaseList) && !showcaseList.length) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    if (tabButton === 'all') {
      setFilteredShowcaseList(showcaseList)
    }
  }, [showcaseList, tabButton])

  const loader = useMemo(
    () => (
      <div className={`center-element ${classes.miniLoaderContainer}`}>
        <ActivityIndicator animating size='small' />
      </div>
    ),
    []
  )

  let columnNum = Math.ceil(width / 350)

  let message
  if (
    tabButton === 'favorites' &&
    !favoriteShowcaseList.length &&
    !showSearchbar
  ) {
    message = 'noFavoriteShowcases'
  } else if (
    tabButton === 'recent' &&
    !recentShowcaseList.length &&
    !showSearchbar
  ) {
    message = 'noRecentShowcases'
  }

  let children
  if ((loading && currentPage === 1) || noData) {
    children = (
      <div className={`center-element ${classes.loaderContainer}`}>
        {loading ? (
          <ActivityIndicator animating size='large' />
        ) : (
          <span>{t('no_home_showcase')}</span>
        )}
      </div>
    )
  } else if (message) {
    children = (
      <div className={`center-element ${classes.loaderContainer}`}>
        <span>{t(message)}</span>
      </div>
    )
  } else {
    children = (
      <div className={classes.container} style={{marginTop: showSearchbar ? 45 : 0}}>
        <InfiniteScroll
          dataLength={filteredShowcaseList?.length || 0}
          next={() => {
            if (tabButton !== 'all') {
              return
            }

            const fetchNewShowcases = async () => {
              dispatch({
                type: 'SET_CURRENT_PAGE',
                currentPage: currentPage + 1
              })
              try {
                await dispatch(fetchShowcases(pageSize))
              } catch (error) {
                setNoData(true)
              }
            }

            if (hasMoreShowcases && tabButton === 'all') {
              fetchNewShowcases()
            }
          }}
          hasMore={tabButton === 'all' && hasMoreShowcases}
          loader={loader}
        >
          <Grid
            square={true}
            hasLine={false}
            data={filteredShowcaseList}
            columnNum={columnNum}
            renderItem={dataItem => <Card dataItem={dataItem} />}
          />
        </InfiniteScroll>
      </div>
    )
  }

  return (
    <div className='h-p100'>
      { showBars && <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-bars'
            className='fa fa-bars fa-lg'
            onClick={() => setShowSidebar(showSide => !showSide)}
          ></i>
        ]}
        rightContent={[
          <i
            key='fa-bars'
            className='fa fa-search fa-lg'
            onClick={() => {
              if (showSidebar) {
                setShowSidebar(false)
              }
              dispatch({ type: 'RESET_SEARCH_PARAMS' })
              setShowSearchbar(showSearch => !showSearch)
            }}
          ></i>
        ]}
      >
        <span>{t(headerBarTitle)}</span>
      </NavBar>}
      {showSearchbar && <Searchbar tabButton={tabButton} />}
      <MenuSidebar
        showSidebar={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
      />
      {children}
      {showBars && <BottomTab tabButton={tabButton} setTabButton={setTabButton} />}
    </div>
  )
}

export default ShowcaseList
