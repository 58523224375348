import React, { useState, createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ChartWidget from '../chart/ChartWidget'
import GridWidget from '../grid/GridWidget'
import HTMLWidget from '../htmlwidget/HtmlWidget'
import RegionalMapWidget from '../regionalmap/RegionalMapWidget'
import InfocellWidget from '../infocell/InfocellWidget'
import MustHaveFilterWidget from '../musthavefilterwidget/MustHaveFilterWidget'
import { FilterService } from '../../../store/reducers/currentfilters'
import useWindowDimensions from '../../../custom-hooks/useWindowDimenisons'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
// import { useTheme } from '../../../custom-hooks/useTheme'

export const DashletCountContext = createContext(1)
export const ChartContext = createContext(null)

const DashletWidget = ({
  dashboardDashlet,
  navigation,
  filteringObject,
  setFilteringObject,
  numColumns,
  drillThrough,
  dashletCount,
  isMoreMarginBottom
}) => {
  // const { themeBackgroundColor } = useTheme(true)
  const  { dashletId } = useParams()
  const dashletData = dashletId ? dashboardDashlet : dashboardDashlet
  let { dashlet, dashboard, html_widget } = dashletData
  const [chart, setChart] = useState(null)
  
  const updateChart = (chart) => {
    setChart(chart)
  }

  const currentfilters = useSelector(state => state.currentfilters)

  const { height } = useWindowDimensions()

  let type, secondary_type, isGauge
  if (dashlet) {
    type = dashlet.type
    secondary_type = dashlet.secondary_type
    isGauge = type === 'info_cell' && secondary_type === 'gauge'
  }

 let cardHeight = useMemo(() => {
    let cardHeight
    if (dashletCount === 1) {
      cardHeight = height
    } else if (dashletCount > 1 && dashletCount < 5) {
      cardHeight = height / 2 - 60
    } else {
      cardHeight = height / 3
    }

    if (cardHeight < 300) {
      cardHeight = 300
    }
  
    if (html_widget) {
      const htmlContent = html_widget.html_content
      let isHalfSizedHtmlWidget = htmlContent?.includes('halfsize')
      let isQuarterSizedHtml = htmlContent?.includes('quartersize')
      const isCustomHeight = htmlContent?.includes('mobileheight')
      let customHeight
      if (isCustomHeight) {
        customHeight = htmlContent.match(/\[(.*)mobileheight\]/) 
        if (Array.isArray(customHeight)) {
          customHeight = customHeight[0]
          customHeight = +customHeight.replace(/\D/g, '')
        }
      }
  
      if (isHalfSizedHtmlWidget) {
        cardHeight = cardHeight / 2
      } else if (isQuarterSizedHtml) {
        cardHeight= cardHeight / 4
      } else if (customHeight) {
        cardHeight= customHeight 
      }
    }
  
    let isSparkline =  _.get(dashlet, 'measures.0.is_sparkline')
    const measures = dashlet?.measures
    if (_.get(measures, 'length') > 1) {
      isSparkline = false
    }
  
  
    if (type === 'info_cell' && !isGauge && !isSparkline) {
      cardHeight = 'fit-content'
    }

    return cardHeight
  }, [dashlet, dashletCount, height, html_widget, isGauge, type])
  
  if (!(dashlet || html_widget)) {
    return false
  }

  const hasMustHaveFilter = (currentfilters, dashlet) => {
    if (dashlet.must_have_filter === -1 || dashlet.must_have_filter > 0) {
      return !FilterService.isMustHaveFilterMet(
        currentfilters,
        dashlet.must_have_filter,
        1
      )
    }
  }

  let propsObject = {
    model: dashlet,
    navigation,
    dashboard,
    key: dashboardDashlet.id,
    filteringObject,
    setFilteringObject,
    numColumns,
    drillThrough,
    dashletCount
  }

  let Dashlet
  if (html_widget) {
    Dashlet = HTMLWidget
    propsObject.model = html_widget
  } else if (hasMustHaveFilter(currentfilters, dashlet)) {
    Dashlet = MustHaveFilterWidget
  } else if (type === 'chart' || isGauge) {
    Dashlet = ChartWidget
    propsObject.isGauge = isGauge
  } else if (type === 'grid' || type === 'detail' || type === 'pivot') {
    Dashlet = GridWidget
  } else if (type === 'info_cell') {
    Dashlet = InfocellWidget
  } else if (type === 'map') {
    Dashlet = RegionalMapWidget
  }

  if (dashletId) {
    return (
      <ChartContext.Provider value={{chart, updateChart}}>
      <div
        style={{
          height: '100%',
          padding: 5
        }}
      >
        <Dashlet {...propsObject} />
      </div>
      </ChartContext.Provider>
    )
  }

  return (
    <DashletCountContext.Provider value={dashletCount}>
      <ChartContext.Provider value={{chart, updateChart}}>
        <div
          style={{
            height: cardHeight,
            padding: 10,
            margin: '-5px 10px',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            border: '0.5px solid #ddd',
            marginBottom: isMoreMarginBottom ? '55px' : '-5px'
          }}
        >
          <Dashlet {...propsObject} />
        </div>
      </ChartContext.Provider >
    </DashletCountContext.Provider>
  )
}

export default React.memo(DashletWidget)
