import React from 'react'
import { Flex } from 'antd-mobile'
import {
  addToFavoriteShowcases,
  removeFromFavoriteShowcases
} from '../../../store/actions/favoriteShowcases'
import AppConfig from '../../../config/appConfig'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classes from './Card.module.css'

const Card = ({ dataItem: showcase }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const currentApiUrl = AppConfig.baseUrl
  let showcaseImageSource =
    currentApiUrl + '/media/dashboard/default-dashboard-thumbnail.png'

  if (showcase.default_thumbnail.includes('thumbnail-showcase')) {
    showcaseImageSource = 'default'
  } else {
    showcaseImageSource = currentApiUrl + showcase.default_thumbnail
  }

  const favoriteShowcases = useSelector(state => state.favoriteShowcases)
  const favoriteShowcasesIds = favoriteShowcases
    .map(showcase => showcase?.id)
    .filter(item => item)

  const toggleFavoriteShowCase = (id, title, imageSource) => {
    if (favoriteShowcasesIds.includes(id)) {
      dispatch(removeFromFavoriteShowcases(id))
    } else {
      dispatch(addToFavoriteShowcases(id, title, imageSource))
    }
  }

  return (
    <div className={classes.cardContainer}>
      <Flex justify='end'>
        <i
          className={`${classes.bookmarkIcon} ${
            favoriteShowcasesIds.includes(showcase.id)
              ? 'fa fa-bookmark fa-lg'
              : 'fa fa-bookmark-o fa-lg'
          }`}
          onClick={() =>
            toggleFavoriteShowCase(
              showcase.id,
              showcase.title,
              showcase.default_thumbnail
            )
          }
        />
      </Flex>
      <div
        className={classes.imageContainer}
        onClick={() => {
          dispatch({ type: 'RESET_SEARCH_PARAMS' })
          history.push(`/showcase/${showcase.id}`, {
            isShowcaseItem: false,
            showcaseTitle: showcase.title
          })
        }}
      >
        {showcaseImageSource === 'default' ? (
          <div className={classes.defaultThumbnailContainer}>
            <i
              className={`fa fa-puzzle-piece fa-3x ${classes.defaultThumbnail}`}
            />
          </div>
        ) : (
          <img
            src={showcaseImageSource}
            alt='showcase-card'
            className={classes.image}
          />
        )}
      </div>
      <p className={classes.cardTitle}>{showcase.title}</p>
    </div>
  )
}

export default React.memo(Card)
