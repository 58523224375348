import { get } from 'lodash'
import { client } from '../configureStore'
import AppConfig from '../../config/appConfig'

export const fetchFilteredData = (url) => {
  client.defaults.baseURL = AppConfig.apiUrl // https://t.ebalina.com/api/v1
  
  return async (dispatch)  => {
    try { 
      if(url){ 
        const response = await client.get(url)
        const { values } = get(
          response,
          'data.data.attributes'
        )
        dispatch({
          type: 'FILTERED_ALL_VALUES',
          Values: {
            values,
          }
        })
      }
    }
     catch (error) {
      console.error(error)
    }
  }
}
