import React, { useState, useEffect, useCallback, useMemo, useContext, useRef } from 'react'
import { fetchData } from '../../../store/actions/data'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import DS from '../../../models/model-data'
import { FilterService } from '../../../store/reducers/currentfilters'
import { useTheme } from '../../../custom-hooks/useTheme'
import EchartsContainer from './EchartsContainer'
import Card from '../../dashlet-card/Card/Card'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Flex } from 'antd-mobile'
import { useParams } from 'react-router-dom'
import { ChartContext } from '../dashlet-widget/DashletWidget'
import { useStoryBoard } from '../../../custom-hooks/useStoryBoard'
import i18next from 'i18next'
import { options } from 'numeral'

const ChartWidget = ({
  model,
  dashboard,
  isSparkline,
  isGauge,
  drillThrough
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filterQuery = useSelector(state =>
    FilterService.getQueryParams(state.currentfilters)
  )
  const slaveDateState = useSelector(state => _.get(state.currentfilters, 'slaveState'))
  const query = useSelector(state => {
    return state.rowClickCheck.filterQuery
  })
  
  const currentfilters = useSelector(state => state.currentfilters)
  const  { dashletId, showcaseId } = useParams()
  const { chart }= useContext(ChartContext)
  const showcase = useSelector((state) => {
    return _.find(_.get(state, 'showcases.entries.data'), { id: showcaseId })
  })
  const language = useSelector(state => _.get(state, 'settings.language')) || i18next.language
  const { goNext } = useStoryBoard()

  const [data, setData] = useState(null)
  const [noData, setNoData] = useState(false)
  const [chartOptions, setChartOptions] = useState(null)
  const [selectedPointCategories, setSelectedPointCategories] = useState([])
  const [showFilterIcon, setShowFilterIcon] = useState(false)

  const getData = useCallback(async () => {
    try {
      const response = await dispatch(
        fetchData(
          model.id,
          false,
          model.type,
          _.get(dashboard, 'id'),
          filterQuery === '' ? query : filterQuery,
          model.date_aggregate
        )
      )
      const data = _.get(response, 'payload.data')
      const rows = _.get(data, 'data.0.attributes.rows') || {}
      if (Object.keys(rows).length) {
        setData(data)
        setNoData(false)
      } else {
        setNoData(true)
      }
    } catch (error) {
      console.log(error)
      setNoData(true)
    }
  }, [dispatch, model.id, model.type, model.date_aggregate, dashboard, filterQuery, query])

  const sizeRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    if(sizeRef.current){
      setWidth(sizeRef.current.width)
    }
  }, []);

  useEffect(() => {
    getData()
  }, [getData])

  const getRelatedFilter = useMemo(() => {
    if (data) {
      const dashletData = data.data
      const drilldownLevel = _.get(
        dashletData,
        '0.attributes.meta.drilldown_level'
      )
      const dimension =
        drilldownLevel > -1
          ? model.drilldowndimensions[drilldownLevel]
          : model.dimensions[0]

      if (!dimension || !dimension.dimension) {
        return
      }
      const revQueryName = _.get(dimension, 'dimension.reversed_query_name')
      let filter = _.find(
        currentfilters.filters,
        currentFilter =>
          _.get(currentFilter, 'node.reversed_query_name') === revQueryName
      )
      if (filter) {
        filter = _.clone(filter)
        filter.parentFilter = null
        filter.affectedFrom = []
      }
      return filter
    }
  }, [
    data,
    model.drilldowndimensions,
    model.dimensions,
    currentfilters.filters
  ])

  const { showcaseTheme } = useTheme(true)
  const createOption = useCallback(() => {
    let dashletData = data.data
    if (_.get(dashletData, '0.attributes.rows.m0')) {
      let dashlet_serializer = DS.belongsTo('dashlet')
      model._drilldown_level_ = _.get(
        dashletData,
        '0.attributes.meta.drilldown_level'
      )
      let serialized_dashlet = dashlet_serializer(model)
      if(!dashletId) {
        serialized_dashlet.immediate_slicer = true
      }
      serialized_dashlet.userLang = language
      let options = _.map(dashletData, dashletDatum => {
        return {
          data: dashletDatum.attributes,
          dashlet: serialized_dashlet,
          theme: showcaseTheme,
          relatedFilter: getRelatedFilter || null
        }
      })
      options.forEach(option => {
        _.set(option, 'data.meta.slaveDateState', slaveDateState)
        _.set(option, 'data.meta.getRelatedFilter', getRelatedFilter)
      });
      options = _.filter(options, datum => {
        return _.get(datum, 'data.meta.type') !== 'motion'
      })
      if (isGauge || isSparkline) {
        options = [_.head(options)]
      }
      setChartOptions(options)
    }
  }, [
    data,
    getRelatedFilter,
    isGauge,
    isSparkline,
    model,
    showcaseTheme,
    language,
    dashletId
  ])

  useEffect(() => {
    if (_.get(data, 'data')) {
      createOption()
    }
  }, [data, createOption])

  const _drilldown = useCallback(() => {
    if (selectedPointCategories !== undefined) {
      const filter = getRelatedFilter
      if (filter) {
        dispatch({
          type: 'FILTER_SERVICE_CALL',
          func: 'setValues',
          params: {
            id: filter._id || filter._id_,
            clone: 1,
            values: selectedPointCategories
          }
        })
      }
      setShowFilterIcon(false)
      const isStoryBoard = showcase?.attributes?.is_storyboard
      if (isStoryBoard) {
        goNext()
      }
    }
  }, [selectedPointCategories, getRelatedFilter, dispatch, showcase, goNext])

  const actions = useMemo(
    () => ({
      set: function (path, value) {
        const func = path.substr(0, 1).toUpperCase() + path.substr(1)
        try {
          // eslint-disable-next-line no-eval
          eval(`set${func}`)(value)
        } catch (e) {
          //
        }
      },
      drilldown: _.debounce(_drilldown, 100),
      resetSelection () {
        setSelectedPointCategories([])
        setShowFilterIcon(false)
        if (chart && !chart.isDisposed()) {
          let chartOption = chart.getOption()
          if (chartOption.metaType !== 'master') {
            chart.dispatchAction({type: 'restore'})
          }
        }
      },
      drillThrough (from, to) {
        drillThrough(from, to)
      },
      setSlaveDateState: (props) => {
        dispatch({
          type: 'FILTER_SERVICE_CALL',
          func: 'setSlaveState',
          params: {
            newSlaveState: props,
          }
        })
      },
      setDataURL: function (uri) {
        // const shareOptions = {
        //   title: model.title,
        //   url: uri
        // }
        // Share.open(shareOptions)
        //   .then(() => {
        //     //console.log(res)
        //   })
        //   .catch(() => {
        //     //console.error(err)
        //   })
      }
    }),
    [_drilldown, chart, drillThrough, dispatch]
  )
  const removeNavigator = useCallback(options => {
    while (options.length > 1) {
      const masterOption = options.find(option => option.data.meta.type === 'master')
      options = options.filter(option => option.data.meta.type !== 'master')
      _.forEach(options, option => _.set(option, 'data.meta.inShowcasePage', true))
      _.forEach(options, option => _.set(option, 'data.meta.masterOption', masterOption))
    }
    return options
  }, [])


  const charts = useMemo(() => {
    if (chartOptions?.length) {
      let options = chartOptions

      if (chartOptions.length > 1) {
        options = removeNavigator(chartOptions)
      }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      className='h-p100 w-p100'
    >
      {options.map((chartOption, idx) => (
        <EchartsContainer
          key={idx}
          option={chartOption}
          actions={actions}
        />
      ))}
    </div>
  )

    }
  }, [chartOptions, actions, removeNavigator, dashletId])

  if (noData) {
    return (
        <Card noBorder={isSparkline}>
        <Flex justify='center' align='items' style={{ height: '100%' }}>
          <span>{t('no_data_dashlet', { dashlet: model.title })}</span>
        </Flex>
      </Card>
    )
  }

  if (_.get(chartOptions, 'length')) {
    if (isSparkline) {
      return (
        <Card noBorder={isSparkline} >
          <EchartsContainer option={chartOptions[0]} actions={actions}/>
        </Card>
      )
    }
    return (
      <Card
        model={model}
        showFilterIcon={showFilterIcon}
        showResetIcon={showFilterIcon}
        actions={actions}
      >
        {charts}
      </Card>
    )
  }

  return null
}
export default React.memo(ChartWidget)
