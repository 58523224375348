import React, { useCallback } from 'react'
import { InputItem, List, Flex } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import classes from './RangeInputWidget.module.css'

const RangeInputWidget = ({
  min,
  selectedValues,
  max,
  setSelectedValues,
  isSingle
}) => {
  const { t } = useTranslation()

  const setMinValue = useCallback(
    value => {
      if (!value || value === '') {
        value = 'min'
      }
      let values = []
      if (isSingle) {
        values = [value, value]
      } else {
        values =
          selectedValues.length > 1 ? [value, selectedValues[1]] : [value, max]
      }
      setSelectedValues(values)
    },
    [isSingle, setSelectedValues, selectedValues, max]
  )

  const setMaxValue = useCallback(
    value => {
      if (!value || value === '') {
        value = 'max'
      }
      let values =
        selectedValues.length > 0 ? [selectedValues[0], value] : [min, value]
      setSelectedValues(values)
    },
    [selectedValues, min, setSelectedValues]
  )

  return (
    <List>
      <Flex className='pl-10'>
        <span className={classes.label}>
          {isSingle ? t('Value') : t('Min')}
        </span>
        <InputItem
          type='number'
          placeholder={min ? min.toString() : ''}
          onChange={value => setMinValue(value)}
          value={
            selectedValues.length && selectedValues[0] !== 'min'
              ? _.toString(selectedValues[0])
              : ''
          }
        />
      </Flex>
      {!isSingle && (
        <Flex className='pl-10'>
          <span className={classes.label}>{t('Max')}</span>
          <InputItem
            type='number'
            placeholder={max ? max.toString() : ''}
            onChange={value => setMaxValue(value)}
            value={
              selectedValues.length > 0 && selectedValues[1] !== 'max'
                ? _.toString(selectedValues[1])
                : ''
            }
          />
        </Flex>
      )}
    </List>
  )
}

export default React.memo(RangeInputWidget)
