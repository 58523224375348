import _ from 'lodash'
import { client } from '../configureStore'
import AppConfig from '../../config/appConfig'

export const fetchThemes = () => {
  return async dispatch => {
    try {
      const themesUrl = `${AppConfig.apiUrl}/themes/`
      const response = await client.get(themesUrl, {
        headers: {
          Accept: '*/*',
          Authorization: ''
        }
      })
      const themes = _.get(response, 'data.data')
      if (!themes) {
        throw new Error('no_themes')
      }
      dispatch({ type: 'CREATE_THEMES', themes })
    } catch (error) {
      console.error(error)
    }
  }
}

export const changeTheme = selectedThemeId => {
  return {
    type: 'SET_THEME',
    selectedThemeId
  }
}
