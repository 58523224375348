import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { fetchGridData } from '../../../store/actions/data'
import { useSelector, useDispatch } from 'react-redux'
import { client } from '../../../store/configureStore'
import { rowClickedCounter, rowClickedFilterQuery, rowClickedFilterArray } from '../../../store/actions/rowClickCheck'
import _ from 'lodash'
import Row from './Row'
import { FilterService } from '../../../store/reducers/currentfilters'
import HeaderRow from './HeaderRow'
import { ActivityIndicator, Button, Flex } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import classes from './GridWidget.module.css'
import Card from '../../dashlet-card/Card/Card'
import {  useParams } from 'react-router-dom'

const minFirstCellWidth = 250
const minGeneralCellWidth = 75

const GridWidget = ({
  model,
  dashboard,
  drillThrough,
  filteringObject,
  setFilteringObject,
  dashletCount,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const  { showcaseItemId, dashletId } = useParams()

  const filterQuery = useSelector(state => 
    FilterService.getQueryParams(state.currentfilters)
  )

  const [noDataMessage, setNoDataMessage] = useState('')
  const [gridAttributes, setGridAttributes] = useState({})
  const [tableHeaders, setTableHeaders] = useState([])
  const [tableRows, setTableRows] = useState([])
  const [tableUpdated, setTableUpdated] = useState(false)
  const [columnWidths, setColumnWidths] = useState([])
  const [totalWidth, setTotalWidth] = useState(0)
  const [drawRows, setDrawRows] = useState(false)
  const [cellCounter, setCellCounter] = useState(0)
  const [sortIcons, setSortIcons] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [hasPagination, setHasPagination] = useState(false)
  const [dashletData, setDashletData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [headersMetaDimension, setHeadersMetaDimension] = useState(null)
  const [headersMetaHeaders, setHeadersMetaHeaders] = useState(null)
  const [ogRows, setOgRows] = useState(null)
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null)
  const [selectedRowValue, setSelectedRowValue] = useState(null)
  const [componentWidth, setComponentWidth] = useState(0)

  const { showcases, showcasemenu } = useSelector(state => state)
  const currentShowcaseItemIndex = _.findIndex(showcasemenu?.flat, { id: showcaseItemId })
  const showcaseChildren = _.get(showcases, `entries.data.${currentShowcaseItemIndex}.relationships._children.data`)

  const rowClickCheck = useSelector(state => {
    return state.rowClickCheck.Check
  })

  const query = useSelector(state => {
    return state.rowClickCheck.filterQuery
  })

  const filters = useSelector(state => {
    return state.currentfilters.filters
  })

  const widthsClone = useRef({})

  useEffect(()=>{
    setTableUpdated(false)
  }, [])

  const getCalculatedMetaHeaders = useCallback((metaHeaders, measureMeta) => {
    return _.map(metaHeaders, function (metaHeader, level) {
      let isLast = level === metaHeaders.length - 1 || metaHeaders.length === 1
      metaHeader.push({
        level: level,
        index: metaHeader[metaHeader.length - 1].index + 1,
        parent: metaHeader[metaHeader.length - 1].parent + 1,
        alias: isLast ? measureMeta.alias : '',
        custom_alias: isLast ? measureMeta.custom_alias : '',
        dashlet_id: measureMeta.dashlet_id,
        colspan: 1,
        type: isLast ? 'M' : 'D',
        formatting: measureMeta.formatting,
        suffix: measureMeta.suffix,
        prefix: measureMeta.prefix,
        thresholds: measureMeta.thresholds,
        conditional_formatting: measureMeta.conditional_formatting
      })
      return metaHeader
    })
  }, [])

  const getCalculatedRows = useCallback((aliasMap, rows, query) => {
    let cellFunc = function () {
      let index = null
      let columnNames = _.toArray(arguments)

      let row = this
      _.some(aliasMap, function (aliases, rowIndex) {
        if (aliases.length === columnNames.length) {
          let intersection = _.intersection(aliases, columnNames)
          if (intersection.length === columnNames.length) {
            index = rowIndex
            return true
          }
        }
      })
      if (index || index === 0) {
        return row[index]
      } else {
        return 0
      }
    }
    return _.map(rows, function (row) {
      // eslint-disable-next-line no-unused-vars
      let COL = cellFunc.bind(row)
      // eslint-disable-next-line no-eval
      row.push(eval(query))
      return row
    })
  }, [])

  const appendCalculatedMeasures = useCallback(
    resourceHash => {
      let calculatedMeasureMeta = _.get(
        resourceHash,
        'attributes.meta.calculated_measure_meta'
      )
      let rows = _.get(resourceHash, 'attributes.rows')
      _.each(calculatedMeasureMeta, function (measureMeta) {
        try {
          let query = measureMeta.query
          let aliasMap = _.get(resourceHash, 'attributes.meta.alias_map')
          let metaHeaders = _.get(resourceHash, 'attributes.meta.headers')
          rows = getCalculatedRows(aliasMap, rows, query)
          metaHeaders = getCalculatedMetaHeaders(metaHeaders, measureMeta)
          _.set(resourceHash, 'rows', rows)
          _.set(resourceHash, 'attributes.meta.headers', metaHeaders)
        } catch (e) {
          console.log(e)
        }
      })
    },
    [getCalculatedRows, getCalculatedMetaHeaders]
  )

  const getGridRows = useCallback(
    (rowData, colTotals) => {
      if (
        ['columns', 'both'].includes(model.totals_type) &&
        _.isArray(colTotals) &&
        colTotals.length
      ) {
        if (model.totals_position === 'top') {
          rowData.unshift(...colTotals)
        } else {
          rowData.push(...colTotals)
        }
      }
      let rows = rowData.map(item => {
        if (typeof item[0] === 'object' && item[0].collapsible) {
          item[0].isCollapsed = false
        }
        return item
      })
      return rows
    },
    [model]
  )

  const setGridDataValue = useCallback(
    (responseData, tUpdated) => {
      let data = responseData.data
      let gridData
      let headers = []
      if (_.filter(data, { id: model.id + '-pivot' })[0]) {
        gridData = _.filter(data, { id: model.id + '-pivot' })[0].attributes
        setGridAttributes(gridData)
        _.forEach(_.get(gridData, 'meta.headers'), function (headerData) {
          let headerArr = []
          _.forEach(headerData, headerCell => {
            let arrayLength = 0
            if (headerCell.colspan !== undefined) {
              arrayLength = headerCell.colspan ? headerCell.colspan - 1 : 1
            }
            headerCell.value = headerCell.alias
            headerCell.noBorder = false
            headerArr.push(headerCell)
            headerArr = _.concat(
              headerArr,
              new Array(arrayLength).fill({ value: '', noBorder: true })
            )
          })
          headers.push(headerArr)
        })
      } else if (_.filter(data, { id: model.id + '-detail' })[0]) {
        gridData = _.filter(data, { id: model.id + '-detail' })[0].attributes
        _.forEach(_.get(gridData, 'meta.headers'), function (headerData) {
          let headerArr = _.map(headerData, headerCell => {
            headerCell.value = headerCell.alias
            headerCell.noBorder = false
            return headerCell
          })
          headers.push(headerArr)
        })
      }
      let rows = getGridRows(
        gridData.rows,
        _.get(gridData, 'meta.footer.colTotals')
      )
      if(!tUpdated) {
        setTableHeaders(headers)
        setTableRows(rows)
      }
      if (_.get(gridData, 'meta.pagination')) {
        setHasPagination(true)
        setCurrentPage(_.get(gridData, 'meta.pagination.page'))
        setTotalPage(_.get(gridData, 'meta.pagination.total'))
      }

      if (
        _.get(gridData, 'meta.__headers') &&
        _.get(model, 'measures.length') > 0
      ) {
        setHeadersMetaHeaders(_.get(gridData, 'meta.__headers.data'))
        setHeadersMetaDimension(_.get(gridData, 'meta.__headers.dimension'))
      }

      setOgRows(rows)
    },
    [getGridRows, model]
  )

  const getAliasList = useCallback((metaHeaders, header, headerIndex) => {
    let aliases = []
    if (header) {
      aliases.push(header.custom_alias || header.alias)
      if (header.parent || header.parent === 0) {
        let parent = _.find(metaHeaders[headerIndex - 1], function (
          parentHeader
        ) {
          return (
            parentHeader.index === header.parent &&
            parentHeader.dashlet_id === header.dashlet_id
          )
        })
        if (parent) {
          aliases.unshift(...getAliasList(metaHeaders, parent, headerIndex - 1))
        }
      } else {
        if (headerIndex > 0) {
          aliases.unshift(...new Array(headerIndex).fill(''))
        }
      }
    }
    return aliases
  }, [])

  const setAliasMap = useCallback(
    resourceHash => {
      let metaHeaders = _.get(resourceHash, 'attributes.meta.headers')
      let lastHeader = _.last(metaHeaders)
      let aliasMap = _.map(lastHeader, function (header) {
        return getAliasList(metaHeaders, header, metaHeaders.length - 1)
      })
      _.set(resourceHash, 'attributes.meta.alias_map', aliasMap)
    },
    [getAliasList]
  )

  const getData = useCallback(
    async (type, page = 1) => {
      let filteringQuery = ''
      if(_.get(_.keys(filteringObject), 'length')) {
        filteringQuery = decodeURI(FilterService._dictToUrl(filteringObject))
      }
      const fetchData = async () => {
        try {
          const response = await dispatch(
            fetchGridData(
              model,
              false,
              type,
              _.get(dashboard, 'id'),
              filterQuery,
              filteringQuery,
              page
            )
          )
          const data = _.get(response, 'payload.data')
          const rows = _.get(data, 'data.0.attributes.rows')
          if (rows?.length) {
            let resourceHash = _.get(data, 'data.0')
            if (_.get(resourceHash, 'attributes.is_warning')) {
              setNoDataMessage(_.get(resourceHash, 'attributes.message'))
              setDashletData(null)
              return
            }
            if (_.get(resourceHash, 'attributes.meta.grid_type') === 'pivot') {
              setAliasMap(resourceHash)
              if (
                _.get(resourceHash, 'attributes.meta.calculated_measure_meta')
              ) {
                appendCalculatedMeasures(resourceHash)
              }
            }
            setDashletData(data)
            setGridDataValue(data, tableUpdated)
          } else {
            setNoDataMessage('no_data_dashlet')
          }
        } catch (error) {
          console.error(error)
        }
      }

      fetchData()
    },
    [
      filteringObject,
      dispatch,
      model,
      dashboard,
      filterQuery,
      setGridDataValue,
      setAliasMap,
      appendCalculatedMeasures
    ]
  )

  useEffect(() => {
    if (model.measures.length !== 0) {
      getData('pivot')
    }
  }, [getData, model])

  useEffect(() => {
    if (model.measures.length === 0) {
      getData('detail')
    }
  }, [model, getData])

  const fetchExpandedRowURL = useCallback(
    (id, pivotFilter, pivotHeaders, pivotHeadersDim, rowL, filterQuery, dashboardId) => {
      let partial = ('' + Math.random()).substring(2, 7)
      let url = `/data/?expanded_type=row&id=${id}&partial=${partial}&type=pivot&rowLevel=${rowL}&pivot_headers=${pivotHeaders}&pivot_headers_dimension=${pivotHeadersDim}&filtering=${pivotFilter}`
      if(filterQuery){
        url = url + `&filters=${filterQuery}`
      }
      if(dashboardId){
        url = url + `&dashboard=${dashboardId}`

      }
      return url
      },
    []
  )

  const appendGridData = useCallback(
    (afterRow, newRows, highFiltering, dimension) => {
      let rowsToAdd = getGridRows(newRows)
      let tRows = _.clone(tableRows)
      rowsToAdd.forEach((row, idx) => {
        row[0].highFilterList = dimension.highFilterList
          ? JSON.parse(JSON.stringify(dimension.highFilterList))
          : []
        if (row[0].highFilterList.indexOf(highFiltering) === -1) {
          row[0].highFilterList.push(highFiltering)
        }
        tRows.splice(afterRow + 1 + idx, 0, row)
      })
      setTableRows(tRows)
      setTableUpdated(true)
    },
    [getGridRows, tableRows]
  )

  const handleCollapse = useCallback(
    (index, value, filtering, collapsed, rowL, dimension) => {
      let reversed_query_name = 'de0'
      let columnDimensionList = _.filter(_.get(model, 'view.nodes'), {
        id: String(headersMetaDimension)
      })
      if (columnDimensionList.length > 0) {
        reversed_query_name = _.get(
          columnDimensionList,
          '0.reversed_query_name'
        )
      } else {
        let columnDimensionListAlternative = _.filter(model.dimensions, {
          id: String(headersMetaDimension)
        })
        if (columnDimensionListAlternative.length > 0) {
          reversed_query_name = _.get(
            columnDimensionListAlternative,
            '0.dimension.reversed_query_name'
          )
          if (!_.get(columnDimensionListAlternative, '0.is_dimension')) {
            reversed_query_name = 'de0'
          }
        }
      }
      if (!collapsed) {
        let url = fetchExpandedRowURL(
          model.id,
          encodeURIComponent(filtering),
          encodeURIComponent(headersMetaHeaders),
          encodeURIComponent(reversed_query_name),
          rowL,
          filterQuery,
          _.get(dashboard,'id')
        )

        const getData = async () => {
          try {
            const response = await client.get(url)
            if (_.get(response, 'data.data.0.attributes')) {
              appendGridData(
                index,
                _.get(response, 'data.data.0.attributes.rows'),
                filtering,
                dimension
              )
            }
          } catch (error) {
            console.error(error)
          }
        }
        getData()
      } else {
        let tRows = tableRows.filter(item => {
          return (
            !item[0].highFilterList ||
            (item[0].highFilterList &&
              item[0].highFilterList.indexOf(filtering) === -1)
          )
        })
        setTableRows(tRows)
      }
    },
    [
      model,
      headersMetaDimension,
      fetchExpandedRowURL,
      headersMetaHeaders,
      appendGridData,
      tableRows
    ]
  )

  const sortColumn = useCallback(
    async index => {
      // Don't sort first column
      let newSortIcons = []
      if (index === 0) {
        return
      }

      let sorted
      if (sortIcons[index] === 'fa fa-chevron-down') {
        newSortIcons[index] = null
        sorted = ogRows
      } else {
        sorted = _.sortBy(ogRows, object => {
          return object[index]
        })
        if (!sortIcons[index] || sortIcons[index] === null) {
          newSortIcons[index] = 'fa fa-chevron-up'
        } else if (sortIcons[index] === 'fa fa-chevron-up') {
          newSortIcons[index] = 'fa fa-chevron-down'
          sorted = _.reverse(sorted)
        }
      }
      setTableRows(sorted)
      setSortIcons(newSortIcons)
    },
    [ogRows, sortIcons]
  )

  const onCellLayout = useCallback(
    (width, idx) => {
      if (!widthsClone.current[idx]) {
        widthsClone.current[idx] = [width]
      }
      setCellCounter(Object.keys(widthsClone.current).length)
    },
    [widthsClone]
  )

  const showRows = useCallback(
    (widths, componentWidth) => {
      const paddingHorizontal = 8
      const windowWidth = componentWidth - paddingHorizontal
      let newColWidths = Object.values(widths).flat()
      let totalWidth = _.sum(newColWidths)

      if (model.auto_width || _.sum(newColWidths) < windowWidth) {
        let extraWidth = 0
        newColWidths = _.map(newColWidths, width => {
          let w = (width / totalWidth) * windowWidth
          if (w < 40) {
            extraWidth += 40 - w
            w = 40
          } else {
            if (extraWidth) {
              if (w - extraWidth > 40) {
                w = w - extraWidth
                extraWidth = 0
              } else {
                extraWidth -= w - 40
                w = 40
              }
            }
          }
          return w
        })
        totalWidth = windowWidth + extraWidth
      }

      setColumnWidths(newColWidths)
      setTotalWidth(totalWidth)
      setDrawRows(true)
    },
    [model]
  )

  useEffect(() => {
    showRows(widthsClone.current, componentWidth)
  }, [componentWidth, showRows, cellCounter])

  const goToPage = useCallback(
    n => {
      let type= 'detail'
      if (model.measures.length !== 0) {
        type = 'pivot'
      }
      getData(type, n)
    },
    [getData, model]
  )

  const onCellClick = useCallback(
    (item, idx) => {
      try {
        if (_.get(gridAttributes, 'meta.grid_type') !== 'pivot') {
          return
        }
        if (
          _.get(item, '0.value') === selectedRowValue
        ) {
          setSelectedColumnIndex(null)
          setSelectedRowValue(null)
          setFilteringObject({})
          dispatch({type: 'FILTER_SERVICE_CALL', func: 'setValues', params: {id: item[0].dimension, clone: 1, values: []}})
        } else {
          setSelectedColumnIndex(idx)
          setSelectedRowValue(_.get(item, '0.value'))
          let headers = _.clone(_.get(gridAttributes, 'meta.headers'))
          let categories = {}
          const lastHeader = headers.pop()
          let columnHeader = _.get(lastHeader, idx)
          while (
            headers.length &&
            columnHeader &&
            columnHeader.parent !== null
          ) {
            const next_header = headers.pop()
            columnHeader = _.find(next_header, { id: columnHeader.parent })
          }
          if (columnHeader && _.get(columnHeader, 'type') === 'D') {
            let columnNode = _.toString(_.get(columnHeader, 'dimension'))
            if (columnNode) {
              categories[columnNode] = _.get(columnHeader, 'alias')
            }
          }
          let rowNode = _.toString(_.get(item, '0.dimension'))
          if (rowNode) {
            categories[rowNode] = _.get(item, '0.value')
          }
          dispatch({
            type: 'FILTER_SERVICE_CALL',
            func: 'setValues',
            params: {
              id: item[0].dimension,
              clone: 1,
              values: Object.values(categories)
            }
          })
          setFilteringObject(categories)
        }
      } catch (e) {
        //
      }
    },
    [gridAttributes, selectedRowValue, selectedColumnIndex, setFilteringObject, showcaseChildren, rowClickCheck]
  )

  const actions = useMemo(
    () => ({
      drillThrough (from, to) {
        drillThrough(from, to)
      }
    }),
    [drillThrough]
  )

  const textProps = useMemo(() => !model.wordwrap ? { numberOfLines: 1, ellipsizeMode: 'tail' } : {}, [model]) 

  const isDetail = useMemo(() => {
    if (!_.get(model, 'measures.length')) {
      return true
    }
    return false
  }, [model])

  const renderHeaderRow = useCallback(
    (item, index) => {
      const { table_column_alignment_headers, auto_width, wordwrap } = model

      return (
        <HeaderRow
          onCellLayout={onCellLayout}
          key={index}
          sortColumn={sortColumn}
          item={item}
          index={index}
          sortIcons={sortIcons}
          columnWidths={columnWidths}
          autoWidth={auto_width}
          wordwrap={wordwrap}
          alignment={table_column_alignment_headers}
        />
      )
    },
    [onCellLayout, sortColumn, sortIcons, columnWidths, model]
  )

  const renderRow = useCallback(
    (item, index) => {
      const {
        auto_width: autoWidth,
        table_column_horizontal_alignment: horizontalAlignment,
        table_column_vertical_alignment: verticalAlignment
      } = model

      return (
        <Row
          key={index}
          onCellClick={onCellClick}
          item={item}
          index={index}
          tableHeaders={_.last(tableHeaders)}
          textProps={textProps}
          totalWidth={totalWidth}
          rowWidths={columnWidths}
          minFirstCellWidth={minFirstCellWidth}
          minGeneralCellWidth={minGeneralCellWidth}
          handler={handleCollapse}
          autoWidth={autoWidth}
          horizontalAlignment={horizontalAlignment}
          verticalAlignment={verticalAlignment}
          isDetail={isDetail}
        />
      )
    },
    [
      onCellClick,
      tableHeaders,
      textProps,
      totalWidth,
      columnWidths,
      handleCollapse,
      model,
      isDetail
    ]
  )

  if (noDataMessage) {
    return (
      <Card>
        <Flex justify='center' align='items' style={{ height: '100%' }}>
          <span>{t(noDataMessage, { dashlet: model.title })}</span>
        </Flex>
      </Card>
    )
  }

  if (dashletData) {
    return (
      <Card model={model} actions={actions}>
        <div
          ref={node => setComponentWidth(node?.offsetWidth)}
          style={{ height: '100%' }}
        >
          <div className={classes.tableWrapper} style={{height: '95%'}}>
            {tableHeaders.map((header, index) =>
              renderHeaderRow(header, index)
            )}
            {drawRows && tableRows.map((row, index) => renderRow(row, index))}
          </div>
          {(dashletId || dashletCount === 1) && hasPagination && (
            <div className={classes.tableControlsWrapper}>
              <div className={classes.tableControlsButton}>
                {currentPage > 1 && (
                  <Button
                    size='small'
                    onClick={() => {
                      goToPage(1)
                    }}
                  >
                    <i className='fa fa-angle-double-left' />
                  </Button>
                )}
              </div>
              <div className={classes.tableControlsButton}>
                {currentPage > 1 && (
                  <Button
                    size='small'
                    onClick={() => {
                      goToPage(currentPage - 1)
                    }}
                  >
                    <i className='fa fa-angle-left' />
                  </Button>
                )}
              </div>
              <div className={classes.tableControlsPageInfo}>
                <span>
                  {currentPage} / {totalPage}
                </span>
              </div>
              <div className={classes.tableControlsButton}>
                {currentPage !== totalPage && (
                  <Button
                    size='small'
                    onClick={() => {
                      goToPage(currentPage + 1)
                    }}
                  >
                    <i className='fa fa-angle-right' />
                  </Button>
                )}
              </div>
              <div className={classes.tableControlsButton}>
                {currentPage !== totalPage && (
                  <Button
                    size='small'
                    onClick={() => {
                      goToPage(totalPage)
                    }}
                  >
                    <i className='fa fa-angle-double-right' />
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>
    )
  }

  return null
}

export default React.memo(GridWidget)
