import dashletMeasure from './dashletmeasure'


export default function (DS) {
  return {
    //dashlet: DS.belongsTo('dashlet'),
    filters: DS.hasMany('dashletmeasurefilter', { inverse: 'measure' }),
    formatting: DS.attr('string'),
    custom_abbreviation: DS.attr('string'),
    entityType: 'hintMeasure',
    percentage_mode: DS.attr('string'),
    is_percentage_function: DS.attr('boolean'),
    order: DS.attr('number'),
    ...dashletMeasure(DS), // Extended dashlet measure
  }
}