import { Modal } from 'antd-mobile'
import { get } from 'lodash'

function dictToUrl (a) {
  let s = []
  let add = function (k, v) {
    v = typeof v === 'function' ? v() : v
    v = v === null ? '' : v === undefined ? '' : v
    s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v)
  }
  let buildParams = function (prefix, obj) {
    let i, len, key

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(prefix, obj[i])
        }
      } else if (String(obj) === '[object Object]') {
        for (key in obj) {
          buildParams(prefix + '[' + key + ']', obj[key])
        }
      } else {
        add(prefix, obj)
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value)
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key])
      }
    }
    return s
  }
  return buildParams('', a).join('&')
}

const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const showAlert = (title, message, buttonText) => {
  const alertInstance = Modal.alert(title, message, [
    { text: buttonText, onPress: () => alertInstance.close(), style: 'default' }
  ])
}

const createTitle = (model, appliedFilters) => {
  if (!model) {
    return
  }
  let title = get(model, 'title')
  const isAutoTitle =  get(model, 'auto_title')
  const passiveTitle = get(model, 'passive_title')
  if (!isAutoTitle && passiveTitle) {
    title = passiveTitle
  }

  if (title?.includes('[[filter|') && appliedFilters?.length) {
    let nodeId = title.match(/\[\[.*\]/)?.[0]
    nodeId = nodeId.match(/\d+/)[0]
    const filter = appliedFilters.find(filter => filter.node.id === nodeId)
    const filterValue = filter?.values?.[0]
    if (filterValue) {
      title = title.replace(/\[\[.*\]/, filterValue)
    }
  }

  title = title.replace('&nbsp;', ' ')
  return title
}

const parseQuery = (queryString) => {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export { dictToUrl, validateEmail, showAlert, createTitle, parseQuery }
