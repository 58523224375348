import React from 'react'
import { Flex } from 'antd-mobile'
import {
  addToFavoriteDashboards,
  removeFromFavoriteDashboards
} from '../../../store/actions/favoriteDashboards'
import AppConfig from '../../../config/appConfig'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classes from './Card.module.css'

const Card = ({ dataItem: dashboard }) => {
  const dispatch = useDispatch()
  const history = useHistory()

 const currentApiUrl = AppConfig.baseUrl
 let dashboardImageSource
 
  if (dashboard.thumbnail === 'thumbnail-dashboard') {
    dashboardImageSource = 'default'
  } else {
    dashboardImageSource = currentApiUrl + dashboard.thumbnail
  }

  const favoriteDashboards = useSelector(state => state.favoriteShowcases)
  const favoriteDashboardsIds = favoriteDashboards
    .map(dashboard => dashboard?.id)
    .filter(item => item)

  const toggleFavoriteDashboard = (id, title, imageSource) => {
    if (favoriteDashboardsIds.includes(id)) {
      dispatch(removeFromFavoriteDashboards(id))
    } else {
      dispatch(addToFavoriteDashboards(id, title, imageSource))
    }
  }

  return (
    <div className={classes.cardContainer}>
      {/* <Flex justify='end'>
        <i
          className={`${classes.bookmarkIcon} ${
            favoriteDashboardsIds.includes(dashboard.id)
              ? 'fa fa-bookmark fa-lg'
              : 'fa fa-bookmark-o fa-lg'
          }`}
          onClick={() =>
            toggleFavoriteDashboard(
              dashboard.id,
              dashboard.title,
              dashboard.default_thumbnail
            )
          }
        />
      </Flex> */}
      <div
        className={classes.imageContainer}
        onClick={() => {
          dispatch({ type: 'RESET_SEARCH_PARAMS' })
          history.push(`/dashboard/${dashboard.id}`, {
            isShowcaseItem: false,
            dashboardTitle: dashboard.title
          })
        }}
      >
        {dashboardImageSource === 'default' ? (
          <div className={classes.defaultThumbnailContainer}>
            <i
              className={`fa fa-puzzle-piece fa-3x ${classes.defaultThumbnail}`}
            />
          </div>
        ) : (
          <img
            src={dashboardImageSource}
            alt='dashboard-card'
            className={classes.image}
          />
        )}
      </div>
      <p className={classes.cardTitle}>{dashboard.title}</p>
    </div>
  )
}

export default React.memo(Card)
