const initialState = {
    Check: 0,
    filterQuery: '',
    filterCheckbox: '',
    filterArray: []
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'ROW_CLICKED':
        return {
          ...state,
          Check: action.Check
        }
      case 'FILTER_QUERY':
        return {
          ...state,
          filterQuery: action.filterQuery
        }
      case 'FILTER_CHECKBOX_QUERY':
        return {
          ...state,
          filterCheckbox: action.filterCheckbox
        }
      case 'FILTER_ARRAY_ADD':
        return {
          ...state,
          filterArray: [...state.filterArray, action.newItem]
        }
      default:
        return state 
  } 
}