import { client, dataStore } from '../configureStore'
import _ from 'lodash'
import AppConfig from '../../config/appConfig'

export function fetchDashboards (pageSize = 10) {
  return async (dispatch, getStore) => {
    client.defaults.baseURL = AppConfig.apiUrl
    dataStore.reset()

    const {
      currentPage: page = 1,
      searchValue = ''
    } = getStore().listofdashboards.searchParameters

    try {
      const response = await client.get(
        `/listofdashboards/?ordering=-updated_at&page=${page}&page_size=${pageSize}&search=${searchValue}`
      )
      const newDashboards = _.get(response, 'data')
      const currentPage = newDashboards?.meta?.pagination?.page
      const totalPages = newDashboards?.meta?.pagination?.pages

      if (currentPage === totalPages) {
        dispatch({ type: 'SET_HAS_MORE_PAGE_TO_FALSE' })
      }

      let listofdashboards = getStore().listofdashboards.entries?.data || []
      listofdashboards = {
        links: newDashboards.links,
        meta: newDashboards.meta,
        data: [...listofdashboards, ...newDashboards.data]
      }

      dispatch({
        type: 'CREATE_DASHBOARDS',
        listofdashboards
      })

      dataStore.syncWithMeta(listofdashboards)
      let dashboardList = dataStore.findAll('listofdashboards')

      dispatch({
        type: 'SET_ALL_DASHBOARDS',
        dashboardList
      })
    } catch (error) {
      throw new Error(error)
    }
  }
}
