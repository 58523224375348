import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Card from '../../dashlet-card/Card/Card'
import classes from './MustHaveFilterWidget.module.css'

const MustHaveFilterWidget = ({ model }) => {
  const { t } = useTranslation()

  let mustHaveFilter = model.must_have_filter
  let mustHaveFilterText = t('useAnyFilter')
  if (mustHaveFilter > 0) {
    let node = _.find(model.view.nodes, ['id', mustHaveFilter.toString()])
    mustHaveFilterText = t('filter_by_x_to_see_visualization', {
      filter: node.alias
    })
  }

  return (
    <Card model={model}>
      <div className={classes.container}>
        <div>
          <i className='fa fa-exclamation-circle fa-2x' aria-hidden='true'></i>
        </div>
        <p className='fs-1rem'>
          {t('dashlet_wont_show_without_filters')} {mustHaveFilterText}
        </p>
      </div>
    </Card>
  )
}

export default React.memo(MustHaveFilterWidget)
