import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Drawer, List } from 'antd-mobile'
import { useHistory, useRouteMatch } from 'react-router-dom'
import _ from 'lodash'

const DashboardListSidebar = ({ showSidebar, closeSidebar }) => {
  const { url } = useRouteMatch()
  const showcasemenu = useSelector(state => state.showcasemenu)
  const showcaseId = useSelector(state =>
    _.get(state, 'showcases.entries.data.0.id')
  )
  const history = useHistory()

  const navigate = useCallback(
    (scid, title) => {
      if (url.includes('public')) {
        history.push(`/public/showcase/${showcaseId}/${scid}`)
      } else {
        history.push(`/showcase/${showcaseId}/${scid}`)
      }
      closeSidebar()
    },
    [history, closeSidebar, showcaseId, url]
  )

  const listItem = useCallback(
    ({ scid, title, childrenSC }) => {
      return (
        <List.Item
          key={scid}
          multipleLine
          onClick={() => {
            if (!_.get(childrenSC, 'length')) {
              closeSidebar()
              navigate(scid, title)
            }
          }}
        >
          <span>{title}</span>
          {_.get(childrenSC, 'length') > 0 &&
            childrenSC.filter(sc => sc !== undefined).map(({ id: scid, title, _children: childrenSC }) => {
              return listItem({ scid, title, childrenSC })
            })}
        </List.Item>
      )
    },
    [navigate, closeSidebar]
  )

  const Sidebar = useMemo(
    () => (
      <List style={{ marginTop: '45px', height: '100%' }}>
        {showcasemenu.items.map(
          ({ id: scid, title, _children: childrenSC }) => (
            <div key={scid}>{listItem({ scid, title, childrenSC })}</div>
          )
        )}
      </List>
    ),
    [showcasemenu.items, listItem]
  )

  return (
    <Drawer
      className='my-drawer'
      style={{
        minHeight: document.documentElement.clientHeight,
        zIndex: showSidebar ? 6 : -1,
        position: 'fixed'
      }}
      position='right'
      enableDragHandle
      contentStyle={{ color: '#A6A6A6', textAlign: 'center', paddingTop: 42 }}
      sidebar={Sidebar}
      open={showSidebar}
      onOpenChange={closeSidebar}
    >
      <span />
    </Drawer>
  )
}

export default React.memo(DashboardListSidebar)
