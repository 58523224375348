
export default function(DS) {
  return {
    id: DS.attr('string'),
    view: DS.attr('number'),
    alias: DS.attr('string'),
    full_name: DS.attr('string'),
    help_text: DS.attr('string'),
    data_type: DS.attr('string'),
    field: DS.belongsTo('field'),
    formatting: DS.attr('string'),
    custom_abbreviation: DS.attr('string'),
    sortable: DS.attr('number'),
    regional_map: DS.belongsTo('regionalmap'),
    date_part: DS.attr('string'),
    sort_by: DS.attr('string'),
    sort_direction: DS.attr('string'),
    prefix: DS.attr('string'),
    suffix: DS.attr('string'),
    types: DS.attr(),
    dimension_type: DS.attr('string'),
    custom_sorting_values: DS.attr('string'),
    custom_color_values: DS.attr('string'),
    has_custom_colors: DS.attr('boolean'),
    is_temporal: DS.attr('boolean'),
    created_at: DS.attr('date'),
    updated_at: DS.attr('date'),
    root_parent: DS.attr(),
    is_hierarchical: DS.attr('boolean'),
    is_default_datacell: DS.attr('boolean'),
    has_child: DS.attr('boolean'),
    filter: DS.belongsTo('filter'),
    dashletrestriction: DS.belongsTo('dashletrestriction'),
    leaves: DS.attr(),
    aggregator: DS.attr(),
    srid: DS.attr('number'),
    sdo_gtype: DS.attr('number'),
    table_name: DS.attr('string'),
    field_name: DS.attr('string'),
    reversed_query_name: DS.attr('string'),
    query_name: DS.attr('string'),
    sdo_gtype_name: DS.attr('string')
  }
}
