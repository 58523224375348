import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams, useHistory, useRouteMatch, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { fetchShowcaseItem, fetchShowcase } from '../../store/actions/showcases'
import { fetchSingleDashboard } from '../../store/actions/singledashboard'
import DashletWidgets from '../../components/dashlet-widgets/DashletWidgets'
import { NavBar, ActivityIndicator, Flex, Card, Button } from 'antd-mobile'
import AppliedFilterList from '../../components/filter-widgets/AppliedFilterList/AppliedFilterList'
import DashboardListSidebar from '../../components/dashboard-list-sidebar/DashboardListSidebar'
import { useTranslation } from 'react-i18next'
import useWindowDimensions from '../../custom-hooks/useWindowDimenisons'
import classes from './DashboardPage.module.css'
import FilterList from '../../components/filter-widgets/FilterList/FilterList'
import FilterItemList from '../../components/filter-widgets/FilterItemList/FilterItemList'
import { createTitle } from '../../helpers/utils'
import BottomTab from "../../components/dashboard/BottomTab/BottomTab"
import { useStoryBoard } from '../../custom-hooks/useStoryBoard'
import { FilterService } from '../../store/reducers/currentfilters'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const isPublicRoute = url?.includes('public')
  const isDashboardPage = url?.includes('dashboard')
  const { showcaseItemId, showcaseId, dashletId, dashboardId } = useParams()
  const { currentfilters, showcases, showcasemenu } = useSelector(state => state)
  const showcaseData = _.get(showcases, 'entries.data.length')
  const showcase = useSelector((state) => {
    return _.find(_.get(state, 'showcases.entries.data'), { id: showcaseId })
  })

  const showBars = useSelector((state) => {
    return _.get(state, 'showBars.showBars')
  })

  const dashboardSC = useSelector(state =>
    _.get(state, 'showcases.currentDashboard')
  )

  const dashboardDB = useSelector(state =>
    _.get(state, 'singledashboard.currentDashboard')
  )

  const rowClickCheck = useSelector(state => {
    return state.rowClickCheck.Check
  })

  const rowClickFilterArr = useSelector(state => {
    return state.rowClickCheck.filterArray
  })

  const dashboard = dashboardSC || dashboardDB

  const { prevItem, goPrev } = useStoryBoard()
  const [filteringObject, setFilteringObject] = useState({})

  const currentShowcaseItem = _.find(showcasemenu?.flat, { id: showcaseItemId })
  const currentShowcaseItemIndex = _.findIndex(showcasemenu?.flat, { id: showcaseItemId })
  const isBox = currentShowcaseItem?.item_display_type === 'box' && !!currentShowcaseItem?._children.length
  const showcaseChildren = _.get(showcases, `entries.data.${currentShowcaseItemIndex}.relationships._children.data`)

  const [loading, setLoading] = useState(true)
  const [noData, setNoData] = useState('')
  const [showSidebar, setShowSidebar] = useState(false)
  const [hasFilter, setHasFilter] = useState(false)
  const [showcaseTitle, setShowcaseTitle] = useState('')
  const [showFilters, setShowFilters] = useState(false)
  const [showFilterItems, setShowFilterItems] = useState(false)
  const [filter, setFilter] = useState(null)
  const [showFilterWarning, setShowFilterWarning] = useState(false)
  const [isRequiredFilter, setIsRequiredFilter] = useState(false)
  const { height } = useWindowDimensions()

  const [allValues, setAllValues] = useState([])
  const [selectedValues, setSelectedValues] = useState([])
  const [firstTimeCheck, setFirstTimeCheck] = useState(true)
  const [affectedFromList, setAffectedFromList] = useState([])
  const [affectedFromCounter, setAffectedFromCounter] = useState([])
  const [filterList, setFilterList] = useState()

  let currFilters = []
  const allFilters = useSelector(state => {
    currFilters = state.currentfilters
  })

  useEffect(() => {
    if (dashboard) {
      const URI_FILTERS = []
      history.location.search.split('&').forEach(function (queryFilter) {
        let queryFilterItem = queryFilter.split('=')
        if (queryFilterItem.length > 1) {
          if (queryFilterItem[0] === '?filters') {
            const parsedFilters = queryFilterItem[1].split('%26')
            parsedFilters.forEach((pf) => {
              const filterVals = decodeURIComponent(pf).split("=")
              if (filterVals.length > 1) {
                const URI_FILTER_IN = URI_FILTERS.find((URI_FILTER) => URI_FILTER.id === filterVals[0])
                if (!URI_FILTER_IN) {
                  let str = filterVals[1]
                  filterVals[1] = str.replaceAll("+", " ")
                  URI_FILTERS.push({
                    id: filterVals[0],
                    values: [filterVals[1]]
                  })
                } else {
                  URI_FILTER_IN.values.push(filterVals[1])
                }
              }
            })
          }
        }
      })

      URI_FILTERS.forEach((URI_FILTER) => {
        let getter = FilterService.getFilter
        const filterToBeApplied = getter(currFilters, URI_FILTER.id, 1)
        let func = 'setValues'
        let id = _.get(filterToBeApplied, 'id')
        if (filterToBeApplied) {
          dispatch({
            type: 'FILTER_SERVICE_CALL',
            func: func,
            params: {
              id: id,
              clone: 1,
              values: URI_FILTER.values
            }
          })
        }
      })

      setFilterList(_.get(dashboard, 'filters'))
      const hasFilter =
        _.get(dashboard, 'filters.length') > 0 ||
        _.get(dashboard, 'expression_filters.length') > 0
      setHasFilter(!!hasFilter)
    }
  }, [dashboard])

  const flushFilters = useCallback(() => {
    dispatch({ type: 'PURGE_FILTERS', params: { filters: [], clone: 1 } })
  }, [dispatch])

  const fetch = useCallback(
    async (refresh = false, appliedFilters) => {
      setLoading(true)
      setNoData('')
      if (!showcaseData) {
        await dispatch(fetchShowcase(showcaseId, refresh))
      }
      try {
        if (showcaseChildren !== undefined && rowClickCheck) {
          await dispatch(fetchShowcaseItem(showcaseChildren[rowClickCheck].id, appliedFilters))
        }
        else
          await dispatch(fetchShowcaseItem(showcaseItemId, appliedFilters))
        setNoData('')
      } catch (error) {
        if (error.message === 'Error: notCachedError') {
          setNoData('noConnectionAndCachedData')
        } else {
          setNoData('noDashboardExists')
          history.replace(`/showcase/${showcaseId}/`)
        }
      }
      setLoading(false)
    },
    [showcaseData, dispatch, showcaseId, showcaseItemId, history, rowClickCheck, showcaseChildren]
  )

  const fetchDashboard = useCallback(
    async (refresh = false) => {
      setLoading(true)
      setNoData('')

      try {
        await dispatch(fetchSingleDashboard(dashboardId, refresh))
        setNoData('')
      } catch (error) {
        if (error.message === 'Error: notCachedError') {
          setNoData('noConnectionAndCachedData')
        } else {
          setNoData('noDashboardExists')
        }
      }
      setLoading(false)
    },
    [dispatch, dashboardId]
  )

  const appliedFilters = _.get(currentfilters, 'filters').filter(filter => {
    if (_.get(filter, 'defaultValues.length')) {
      return filter
    }
    return null
  })

  const affectedFrom = () => {
    let arr = []
    if (!!filterList) {
      for (var i = 0; i < filterList.length; i++) {
        let affected_from = _.get(filterList[i], 'affected_from')
        if (affected_from) {
          for (var j = 0; j < affected_from.length; j++) {
            let _id = _.get(affected_from[j], 'id')
            arr.push(_id)
          }
        }
      }
    }
    return arr
  }

  useEffect(() => {
    const arr = affectedFrom()
    setAffectedFromList(arr.filter(function (item, pos) {
      return arr.indexOf(item) === pos;
    }))
  }, [filterList])

  useEffect(() => {
    for (var i = 0; i < affectedFromList.length; i++) {
      setAffectedFromCounter(affectedFromCounter => [...affectedFromCounter, -1])
    }
  }, [affectedFromList])

  useEffect(() => {
    if (showcaseItemId) {
      setShowFilterWarning(false)
      flushFilters()
      fetch(false, appliedFilters)
    }
    if (dashboardId) {
      setShowFilterWarning(false)
      flushFilters()
      fetchDashboard(false, appliedFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, fetchDashboard, flushFilters, showcaseItemId, dashboardId])

  useEffect(() => {
    if (dashboard) {
      let fullScreenDashlet
      if (dashletId) {
        fullScreenDashlet = dashboard.dashlets.find(dashboardDashlet => {
          if (dashboardDashlet.html_widget) {
            return _.get(dashboardDashlet, 'html_widget.id') === dashletId
          }
          return _.get(dashboardDashlet, 'dashlet.id') === dashletId
        })
      }
      if (fullScreenDashlet) {
        const dashlet = _.get(fullScreenDashlet, 'dashlet')
        setShowcaseTitle(createTitle(dashlet, appliedFilters))
      } else {
        setShowcaseTitle(dashboard.title)
      }
    } else if (isBox) {
      setShowcaseTitle(showcase?.attributes?.title)
    }
  }, [dashboard, dashletId, dashboardId, isBox, showcase, appliedFilters])

  const requiredFilters = useMemo(() => {
    if (dashboard) {
      return dashboard._dashboard_filters.filter(
        filter => filter.required
      )
    }
  }, [dashboard])

  const showRequiredFilters = useMemo(() => {
    if (requiredFilters?.length && appliedFilters && dashboard) {
      const appliedFiltersIds = appliedFilters.map(filter => filter.id)
      return !requiredFilters.every(filter =>
        appliedFiltersIds.includes(filter.id)
      )
    }
    return false
  }, [appliedFilters, dashboard, requiredFilters])

  useEffect(() => {
    if (showRequiredFilters && !showFilterWarning) {
      const appliedFiltersIds = appliedFilters.map(filter => filter.id)
      let filters = requiredFilters.filter(filter => !appliedFiltersIds.includes(filter.id))
      const filter = filters?.[0]
      setShowFilterItems(true)
      setFilter(filter)
      setIsRequiredFilter(true)
    }

  }, [appliedFilters, requiredFilters, showRequiredFilters, showFilterWarning, setIsRequiredFilter])

  const getFilterAlias = () => {
    const appliedFiltersIds = appliedFilters?.map(filter => filter.id)
    const filters = requiredFilters?.filter(
      filter => !appliedFiltersIds.includes(filter.id)
    )
    const alias = filters?.map(filter => filter.alias).join(', ')
    return alias
  }

  const isStoryBoard = showcase?.attributes?.is_storyboard
  let children
  if (loading || (noData && !isBox)) {
    children = (
      <div className='vh-100 center-element'>
        {loading ? (
          <ActivityIndicator animating size='large' />
        ) : (
          <span>{t(noData)}</span>)}
      </div>
    )
  } else if (showFilters) {
    children = (
      <FilterList
        showcases={showcases}
        showFilters={showFilters}
        filterHandlers={{ setShowFilters, setFilter, setShowFilterItems }}
      />
    )
  } else if (showFilterItems) {
    children = (
      <FilterItemList
        filter={filter}
        setShowFilterItems={setShowFilterItems}
        setShowFilterWarning={setShowFilterWarning}
        setShowFilters={setShowFilters}
        isRequiredFilter={isRequiredFilter}
        showFilterItems={showFilterItems}
        isStoryBoard={isStoryBoard}
        allValues={allValues}
        setAllValues={setAllValues}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        firstTimeCheck={firstTimeCheck}
        setFirstTimeCheck={setFirstTimeCheck}
        appliedFilters={appliedFilters}
        affectedFromList={affectedFromList}
        affectedFromCounter={affectedFromCounter}
        setAffectedFromCounter={setAffectedFromCounter}
      />
    )
  } else if (showFilterWarning) {
    const alias = getFilterAlias()
    children = (
      <div className='vh-100 center-element'>
        <span>{t('requiredFilters', { alias })}</span>
      </div>
    )
  } else if (dashboard) {
    const { filters, variables, customs } = currentfilters
    const isScrollable = dashboard.dashlets.length > 2
    children = (
      <div style={isScrollable ? { height: height - 50 } : { height: height - 50, overflow: 'hidden' }}>
        <AppliedFilterList
          filters={filters}
          variables={variables}
          customs={customs}
          setFilteringObject={setFilteringObject}
        />
        <DashletWidgets dashboard={dashboard} appliedFilters={appliedFilters} isStoryBoard={isStoryBoard} filteringObject={filteringObject} setFilteringObject={setFilteringObject}/>
        {isStoryBoard && <BottomTab />}
      </div>
    )
  } else if (isBox) {
    children = (
      <Flex direction='column' justify='center' align='center' style={{ height: '100vh' }}>
        {currentShowcaseItem._children.map(item => {
          return (
            <Card
              key={item.id}
              className={classes.card}
              onClick={() => {
                if (isPublicRoute) {
                  history.push(`/public/showcase/${showcaseId}/${item.id}`)
                } else {
                  history.push(`/showcase/${showcaseId}/${item.id}`)
                }
              }}>
              <Card.Body>
                <h3 style={{ textAlign: 'center' }}>{item.title}</h3>
              </Card.Body>
            </Card>
          )
        })}
        {prevItem && prevItem.parent && <Button onClick={goPrev} className={classes.card}>{t('back')}</Button>}
      </Flex>
    )
  } else if (!dashboard) {
    children = <Link to={`/public/showcase/${showcaseId}`}>{t('goBack')}</Link>
  }

  let showDashboardList = true
  const isSingleShowcaseItem = _.get(showcasemenu, 'items.length') === 1
  const isAnyChildren = _.get(showcasemenu, 'items.0._children.length')
  if (isSingleShowcaseItem && !isAnyChildren) {
    showDashboardList = false
  }

  if (showcase?.attributes?.is_storyboard) {
    showDashboardList = false
  }

  const hideLeftArrow = history.location.search.includes('&hide_left_arrow')
  const hideBackArrow = (isPublicRoute && !dashletId) || !showBars || hideLeftArrow
  const showFilterIcon = hasFilter && !isBox && !loading
  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[!hideBackArrow &&
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => {
              if (isDashboardPage) {
                history.goBack()
              }
              if (dashletId) {
                if (isPublicRoute) {
                  history.push(`/public/showcase/${showcaseId}/${showcaseItemId}`)
                } else {
                  history.push(`/showcase/${showcaseId}/${showcaseItemId}`)
                }
              } else {
                dispatch({ type: 'PURGE_SHOWCASE' })
                history.push('/showcases')
              }
            }}
          />
        ]}
        rightContent={[
          showFilterIcon && (
            <i
              key='fa-filter'
              className={'fa fa-filter fa-lg'}
              onClick={() => {
                setShowFilters(true)
              }}
            />
          ),
          !dashletId && showDashboardList && !isDashboardPage && (
            <i
              key='fa-list-ul'
              className={`fa fa-list-ul fa-lg ${classes.icon}`}
              onClick={() => {
                setShowSidebar(!showSidebar)
              }}
            />
          )
        ]}
      >
        <span className={classes.navbarTitle}>{showcaseTitle}</span>
      </NavBar>
      <DashboardListSidebar
        showSidebar={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
      />
      {children}
    </>
  )
}

export default React.memo(DashboardPage)
