import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  InputItem,
  NavBar,
  Button,
  WingBlank,
  List,
  Flex,
  WhiteSpace
} from 'antd-mobile'
import axios from 'axios'
import { client } from '../../../store/configureStore'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { validateEmail, showAlert } from '../../../helpers/utils'
import classes from './PersonalInfoPage.module.css'

const PersonalInfoPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const user = useSelector(state => state.auth)
  const {
    userId,
    language,
    isIntroClosed,
    username,
    token,
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail
  } = user

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState(initialFirstName || '')
  const [lastName, setLastName] = useState(initialLastName || '')
  const [email, setEmail] = useState(initialEmail || '')

  const handleChangePassword = async () => {
    if (!firstName || !lastName || !email) {
      showAlert(t('error'), t('invalidInput'), t('close'))
      return
    }

    const isValidEmail = validateEmail(email)
    if (!isValidEmail) {
      showAlert(t('error'), t('invalidEmailError'), t('close'))
      return
    }

    setLoading(true)
    const passwordChangeUrl = `${client.defaults.baseURL}/users/${userId}/`
    const data = {
      id: userId,
      type: 'users',
      attributes: {
        email_address: initialEmail,
        email,
        first_name: firstName,
        last_name: lastName,
        is_intro_closed: isIntroClosed,
        language,
        username
      }
    }
    const headers = {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json'
    }
    axios.defaults.headers.common = { Authorization: `Token ${token}` }

    try {
      const response = await axios.patch(
        passwordChangeUrl,
        {
          data
        },
        {
          headers
        }
      )

      if (_.get(response, 'status') === 200) {
        const userData = {
          email,
          firstName,
          lastName
        }
        dispatch({ type: 'CREATE_USER', userData })
        showAlert(t('success'), t('profileHasBeenUpdated'), t('close'))
      } else {
        showAlert(t('error'), t('editProfileError'), t('close'))
      }
    } catch (error) {
      const errorMessage = _.get(error, 'response.data.errors.0.detail')
      if (
        [
          'A user is already registered with this e-mail address.',
          'Bir kullanıcı bu e-posta adresine zaten kayıtlı.'
        ].includes(errorMessage)
      ) {
        showAlert(t('error'), t('alreadyRegisteredEmail'), t('close'))
        return
      } else if (
        [
          'Bu alanın 30 karakterden fazla karakter barındırmadığından emin olun.',
          'Ensure this field has no more than 30 characters'
        ].includes(errorMessage)
      ) {
        showAlert(t('error'), t('personalInfoError'), t('close'))
        return
      }
      showAlert(t('error'), t('editProfileError'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => history.push('/profile')}
          />
        ]}
      >
        {t('personalInformation')}
      </NavBar>
      <WingBlank>
        <List>
          <Flex>
            <span className={classes.label}>{t('firstName')}</span>
            <InputItem
              type='text'
              onChange={value => setFirstName(value)}
              value={firstName}
            />
          </Flex>
          <Flex>
            <span className={classes.label}>{t('lastName')}</span>
            <InputItem
              type='text'
              onChange={value => setLastName(value)}
              value={lastName}
            />
          </Flex>
          <Flex>
            <span className={classes.label}>{t('email')}</span>
            <InputItem
              type='email'
              onChange={value => setEmail(value)}
              value={email}
            />
          </Flex>
        </List>
        <WhiteSpace size='lg' />
        <Button
          loading={loading}
          size='small'
          type='primary'
          onClick={handleChangePassword}
        >
          {t('change')}
        </Button>
      </WingBlank>
    </>
  )
}

export default PersonalInfoPage
