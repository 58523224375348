import React from 'react'
import { Button, WhiteSpace } from 'antd-mobile'
import appConfig from '../../../config/appConfig'

const LoginOptions = ({ handleLoginType, SSOAuthUrl }) => {
  return (
    <>
      <p className='ta-center fs-1rem'>
        E-posta adresi, e-imza veya e-devlet ile giriş yapacaksanız "Ortak
        Giriş" düğmesine tıklayınız.
      </p>
      <Button
        className='w-p100'
        style={{background:'#21ba45'}}
        onClick={() => {
          window.location.href = SSOAuthUrl
        }}
      >
        ORTAK GİRİŞ
      </Button>
      <WhiteSpace size='lg' />
      <p className='ta-center fs-1rem'>
        Bir kullanıcı adı ve şifre verildiyse "Uygulama Hesabı İle Giriş"
        düğmesine tıklayınız.
      </p>
      <Button
        className='w-p100'
        style={{background:'#e0e1e2'}}
        onClick={() => handleLoginType('custom')}
      >
        UYGULAMA HESABI İLE GİRİŞ
      </Button>
    </>
  )
}

export default LoginOptions
