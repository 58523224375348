import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, NavBar, Radio } from 'antd-mobile'
import _ from 'lodash'
import { changeLanguage } from '../../../store/actions/settings'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import i18next from 'i18next'

const LanguageSettingScreen = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const language =
    useSelector(state => _.get(state, 'settings.language')) || i18next.language

  const setAppLanguage = language => {
    dispatch(changeLanguage(language))
    i18next.changeLanguage(language)
  }

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => history.push('/profile')}
          />
        ]}
      >
        {t('languageSetting')}
      </NavBar>
      <List>
        <Radio.RadioItem
          checked={language === 'en'}
          key={1}
          onChange={() => setAppLanguage('en')}
        >
          English
        </Radio.RadioItem>
        <Radio.RadioItem
          checked={language === 'tr'}
          key={2}
          onChange={() => setAppLanguage('tr')}
        >
          Türkçe
        </Radio.RadioItem>
      </List>
    </>
  )
}

export default React.memo(LanguageSettingScreen)
