import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, Radio } from 'antd-mobile'
import { rowClickedFilterQuery, rowClickedFilterArray } from '../../store/actions/rowClickCheck'
import _ from 'lodash'

const RadioWidget = ({
  setSelectedValues,
  selectedValues = [],
  allValues = [],
  nodeId,
  ...otherProps
}) => {
  const dispatch = useDispatch()

  const isChecked = useCallback(
    item => {
      return selectedValues.includes(item)
    },
    [selectedValues]
  )

  const query = useSelector(state => {
    return state.rowClickCheck.filterQuery
  })

  const filterArr = useSelector(state => {
    return state.rowClickCheck.filterArray
  })

  const toggleItem = useCallback(
    async item => {
      let values = [...selectedValues]
      if (!isChecked(item)) {
        values = [item]
      } else {
        values = []
      }
      setSelectedValues(values)
      if(!!query && !!nodeId) {
        let removeStr = null, newQuery = null
        for (var i = 0; i < values.length; i++) {
          for( var j = 0; j < filterArr.length; j++) {
            if(!!_.get(filterArr, `${j}.${nodeId}`)) {
              removeStr = _.get(filterArr, `${j}.${nodeId}`)
              newQuery = query.replace(removeStr, values[i])
              break
            }
            else {
              newQuery = `${query}&${nodeId}=${values[i]}`
            }
          }
          await dispatch(rowClickedFilterQuery(`${newQuery}`))
          await dispatch(rowClickedFilterArray({[nodeId]: values[i]}))
        }
      }
    },
    [isChecked, selectedValues, setSelectedValues]
  )

  return (
    typeof allValues === 'object' && allValues.length ? <List>
      {allValues.map((item, index) => (
        <Radio.RadioItem
          checked={selectedValues.includes(item)}
          key={index}
          onChange={() => toggleItem(item)}
          onClick={() => toggleItem(item)}
        >
          {item}
        </Radio.RadioItem>
      ))}
    </List> : null
  )
}

export default RadioWidget
