import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { fetchShowcase } from '../../store/actions/showcases'
import { ActivityIndicator, NavBar } from 'antd-mobile'
import { useTranslation } from 'react-i18next'

const ShowcasePage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { url } = useRouteMatch()
  const history = useHistory()

  let showcaseId = _.get(useParams(), 'showcaseId')

  const showcasemenu = useSelector(state => state.showcasemenu)

  const getShowcaseItemId = () => {
    let showcaseItemId
    showcaseItemId = _.get(showcasemenu, 'items.0.id')

    return showcaseItemId
  }

  const showcaseItemId = getShowcaseItemId()

  const [loading, setLoading] = useState(true)
  const [noData, setNoData] = useState(true)

  const fetch = useCallback(
    async (refresh = false) => {
      setLoading(true)
      setNoData('')

      try {
        await dispatch(fetchShowcase(showcaseId, refresh))
        setNoData('')
      } catch (error) {
        if (error.message === 'Error: notCachedError') {
          setNoData('noConnectionAndCachedData')
        } else {
          setNoData('noDashboardExists')
        }
      }
      setLoading(false)
    },
    [dispatch, showcaseId]
  )

  useEffect(() => {
    fetch(false, [])
  }, [fetch])

  let children
  if (loading || noData || !showcaseItemId) {
    children = (
      <div className='center-element vh-100'>
        {loading ? (
          <ActivityIndicator animating size='large' />
        ) : (
          <div className='center-element vh-100'>
            <NavBar
              className='navigationBar'
              style={{width: '100%'}}
              mode='light'
              leftContent={[
                <i
                  key='fa-arrow-left'
                  className='fa fa-arrow-left fa-lg'
                  onClick={() => {
                      history.push('/showcases')
                  }}
                />
              ]}
            >
            </NavBar>
            <span>{t(noData)}</span>
          </div>
        )}
      </div>
    )
  } else {
    children = <Redirect to={`${url}/${showcaseItemId}`} />
  }

  return children
}

export default React.memo(ShowcasePage)
