const initialState = []

export default function (state = initialState, action) {
  const recentDashboard = {
    id: action.id,
    title: action.title,
    default_thumbnail: 'default'
  }
  const isRecentDashboardInTheList = state
    .map(dashboard => dashboard?.id)
    .includes(action.id)
    
  switch (action.type) {
    case 'ADD_TO_RECENT_DASHBOARD':
      if (isRecentDashboardInTheList) {
        return [
          recentDashboard,
          ...state.filter(item => item?.id).filter(dashboard => dashboard?.id !== action.id)
        ]
      }
      return [recentDashboard, ...state.slice(0, 5)]
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
