const initialState = []

export default function (state = initialState, action) {
  const recentShowcase = {
    id: action.id,
    title: action.title,
    default_thumbnail: action.imageSource
  }
  const isRecentShowcaseInTheList = state
    .map(showcase => showcase?.id)
    .includes(action.id)
    
  switch (action.type) {
    case 'ADD_TO_RECENT_SHOWCASES':
      if (isRecentShowcaseInTheList) {
        return [
          recentShowcase,
          ...state.filter(item => item?.id).filter(showcase => showcase?.id !== action.id)
        ]
      }
      return [recentShowcase, ...state.slice(0, 5)]
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
