import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { ActivityIndicator, Flex } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import { fetchMapData } from '../../../store/actions/data'
import { useDispatch, useSelector } from 'react-redux'
import { FilterService } from '../../../store/reducers/currentfilters'
import { client } from '../../../store/configureStore'
import _ from 'lodash'
import { useTheme } from '../../../custom-hooks/useTheme'
import Card from '../../../components/dashlet-card/Card/Card'
import EchartsContainer from '../../dashlet-widgets/chart/EchartsContainer'
import DS from '../../../models/model-data'
import { useStoryBoard } from '../../../custom-hooks/useStoryBoard'
import { useParams} from 'react-router-dom'
import i18next from 'i18next'

const RegionalMapWidget = ({ model, dashboard, drillThrough }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filterQuery = useSelector(state =>
    FilterService.getQueryParams(state.currentfilters)
  )
  const currentFilters = useSelector(state => state.currentfilters)
  const { showcaseTheme } = useTheme(true)
  const  { showcaseId } = useParams()
  const showcase = useSelector((state) => {
    return _.find(_.get(state, 'showcases.entries.data'), { id: showcaseId })
  })
  const language = useSelector(state => _.get(state, 'settings.language')) || i18next.language

  const { goNext } = useStoryBoard()

  const [mapData, setMapData] = useState({})
  const [data, setData] = useState([])
  const [selectedPointCategories, setSelectedPointCategories] = useState([])
  const [showFilterIcon, setShowFilterIcon] = useState(false)
  const [noData, setNoData] = useState(false)
  const [chartOptions, setChartOptions] = useState(null)

  const getData = useCallback(
    filterQuery => {

      const getData = async () => {
        try {
          const response = await dispatch(
            fetchMapData(model.id, false, _.get(dashboard, 'id'), filterQuery)
          )
          const rows = _.get(response, 'payload.data.data.0.attributes.rows')
          const isAnyData = Object.keys(rows).length > 0
          const data = _.get(response, 'payload.data')
          if (isAnyData) {
            setData(data)
          } else {
            setNoData(true)
          }
          return data || null
        } catch (error) {
          setNoData(true)
          console.error(error)
        }
      }

      return getData()
    },
    [dashboard, dispatch, model.id]
  )

  const getMap = useCallback(
    responseData => {
      const drilldownLevel = _.get(
        responseData,
        'data.0.attributes.meta.drilldown_level'
      )
      const dimension =
        drilldownLevel > -1
          ? model.drilldowndimensions[drilldownLevel]
          : model.dimensions[0]

      let source = _.get(dimension, 'dimension.regional_map.source') || ''
      const baseUrl = client.defaults.baseURL.replace('api/v1', '')
      const url = `${baseUrl}media/geojson/${source}`

      const fetchMap = async () => {
        try {
          const response = await client.get(url)
          const data = _.get(response, 'data')
          if (data) {
            setMapData(data)
          } else {
            setMapData({})
            setNoData(true)
          }
        } catch (error) {
          console.error(error)
          setNoData(true)
        }
      }

      fetchMap()
    },
    [model.dimensions, model.drilldowndimensions]
  )

  const fetchData = useCallback(async () => {
    try {
      const responseData = await getData(filterQuery)
      if (responseData) {
        getMap(responseData)
      } else {
        setNoData(true)
      }
    } catch (error) {
      console.error(error)
      setNoData(true)
    }
  }, [filterQuery, getData, getMap])

  useEffect(() => {
    setMapData({})
    setData([])
    fetchData()
  }, [fetchData])

  const getRelatedFilter = useMemo(() => {
    if (data) {
      const dashletData = data.data
      const drilldownLevel = _.get(
        dashletData,
        '0.attributes.meta.drilldown_level'
      )
      const dimension =
        drilldownLevel > -1
          ? model.drilldowndimensions[drilldownLevel]
          : model.dimensions[0]

      if (!dimension || !dimension.dimension) {
        return
      }
      const revQueryName = _.get(dimension, 'dimension.reversed_query_name')
      let filter = _.find(
        currentFilters.filters,
        currentFilter =>
          _.get(currentFilter, 'node.reversed_query_name') === revQueryName
      )
      if (filter) {
        filter = _.clone(filter)
        filter.parentFilter = null
        filter.affectedFrom = []
      }
      return filter
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, model.drilldowndimensions, model.dimensions])

  const _drilldown = useCallback(() => {
    if (selectedPointCategories !== undefined) {
      const filter = getRelatedFilter
      if (filter) {
        dispatch({
          type: 'FILTER_SERVICE_CALL',
          func: 'setValues',
          params: {
            id: filter._id || filter._id_,
            clone: 1,
            values: selectedPointCategories
          }
        })
      }
      setShowFilterIcon(false)
      const isStoryBoard = showcase?.attributes?.is_storyboard
      if (isStoryBoard) {
        goNext()
      }
    }
  }, [selectedPointCategories, getRelatedFilter, dispatch, showcase, goNext])

  const actions = useMemo(
    () => ({
      set: function (path, value) {
        const func = path.substr(0, 1).toUpperCase() + path.substr(1)
        try {
          // eslint-disable-next-line no-eval
          eval(`set${func}`)(value)
        } catch (e) {
          //
        }
      },
      drilldown: _.debounce(_drilldown, 100),
      resetSelection () {
        setSelectedPointCategories([])
        setShowFilterIcon(false)

      },
      drillThrough (from, to) {
        drillThrough(from, to)
      },
      setDataURL: function (uri) {
        // const shareOptions = {
        //   title: model.title,
        //   url: uri
        // }
        // Share.open(shareOptions)
        //   .then(() => {
        //     //console.log(res)
        //   })
        //   .catch(() => {
        //     //console.error(err)
        //   })
      }
    }),
    [_drilldown, drillThrough]
  )

  const createOption = useCallback(() => {
    let dashletData = data.data
    if (_.get(dashletData, '0.attributes.rows.m0')) {
      let dashlet_serializer = DS.belongsTo('dashlet')
      model._drilldown_level_ = _.get(
        dashletData,
        '0.attributes.meta.drilldown_level'
      )
      let serialized_dashlet = dashlet_serializer(model)
      serialized_dashlet.immediate_slicer = true
      serialized_dashlet.userLang = language
      serialized_dashlet['geojson'] = mapData
      let options = _.map(dashletData, dashletDatum => {
        return {
          data: dashletDatum.attributes,
          dashlet: serialized_dashlet,
          theme: showcaseTheme,
          relatedFilter: getRelatedFilter || {}
        }
      })
      options = _.filter(options, datum => {
        return _.get(datum, 'data.meta.type') !== 'motion'
      })

      setChartOptions(options)
    }
  }, [data, getRelatedFilter, model, mapData, showcaseTheme, language])

  useEffect(() => {
    if (_.get(data, 'data.length') && _.get(mapData, 'features.length')) {
      createOption()
    }
  }, [data, mapData, createOption])

  let children
  if (noData) {
    children = (
      <Flex
        justify='center'
        align='center'
        style={{ height: '100%', width: '100%' }}
      >
        <span>{t('no_data_dashlet')}</span>
      </Flex>
    )
  } else if (
    _.get(chartOptions, 'length') &&
    _.get(mapData, 'features.length')
  ) {
    children = (
      <Card model={model} actions={actions} showFilterIcon={showFilterIcon}>
        <div style={{display: 'flex', height: '100%'}}>
          <EchartsContainer
            option={chartOptions[0]}
            actions={actions}
          />
        </div>
      </Card>
    )
  } else {
    children = null
  }

  return children
}

export default React.memo(RegionalMapWidget)
