import React, { useState, useMemo, useCallback } from 'react'
import Cell from './Cell'
import _ from 'lodash'
import formatter, { dateFormatting } from '../../../helpers/Formatting'
import { formatDateFilter } from '../../../helpers/DateFilter'
import AppConfig from '../../../config/appConfig'

const Row = ({
  handler,
  index,
  item,
  tableHeaders,
  totalWidth,
  rowWidths,
  minFirstCellWidth,
  minGeneralCellWidth,
  onCellClick,
  autoWidth,
  textProps,
  isDetail,
  horizontalAlignment,
  verticalAlignment
}) => {
  const rowLevel = useMemo(() => (_.get(item, '0.indent') === 1.5 ? 2 : 1), [
    item
  ])

  const [rowCollapse, setRowCollapse] = useState(false)

  const toggleRowCollapse = useCallback(
    (value, dimension) => {
      let filtering
      if (dimension.dateAggregateDataType) {
        filtering = formatDateFilter(dimension, value)
      } else {
        if (typeof value === 'string') {
          value = value.trim()
        }
        filtering = dimension.dimension + '=' + value
      }
      handler(
        index,
        value,
        filtering,
        dimension.isCollapsed,
        rowLevel,
        dimension
      )
      dimension.isCollapsed = !dimension.isCollapsed
      setRowCollapse(!rowCollapse)
    },
    [handler, index, rowLevel, rowCollapse]
  )

  const addSuffixPrefix = useCallback((column, displayValue) => {
    const prefix = column?.prefix ?? ''
    const suffix = column?.suffix ?? ''
    return `${prefix} ${displayValue} ${suffix}`
  }, [])

  const cells = useMemo(
    () =>
      item.map((cellValue, idx) => {
        let firstValue = typeof item[0] === 'object' ? item[0].value : item[0]
        let isCellObject = typeof cellValue === 'object'
        let value = isCellObject ? cellValue.value : cellValue
        let cellBGColor = false
        let cellWidth = rowWidths[idx]
        let minCellWidth = autoWidth
          ? 40
          : idx === 0
          ? minFirstCellWidth
          : minGeneralCellWidth
        let textColor = '#333'
        let column = _.get(tableHeaders, idx)
        let cellFontWeight = 'normal'
        if (isCellObject || firstValue === 'Totals') {
          cellFontWeight = 'bold'
        }
        if (_.get(column, 'conditional_formatting')) {
          let thresholds = JSON.parse(column.thresholds)
          let cellValue = value
          thresholds.forEach(function (threshold, ind) {
            if (cellValue.string !== '') {
              if (
                threshold.name === 'less_than' &&
                parseFloat(cellValue) < parseFloat(threshold.value)
              ) {
                cellBGColor = threshold.color
                textColor = threshold.fontIconColor
              } else if (
                threshold.name === 'Between' &&
                parseFloat(cellValue) > parseFloat(thresholds[ind - 1].value) &&
                parseFloat(cellValue) < parseFloat(thresholds[ind + 1].value)
              ) {
                cellBGColor = threshold.color
                textColor = threshold.fontIconColor
              } else if (
                threshold.name === 'greater_than' &&
                parseFloat(cellValue) > parseFloat(threshold.value)
              ) {
                cellBGColor = threshold.color
                textColor = threshold.fontIconColor
              }
            }
          })
        }
        let displayValue
        if (
          _.get(column, 'type') === 'M' &&
          _.get(column, 'func') !== 'Value'
        ) {
          displayValue =
            idx === 0
              ? value
              : formatter(column.custom_abbreviation, column.formatting, value)
        } else {
          displayValue = value
        }
        if (value === '') {
          displayValue = ''
        }

        if (
          isDetail &&
          _.get(column, 'type') === 'D' &&
          AppConfig.DATE_TYPES.includes(_.get(column, 'data_type'))
        ) {
          displayValue = dateFormatting(column, displayValue)
        }

        if (displayValue) {
          displayValue = addSuffixPrefix(column, displayValue)
        }

        return (
          <Cell
            key={idx}
            displayValue={displayValue}
            textColor={textColor}
            cellBGColor={cellBGColor}
            cellFontWeight={cellFontWeight}
            cellValue={cellValue}
            cellWidth={cellWidth}
            minCellWidth={minCellWidth}
            handler={toggleRowCollapse}
            onCellClick={() => onCellClick(item, idx)}
            textProps={textProps}
            horizontalAlignment={horizontalAlignment}
            verticalAlignment={verticalAlignment}
          />
        )
      }),
    [
      item,
      rowWidths,
      autoWidth,
      minFirstCellWidth,
      minGeneralCellWidth,
      tableHeaders,
      isDetail,
      addSuffixPrefix,
      toggleRowCollapse,
      textProps,
      horizontalAlignment,
      verticalAlignment,
      onCellClick
    ]
  )

  return (
    <div
      style={{
        display: 'flex',
        border: '1px solid transparent'
      }}
    >
      {cells}
    </div>
  )
}

export default React.memo(Row)
