
export default function(DS) {
  return {
  id: DS.attr('string'),
  alias: DS.attr('string'),
  custom_alias: DS.attr('string'),
  //dashlet: DS.belongsTo('dashlet', { inverse: 'drilldowndimensions' }),
  dimension: DS.belongsTo('node'),
  filter: DS.belongsTo('filter', { async: true }),
  order: DS.attr('number'),
  sort: DS.attr('string'),
  is_dimension: true
}}
