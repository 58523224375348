const initialState = {items: []}

export default function auth (state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_DASHBOARD_MENU':
      return {...state, ...action.payload}
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
