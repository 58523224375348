
export default function(DS) {
  return {
    id: DS.attr('string'),
    alias: DS.attr('string'),
    custom_alias: DS.attr('string'),
    //dashlet: DS.belongsTo('dashlet'),
    data_cell: DS.belongsTo('node'),
    is_expression: DS.attr('boolean'),
    expression: DS.belongsTo('sqlexpression'),
    visibility: DS.attr('number'),
    order: DS.attr('number'),
    sortable: DS.attr('number'),
    table_name: DS.attr('string'),
    view: DS.attr('string'),
    data_type: DS.attr('string'),
    reversed_query_name: DS.attr('string'),
    is_star: DS.attr('boolean'),
    hide_from_totals: DS.attr('boolean'),
    formatting: DS.attr('string'),
    custom_abbreviation: DS.attr('string'),
    prefix: DS.attr('string'),
    suffix: DS.attr('string'),
    date_aggregate: DS.attr('string', {defaultValue: 'none'}),
    entityType: 'dataCell',
  }
}
