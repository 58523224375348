const initialState = {
  entries: {},
  dashboardList: null,
  searchParameters: {
    searchValue: '',
    hasMoreDashboards: true,
    currentPage: 1,
  }
}

export default function dashboardList (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_DASHBOARDS':
      return { ...state, entries: action.listofdashboards }
    case 'SET_ALL_DASHBOARDS':
      return { ...state, dashboardList: action.dashboardList }
    case 'SET_HAS_MORE_PAGE_TO_FALSE':
      return {
        ...state,
        searchParameters: {
          ...state.searchParameters,
          hasMoreDashboards: false
        }
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        searchParameters: {
          ...state.searchParameters,
          currentPage: action.currentPage
        }
      }
    case 'SET_SEARCH_PARAMETERS':
      return {
        ...state,
        searchParameters: {
          ...initialState.searchParameters,
          searchValue: action.searchParams.searchValue
        }
      }
    case 'RESET_SEARCH_PARAMS':
      return {
        ...state,
        searchParameters: initialState.searchParameters
      }
    case 'DESTROY_DASHBOARD_LIST':
      return {
        ...initialState,
        searchParameters: state.searchParameters
      }
    case 'LOGOUT':
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
