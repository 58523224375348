import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { findRecord } from '../../store/configureStore'
import { Grid } from 'antd-mobile'
import DashletWidget from './dashlet-widget/DashletWidget'
import useWindowDimensions, { handleResize } from '../../custom-hooks/useWindowDimenisons'
import classes from './DashletWidgets.module.css'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { useTheme } from '../../custom-hooks/useTheme'

const DashletWidgets = ({ dashboard, isStoryBoard, filteringObject, setFilteringObject }) => {
  // const { lightedBackgroundColor } = useTheme(true)

  const  { dashletId } = useParams()

  const [dashlets, setDashlets] = useState([])
  let { height, width } = useWindowDimensions()
  
  let columnNumber = 1
  if (width > height && dashlets?.length > 2) {
    columnNumber = 2
  }

  const getDashlets = useCallback(() => {
    let dashboardDashlets = _.get(dashboard, 'dashlets') || []
    dashboardDashlets = _.filter(dashboardDashlets, dbdl => {
      return (
        (_.get(dbdl, 'dashlet') &&
          ['chart', 'grid', 'detail', 'pivot', 'info_cell', 'map'].includes(
            _.get(dbdl, 'dashlet.type')
          )) ||
        _.get(dbdl, 'html_widget')
      )
    })
    dashboardDashlets = _.sortBy(dashboardDashlets, ['x'])
    dashboardDashlets = _.sortBy(dashboardDashlets, ['y'])

    if (dashletId) {
      dashboardDashlets = dashboardDashlets.filter(dashboardDashlet => {
        if (dashboardDashlet.html_widget) {
          return (
            _.get(dashboardDashlet, 'html_widget.id') === dashletId
          )
        }
        return _.get(dashboardDashlet, 'dashlet.id') === dashletId
      })
    }

    setDashlets(dashboardDashlets)
  }, [dashboard, dashletId])

  useEffect(() => {
    getDashlets()
  }, [getDashlets])

  const drillThrough = useCallback(
    async (from, to) => {
      try {
        let dashlet = await findRecord('dashlets', to)
        await findRecord('views', _.get(dashlet, 'view.id'))
        if (dashlet) {
          const dashboardDashlets = _.map(dashlets, dashboardDashlet => {
            if (_.get(dashboardDashlet, 'dashlet.id') === from) {
              _.set(dashboardDashlet, 'dashlet', dashlet)
              return dashboardDashlet
            }
            return dashboardDashlet
          })

          setDashlets(dashboardDashlets)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [dashlets]
  )
  
  const currentfilters = useSelector(state => state.currentfilters)

  const appliedFilters = _.get(currentfilters, 'filters').filter(filter => {
    if (_.get(filter, 'defaultValues.length')) {
      return filter
    }
    return null
  })

  if (dashletId && dashlets.length) {
    window.scrollTo(0, 0)
    let cardHeight
    if ( height > width) {
      cardHeight =  height - (height/15)
      if (appliedFilters.length) {
        cardHeight = height - (height/8.5)
      }
    } else {
      cardHeight =  height - (height/8)
      if (appliedFilters.length) {
        cardHeight = height - (height/4)
      }
    }
    

    window.addEventListener('resize', () => {
      window.location.reload()
    })

    return (
      <div
        className={classes.card}
        style={{
          height: cardHeight
        }}
      >
        <DashletWidget
          dashboardDashlet={dashlets[0]}
          filteringObject={filteringObject}
          drillThrough={drillThrough}
          setFilteringObject={setFilteringObject}
        />
      </div>
    )
  }

  const dashletsLength = dashlets?.length
  return (
    <Grid
      className='p-0'
      square={false}
      hasLine={false}
      data={dashlets}
      columnNum={columnNumber}
      renderItem={(dataItem, index) => {
        let isMoreMarginBottom
        if ((index === dashletsLength - 1) && isStoryBoard) {
          isMoreMarginBottom = true
        }
        return (
          <DashletWidget
            dashletCount={_.get(dashlets, 'length')}
            key={index}
            dashboardDashlet={dataItem}
            filteringObject={filteringObject}
            drillThrough={drillThrough}
            setFilteringObject={setFilteringObject}
            isMoreMarginBottom={isMoreMarginBottom}
          />
        )
      }}
    />
  )
}

export default React.memo(DashletWidgets)
