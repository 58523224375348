import Checkbox from './CheckboxWidget'
import Radio from './RadioWidget'
import Textbox from './TextboxWidget'
import RangeInput from './RangeInputWidget/RangeInputWidget'
import DatePicker from './DatePickerWidget'

export const CheckboxWidget = Checkbox
export const RadioWidget = Radio
export const TextboxWidget = Textbox
export const RangeInputWidget = RangeInput
export const DatePickerWidget = DatePicker
