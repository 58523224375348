import _ from 'lodash'
import { dataStore, client } from '../configureStore'
import { fetchView } from './views'
import { all } from 'rsvp'

export const fetchSingleDashboard = (id, appliedFilters, refresh) => {
  const refreshString = refresh ? 'true' : 'false'
  return async (dispatch, getState) => {
    const appendFilters = (filters, customs, variables) => {
      dispatch({
        type: 'FILTER_SERVICE_CALL',
        func: 'createFilters',
        params: {
          filters: filters,
          customs: customs,
          variables: variables,
          clone: 1
        }
      })
    }
    let dashboard = null
    let dashboardData = null
    try {
      const response = await client.get(
        `/dashboardviews/${id}/?refresh_cache=${refreshString}`
      )
      dashboardData = _.get(response, 'data')
      dataStore.syncWithMeta(dashboardData)
      if (!dashboardData) {
        throw new Error('noDashboardExists')
      }
      
      
    } catch (error) {
      throw new Error(error)
    }
    
    let viewFilters = []

    const { auth } = getState()
    if (auth?.token) {
      client.defaults.headers.common['Authorization'] = `Token ${auth.token}`
    }
    
    dashboard = dataStore.find('dashboards', id)

    if(!dashboard) {
      return
    }


    try {
      const response = await all(
        _.map(
          _.compact(_.uniq(_.map(dashboard.dashlets, 'dashlet.view.id'))),
          id => {
            return dispatch(fetchView(id))
          }
        )
      )

      if (response) {
        _.forEach(response, payload => {
          dataStore.syncWithMeta(_.get(payload, 'payload.data'))
        })

        let filters = _.map(_.get(dashboard, 'filters') || [], filter => {
          filter.info = _.get(filter, 'node.filter.info')
          if (_.isArray(_.get(filter, 'parent_filter.default_values'))) {
            filter.default_values = _.get(
              filter,
              'parent_filter.default_values'
            )
          }
          if (_.isArray(appliedFilters)) {
            let applied = _.find(appliedFilters, appliedFilter => {
              if (
                (_.get(filter, 'custom_alias') &&
                  _.get(filter, 'custom_alias') ===
                    _.get(appliedFilter, 'customAlias')) ||
                (_.get(filter, 'custom_alias') &&
                  _.get(filter, 'custom_alias') ===
                    _.get(appliedFilter, 'alias')) ||
                (_.get(filter, 'alias') &&
                  _.get(filter, 'alias') ===
                    _.get(appliedFilter, 'customAlias')) ||
                (_.get(filter, 'alias') &&
                  _.get(filter, 'alias') === _.get(appliedFilter, 'alias'))
              ) {
                return appliedFilter
              }
            })
            if (applied) {
              filter.default_values = _.get(applied, 'defaultValues')
            }
          }
          return filter
        })
        let merged_filters = _.get(dashboard, 'merged_filters', [])
        let variables = _.map(
          _.get(dashboard, 'variable_filters', []),
          function (variable) {
            _.set(variable, 'type', 'V')
            _.set(variable, '_id', _.get(variable, 'variable.id'))
            _.set(variable, 'viewId', _.get(variable, 'variable.view.id'))

            return variable
          }
        )

        let customs = _.map(
          _.get(dashboard, 'expression_filters', []),
          function (custom) {
            _.set(custom, 'type', 'F')
            _.set(custom, '_id', _.get(custom, 'expression.id'))
            _.set(custom, 'viewId', _.get(custom, 'expression.view.id'))

            return custom
          }
        )

        appendFilters(merged_filters)
        appendFilters(filters, customs, variables)

        _.each(_.uniq(_.map(dashboard.dashlets, 'dashlet.view')), view => {
          if (view) {
            viewFilters.push(...view._filters)
          }
        })

        appendFilters(viewFilters)
        dispatch({ type: 'CREATE_SINGLE_DASHBOARD', dashboard })
      } else {
        throw new Error()
      }
    } catch (error) {
      throw new Error(error)
    }
  }
}
