import { useSelector } from 'react-redux'
import _ from 'lodash'
import Color from 'tinycolor2'

export function useTheme (isShowcase = false) {
  let showcaseTheme
  const themes = useSelector(state => _.get(state, 'theme.themesList'))

  let deviceTheme
  if (_.get(themes, 'length')) {
    deviceTheme = themes.find(theme => theme.id === 'toronto')
    deviceTheme = _.get(deviceTheme, 'attributes.theme')
  }

  // if (!isShowcase || selectedThemeId !== 'default' || !showcaseTheme) {
  showcaseTheme = deviceTheme
  // }

  let themeBackgroundColor, themeTextColor, themeSubTextColor
  if (showcaseTheme) {
    themeBackgroundColor = Color(showcaseTheme.backgroundColor)
      .setAlpha(1)
      .toString()
    themeTextColor = _.get(showcaseTheme, 'title.textStyle.color')
    themeSubTextColor = _.get(showcaseTheme, 'title.subtextStyle.color')
  }
  const lightedBackgroundColor = Color(themeBackgroundColor)
    .lighten(5)
    .toString()

  return {
    themeBackgroundColor,
    themeTextColor,
    themeSubTextColor,
    lightedBackgroundColor,
    showcaseTheme
  }
}
