import React from 'react'
import { Button } from 'antd-mobile'
import classes from './HeaderRow.module.css'

const alignmentLookup = {
  'right aligned': 'right',
  'left aligned': 'left',
  center: 'center'
}

const minFirstCellWidth = 250
const minGeneralCellWidth = 75

const HeaderRow = ({
  item,
  index,
  sortColumn,
  sortIcons,
  columnWidths,
  onCellLayout,
  autoWidth,
  textProps,
  alignment
}) => {
  return (
    <div className={classes.row}>
      {item.map((member, idx) => {
        let displayValue
        if (typeof member === 'object') {
          displayValue = member.value
        } else {
          displayValue = member
        }
        displayValue = displayValue.replace(/<.+?>/g, '')
        if (displayValue.length > 25) {
          displayValue = displayValue.slice(0, 22) + '...'
        }
        const cellBorderStyle = member.noBorder
          ? classes.headerCellNoBorderStyle
          : classes.headerCellStyle
        return (
          <div
            ref={node => onCellLayout(node?.offsetWidth, idx)}
            key={idx}
            className={cellBorderStyle}
            style={{
              minWidth: autoWidth
                ? 40
                : idx === 0
                ? minFirstCellWidth
                : minGeneralCellWidth,
              width: columnWidths[idx]
            }}
          >
            {index === 1 ? (
              <Button size='small' onClick={() => sortColumn(idx)}>
                <>
                  <span className={classes.headerText} style={{ ...textProps }}>
                    {displayValue}
                  </span>
                  {sortIcons[idx] && sortIcons[idx] !== null && (
                    <i className={sortIcons[idx]} />
                  )}
                </>
              </Button>
            ) : (
              <span
                className={classes.headerText}
                style={{
                  textAlign: alignmentLookup[alignment],
                  ...textProps
                }}
              >
                {displayValue}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(HeaderRow)
