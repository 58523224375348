import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { List, Checkbox } from 'antd-mobile'
import { rowClickedFilterQuery, rowClickedFilterArray, rowClickedFilterCheckboxQuery } from '../../store/actions/rowClickCheck'
import _ from 'lodash'

const CheckboxWidget = ({
  selectedValues = [],
  setSelectedValues,
  allValues =[],
  affectedFromList,
  affectedFromCounter,
  setAffectedFromCounter,
  id,
  nodeId,
  ...otherProps
}) => {
  const dispatch = useDispatch()

  const isChecked = useCallback(
    item => {
      return selectedValues.includes(item)
    },
    [selectedValues]
  )

  const query = useSelector(state => {
    return state.rowClickCheck.filterQuery
  })

  const checkboxQuery = useSelector(state => {
    return state.rowClickCheck.filterCheckbox
  })

  const toggleItem = useCallback(
    async item => {
      let values, mainQuery = query.replace(checkboxQuery, ""), newQuery = ""
      if (isChecked(item)) {
        values = selectedValues.filter(value => item !== value)
      } else {
        values = [...selectedValues, item]
      }
      for(var i = 0; i < values.length; i++) {
        newQuery += `&${nodeId}=${values[i]}`
      }
      for(var i = 0; i < affectedFromList.length; i++) {
        if(affectedFromList[i] === id) {
          if(values.length <= selectedValues.length && selectedValues.length && values.length) {
            let newCounterArr = [...affectedFromCounter];
            newCounterArr[i] = affectedFromCounter[i]-1;
            setAffectedFromCounter(newCounterArr)
          }
          else {
            let newCounterArr = [...affectedFromCounter];
            newCounterArr[i] = affectedFromCounter[i] === -1 ? affectedFromCounter[i]+2 : affectedFromCounter[i]+1; 
            setAffectedFromCounter(newCounterArr)
          }
        }
      }
      setSelectedValues(values)
      await dispatch(rowClickedFilterCheckboxQuery(`${newQuery}`))
      await dispatch(rowClickedFilterQuery(`${mainQuery}${newQuery}`))
    },
    [isChecked, selectedValues, setSelectedValues]
  )

  return (
    typeof allValues === 'object' && allValues.length ? <List>
      {allValues.map((item, index) => (
        <Checkbox.CheckboxItem
          key={index}
          onChange={() => toggleItem(item)}
          checked={selectedValues.includes(item)}
        >
          {item}
        </Checkbox.CheckboxItem>
      ))}
    </List> : null
  )
}

export default React.memo(CheckboxWidget)
