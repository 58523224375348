import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavBar, Picker, List } from 'antd-mobile'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { changeTheme } from '../../store/actions/theme'
import { useTheme } from '../../custom-hooks/useTheme'
import themeDemoDashlet from '../../config/themeDemoDashlet.json'
import MenuSidebar from '../../components/menu-sidebar/MenuSidebar'
import EchartsContainer from '../../components/dashlet-widgets/chart/EchartsContainer'
import { ActivityIndicator } from 'antd-mobile'
import useWindowDimensions from '../../custom-hooks/useWindowDimenisons'
import classes from './ThemePage.module.css'

const ThemePage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { showcaseTheme } = useTheme()
  let { height } = useWindowDimensions()

  let selectedThemeId = useSelector(state =>
    _.get(state, 'theme.selectedThemeId')
  )
  const themes = useSelector(state => _.get(state, 'theme.themesList'))

  const [selected, setSelected] = useState(selectedThemeId)
  const [themesOptionArray, setThemesOptionArray] = useState([])
  const [loading, setLoading] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)

  useEffect(() => {
    if (themes) {
      const themesOptionArray = themes.map(theme => {
        return { label: _.get(theme, 'attributes.name'), value: theme.id }
      })
      const options = [
        { label: t('default'), value: 'default' },
        ...themesOptionArray
      ]
      setThemesOptionArray(options)
    }
  }, [t, themes])

  const option = themeDemoDashlet
  option.theme = showcaseTheme
  option.relatedFilter = {}

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [showcaseTheme])

  useEffect(() => {
    dispatch(changeTheme(selected))
  }, [dispatch, selected])

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-bars'
            className='fa fa-bars fa-lg'
            onClick={() => setShowSidebar(!showSidebar)}
          />
        ]}
      >
        <span>{t('theme')}</span>
      </NavBar>
      <MenuSidebar
        showSidebar={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
      />
      <div
        className={classes.flexColumn}
        style={{
          height: height - 45
        }}
      >
        {themesOptionArray.length && (
          <div>
            <Picker
              data={[themesOptionArray]}
              cols={1}
              title={t('themes')}
              cascade={false}
              value={[selected]}
              onOk={themeId => setSelected(themeId[0])}
              okText={t('ok')}
              dismissText={t('cancel')}
            >
              <List.Item arrow='horizontal'>{t('selectTheme')}</List.Item>
            </Picker>
          </div>
        )}
        <div className={classes.card}>
          {loading ? (
            <ActivityIndicator animating size='large' />
          ) : (
            <EchartsContainer option={option} />
          )}
        </div>
      </div>
    </>
  )
}

export default React.memo(ThemePage)
