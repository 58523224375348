import _ from 'lodash'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'


const set = _.set
const FancyObject = {
    create: function(opts) {
        opts.get = function(path) {
            return _.get(opts, path)
        }
        opts.set = function(path, value) {
            return _.set(opts, path, value)
        }
        return opts
    }
}

const FancyShowcaseItem = {
    create: function(opts) {
        let obj = FancyObject.create(opts)
        Object.defineProperty(obj, 'isStep', {
            get: function() {
                return this.item_display_type === 'step'
            }
        });
        Object.defineProperty(obj, 'isMenu', {
            get: function() {
                return this.item_display_type === 'menu'
            }
        });
        Object.defineProperty(obj, 'isBox', {
            get: function() {
                return this.item_display_type === 'box'
            }
        });
        return obj
    }

}

export const storyboardService = {
    showcase: null,
    model: null,
    items: [],
    nextDashboard: null,
    set(path, value) {
        return _.set(this, path, value)
    },
    get(path) {
        return _.get(this, path)
    },
    get isPublicRoute() {
        let currentRouteName = this.get('router._router.currentURL')
        return _.startsWith(currentRouteName, '/public/showcase/')
    },
    get routePrefix() {
        let prefix = 'index'
        if (this.get('isPublicRoute')) {
            prefix = 'public'
        }
        return prefix
    },
    setShowcase(showcase) {
        this.set('showcase', showcase)
    },
    setNextDashboard(dashboard) {
        this.set('nextDashboard', dashboard)
    },
    setQueryParams(queryParams) {
        if (this.get('showcase') && this.get('showcase.drill_through_enabled') === false) {
            set(queryParams, 'filters', '')
        }
        this.set('queryParams', queryParams)
    },
    flushNavigation() {
        this.set('items', [])
    },
    setModel(model) {
        model = FancyObject.create(model)
        this.set('model', model)
        let rootNavItem = {
            'id': model.get('id'),
            'showcaseItemId': null,
            'icon': 'home icon',
            'hint': model.get('title'),
            'route': `${this.get('routePrefix')}.showcase`,
            'isCurrent': false,
            'isFirst': true,
            'isLast': false,
            'dashboard': null,
            'isBox': false,
            'isStep': false,
            'isMenu': false,
            'isDashboard': false,
            'parent': null,
            'order': null
        }
        this.get('items').push(FancyObject.create(rootNavItem))
    },
    setCurrent(model) {
        if (model) {
            this.get('items').forEach(item => {
                item.set('isCurrent', false)
            })
            try {
                this.get('items').findBy('id', model.get('id')).set('isCurrent', true)
            } catch (e) {
                //
            }
        }
    },
    getCurrent() {
        return this.get('items').findBy('isCurrent', true)
    },
    getParent(navItem) {
        return this.get('items').findBy('id', _.get(navItem, 'parent'))
    },
    getChildren(navItem) {
        let children = []
        if (navItem) {
            children = this.get('items').filterBy('parent', navItem.get('id')).sortBy('order') || []
        }
        return children
    },
    generateNavigation(children, level = 0) {
        level = level + 1
        children.forEach(child => {
            child = FancyShowcaseItem.create(child)
            let id = child.get('id')
            let dashboardId = null
            let hint = child.get('title')
            let route = `${this.get('routePrefix')}.showcase.showcaseitem`
            let isBox = child.get('isBox')
            let isStep = child.get('isStep')
            let isMenu = child.get('isMenu')
            if (child.get('dashboard_id') !== null) {
                id = child.get('id')
                dashboardId = child.get('dashboard_id')
                route = `${this.get('routePrefix')}.showcase.dashboard`
                hint = child.get('dashboard.title')
                isBox = false
                isStep = false
                isMenu = false
                let parent = this.get('items').findBy('id', child.get('parent.id'))
                if (parent) {
                    isBox = parent.get('isBox')
                    isStep = parent.get('isStep')
                    isMenu = parent.get('isMenu')
                }
            }
            let isDashboard = child.get('dashboard_id') !== null
            let navItem = {
                'id': id,
                'dashboardId': dashboardId,
                'showcaseItemId': child.get('id'),
                'hint': hint,
                'icon': child.get('icon'),
                'route': route,
                'isCurrent': false,
                'isFirst': false,
                'isLast': false,
                'dashboard': child.get('dashboard_id'),
                'isBox': isBox,
                'isStep': isStep,
                'isMenu': isMenu,
                'level': level,
                'isDashboard': isDashboard,
                'parent': child.get('parent.id') || child.get('showcase.id'),
                'order': child.get('order'),
                'title': child.get('title')
            }
            this.get('items').push(FancyObject.create(navItem))
            if (child.get('children.length')) {
                this.generateNavigation(child.get('children').sortBy('order', 'asc'), level)
            }
            this.get('items.lastObject').set('isLast', true)
        })
    },
    get isStoryboard() {
        return this.get('showcase.is_storyboard')
    },
    get hasNextDashboard() {
        return !!this.get('nextDashboard')
    },
    getPrevFirstDashboardItem(currentItem) {
        let currentIndex = this.get('items').indexOf(currentItem)
        let prevIndex = currentIndex - 1
        let prevItem = _.get(this.get('items'), prevIndex)
        if (prevItem) {
            if (prevItem.get('isDashboard')) {
                return prevItem
            } else {
                this.getPrevFirstDashboardItem(prevItem)
            }
        }
    },
    getNextFirstDashboardItem(currentItem) {
        let currentIndex = this.get('items').indexOf(currentItem)
        let nextIndex = currentIndex + 1
        nextIndex = nextIndex % this.get('items.length')
        let nextItem = _.get(this.get('items'), nextIndex)
        if (nextItem.get('isDashboard')) {
            return nextItem
        } else {
            this.getNextFirstDashboardItem(nextItem)
        }
    },
    getPrevItem(currentNavItem) {
        if (this.get('items.length')) {
            if (currentNavItem) {
                if (currentNavItem.get('level') === 1) {
                    if (currentNavItem.get('isDashboard') && !currentNavItem.get('isFirst')) {
                        let currentNavItemIndex = this.get('items').indexOf(currentNavItem)
                        let prevNavItemIndex = currentNavItemIndex - 1
                        return _.get(this.get('items'), prevNavItemIndex)
                    }
                    return this.getParent(currentNavItem)
                }
                let prevDashboardItem = this.getPrevFirstDashboardItem(currentNavItem)
                let currentNavParent = currentNavItem.get('parent')
                if (currentNavItem.get('isBox') && !currentNavItem.get('isDashboard')) {
                    let currentNavItemIndex = this.get('items').indexOf(currentNavItem)
                    let prevNavItemIndex = currentNavItemIndex - 1
                    let prevItem = _.get(this.get('items'), prevNavItemIndex)
                    if (currentNavItem.get('isBox') && !currentNavItem.get('isFirst')) {
                        let sameLevelItems = this.get('items').filterBy('level', currentNavItem.get('level'))
                        if (sameLevelItems.get('length')) {
                            let allAreBoxes = _.every(sameLevelItems.getEach('isBox'))
                            if (allAreBoxes) {
                                let currentOrder = currentNavItem.get('order') - 1
                                if (currentOrder && _.isNumber(currentOrder)) {
                                    let prevOrder = currentOrder - 1
                                    prevItem = sameLevelItems.filterBy('order', prevOrder).get('firstObject')
                                    if (prevItem) {
                                        return this.getParent(prevItem)
                                    } else {
                                        return this.getParent(currentNavItem)
                                    }
                                }
                            }
                        }
                    }
                    if (currentNavItem.get('isBox') && prevItem.get('isStep') && currentNavItem.get('isDashboard') === false) {
                        return prevDashboardItem
                    }
                    if (prevItem.get('isBox')) {
                        if (prevItem.get('isDashboard')) {
                            let parentNavItem = this.getParent(currentNavItem)
                            if (parentNavItem.get('isDashboard')) {
                                return this.getPrevItem(prevItem)
                            } else {
                                return parentNavItem
                            }
                        } else {
                            return prevItem
                        }
                    }
                    if (prevItem.get('isStep')) {
                        return this.getPrevItem(prevItem)
                    }
                    if (prevItem.get('isFirst')) {
                        return prevItem
                    }
                }
                let currentParentNavItem = this.getParent(currentNavItem)
                if (currentParentNavItem && !currentParentNavItem.get('isFirst')) {
                    if (currentParentNavItem.get('isMenu')) {
                        let prevNavItem = this.getPrevItem(currentParentNavItem)
                        if (!prevNavItem) {
                            let rootParent = currentParentNavItem.get('parent')
                            let prev = this.get('items').findBy('id', rootParent)
                            if (prev.get('isFirst')) {
                                let prevChildren = this.getChildren(prev)
                                let prevChildrenLength = _.get(prevChildren, 'length')
                                if (prevChildrenLength > 1) {
                                    prevNavItem = prev
                                }
                            }
                        }
                        return prevNavItem
                    }
                    if (currentParentNavItem.get('isBox')) {
                        return currentParentNavItem
                    }
                    if (currentParentNavItem.get('isStep')) {
                        let prevNavItem = null
                        if (prevDashboardItem && prevDashboardItem.get('parent') === currentNavParent) {
                            prevNavItem = prevDashboardItem
                            if (!prevNavItem) {
                                prevNavItem = this.getPrevFirstDashboardItem(currentParentNavItem)
                            }
                            if (prevNavItem) {
                                if (prevNavItem.get('parent') !== currentNavParent) {
                                    let prevParentNavItem = this.getParent(prevNavItem)
                                    if (prevParentNavItem && prevParentNavItem.get('isStep') && prevNavItem.get('isDashboard')) {
                                        return prevNavItem
                                    } else {
                                        return this.getPrevItem(prevNavItem)
                                    }
                                } else {
                                    return prevNavItem
                                }
                            }
                        } else {
                            if (currentParentNavItem.get('isDashboard')) {
                                return currentParentNavItem
                            } else {
                                let currentParentParentNavItem = this.getParent(currentParentNavItem)
                                if (currentParentParentNavItem.get('isFirst') || currentParentParentNavItem.get('isBox') === true) {
                                    let children = this.getChildren(currentParentParentNavItem)
                                    if (_.get(children, 'length') < 2 && currentParentNavItem.get('isStep')) {
                                        currentParentParentNavItem = null
                                    }
                                    return currentParentParentNavItem
                                } else {
                                    let prev = this.getPrevItem(currentParentParentNavItem)
                                    if (!prev) {
                                        prev = this.getPrevItem(currentParentNavItem)
                                    }
                                    return prev
                                }
                            }
                        }
                    }
                } else {
                    let currentParentNavItemChildren = this.getChildren(currentParentNavItem)
                    if (currentParentNavItem.get('isFirst') === true && _.get(currentParentNavItemChildren, 'length') > 1) {
                        return currentParentNavItem
                    } else {
                        return this.getPrevFirstDashboardItem(currentNavItem)
                    }
                }
                return prevDashboardItem
            }
        }
    },
    getNextItem(currentItem) {
        if (currentItem) {
            if (this.get('items.length')) {
                let nextItem = null
                let children = this.getChildren(currentItem)
                let currentParentNavItem = this.getParent(currentItem)
                let currentNavItemIndex = this.get('items').indexOf(currentItem)
                let nextNavItemIndex = currentNavItemIndex + 1
                let nextNavItem = _.get(this.get('items'), nextNavItemIndex)
                if (nextNavItem) {
                    let nextParentNavItem = this.getParent(nextNavItem)
                    let isInSameParent = nextParentNavItem.get('id') === currentParentNavItem.get('id')
                    if (isInSameParent && nextNavItem.get('isStep') && nextNavItem.get('isDashboard')) {
                        nextItem = nextNavItem
                    } else {
                        if (currentItem.get('isStep') || currentItem.get('isMenu')) {
                            if (currentItem.get('isDashboard') === false) {
                                let firstNavItem = children.get('firstObject')
                                if (firstNavItem.get('isDashboard')) {
                                    nextItem = firstNavItem
                                } else {
                                    return this.getNextItem(firstNavItem)
                                }
                            } else {
                                if (currentItem.get('isMenu')) {
                                    let parentItem = this.getParent(currentParentNavItem)
                                    if (!parentItem.get('isFirst')) {
                                        return this.getNextItem(currentParentNavItem)
                                    }
                                } else {
                                    nextItem = nextNavItem
                                }
                            }
                        }
                        if (currentItem.get('isBox') && !currentItem.get('isDashboard')) {
                            nextItem = currentItem
                        }
                        if (!nextItem) {
                            nextItem = nextNavItem
                        }
                    }
                    this.setNextDashboard(nextItem)
                    return nextItem
                }
            } else {
                throw Error('Items not set.')
            }
        }
    }
}

export const useStoryBoard = () => {
    const dispatch = useDispatch()
    const { showcaseId } = useParams()
    const history = useHistory()
    const { url } = useRouteMatch()
    const isPublicRoute = url?.includes('public')
    const navigation = storyboardService?.items

    const getPrevItem = function () {
        let navItem = navigation.findBy('isCurrent', true)
        let prevItem = storyboardService.getPrevItem(navItem)
        let children = storyboardService.getChildren(prevItem)
        let childrenMenuItems = children.filterBy('isDashboard', true).sortBy('order').getEach('showcaseItemId')
        let allItems = navigation
        if (allItems && allItems.length - 1 === childrenMenuItems.length && prevItem.get('isFirst')) {
            return false
        }
        return prevItem
    }

    const prevItem = getPrevItem()

    const goPrev = function () {
        if (prevItem) {
            dispatch({ type: 'DESTROY_DASHBOARD' })
            if (isPublicRoute) {
                history.push(`/public/showcase/${showcaseId}/${prevItem.id}`)
            } else {
                history.push(`/showcase/${showcaseId}/${prevItem.id}`)
            }
        }
    }

    const getNextItem = function () {
        let navItem = navigation.findBy('isCurrent', true)
        return storyboardService.getNextItem(navItem)
    }

    const nextItem = getNextItem()

    const goNext = function () {
        if (nextItem) {
            dispatch({ type: 'DESTROY_DASHBOARD' })
            if (isPublicRoute) {
                history.push(`/public/showcase/${showcaseId}/${nextItem.id}`)
            } else {
                history.push(`/showcase/${showcaseId}/${nextItem.id}`)
            }
        }
    }

    return {
        prevItem,
        goPrev,
        nextItem,
        goNext
    }
}