import React, { useMemo, useContext, useCallback } from 'react'
import { useDoubleClick } from '../../../custom-hooks/useDoubleClick'
import CardHeader from '../CardHeader/CardHeader'
import _ from 'lodash'
import {  useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { DashletCountContext } from '../../dashlet-widgets/dashlet-widget/DashletWidget'
import classes from './Card.module.css'

// import { useTheme } from '../../custom-hooks/useTheme'

const Card = ({
  children,
  model,
  noBorder,
  actions,
  showFilterIcon,
  showResetIcon,
  noTitle
}) => {
  // const { themeBackgroundColor } = useTheme(true)
  const history = useHistory()
  const { url } = useRouteMatch()
  const  { dashletId } = useParams()
  const dashletCount = useContext(DashletCountContext)

  const type = _.get(model, 'type')

  let isGrid
  if (type === 'grid' || type === 'detail' || type === 'pivot') {
    isGrid = true
  }

  const handleFullScreen = () => {
    history.push(`${url}/${model.id}`)
  }

  const { onDoubleTap } = useDoubleClick(handleFullScreen)

  const handleClick = useCallback(() => {
      if (dashletCount === 1) {
        return
      }
      onDoubleTap()
  }, [dashletCount, onDoubleTap])

  const cardView = useMemo(() => {
    return (
      <div className={classes.cardSubContainer}>
        <CardHeader
          showResetIcon={showResetIcon}
          showFilterIcon={showFilterIcon}
          actions={actions}
          model={model}
          noTitle={noTitle}
          dashletCount={dashletCount}
        />
        {isGrid ? (
          <div className={classes.gridContainer}>{children}</div>
        ) : (
          <div
            style={{
              flex: 1
            }}
          >
            {children}
          </div>
        )}
      </div>
    )
  }, [actions, children, model, noTitle, showFilterIcon, showResetIcon, isGrid, dashletCount])

  if (noBorder) {
    return <div>{children}</div>
  }

  if (!dashletId) {
    return (
      <div className={classes.cardContainer} onClick={handleClick}>
        {cardView}
      </div>
    )
  }

  return cardView
}

export default React.memo(Card)
