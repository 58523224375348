
export default function(DS) {
  return {
    id: DS.attr('string'),
    title: DS.attr('string'),
    help_text:  DS.attr('string'),
    nodes: DS.hasMany('node'),
    restrictions: DS.hasMany('restriction'),
    _filters: DS.hasMany('filter'),
    _tables: DS.attr(),
    _custom_variables: DS.attr(),
    _expressions: DS.hasMany('sqlexpression'),
    _schema: DS.attr(),
    database_name: DS.attr('string'),
    table_name: DS.attr('string'),
    engine: DS.attr('string'),
    created_at: DS.attr('date'),
    updated_at: DS.attr('date'),
    owner: DS.attr('number'),
    owner_full_name: DS.attr('string'),
    cache_period: DS.attr('string'),
    cache_hour: DS.attr('number'),
    cache_week_day: DS.attr('number'),
    cache_day: DS.attr('number'),
    cache_month: DS.attr('number'),
    available_gismap_layers: DS.attr(),
    use_default_filter_date_range: DS.attr('boolean'),
    default_filter_date_range: DS.attr(),
    default_filter_date_range_values: DS.attr(),
    shares: DS.attr(),
    owner_id: DS.attr('number'),
    permissions: DS.attr(),
    default_datacells_dashlet: DS.attr('string'),
  }
}
