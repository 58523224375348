import React, { useState, useCallback, useEffect } from 'react'
import { NavBar, SearchBar } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { client } from '../../../store/configureStore'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { FilterService } from '../../../store/reducers/currentfilters'
import { fetchFilteredData } from '../../../store/actions/filteredData'
import {
  CheckboxWidget,
  RadioWidget,
  TextboxWidget,
  RangeInputWidget,
  DatePickerWidget
} from '../'
import { useStoryBoard } from '../../../custom-hooks/useStoryBoard'
import AppConfig from '../../../config/appConfig'
import moment from 'moment'

const FilterItemScreen = ({ filter: { id }, setShowFilterItems, setShowFilterWarning, setShowFilters, isRequiredFilter, showFilterItems, isStoryBoard, allValues, setAllValues, selectedValues, setSelectedValues, firstTimeCheck, setFirstTimeCheck, appliedFilters, affectedFromList, affectedFromCounter, setAffectedFromCounter }) => {
  client.defaults.baseURL = AppConfig.apiUrl
  const location = useLocation()
  const { filterType: filterTypeParam } = location.state || {}
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { goPrev } = useStoryBoard()

  const filter = useSelector(state => {
    let getter = FilterService.getFilter
    if (filterTypeParam === 'V') {
      getter = FilterService.getVariableFilter
    } else if (filterTypeParam === 'F') {
      getter = FilterService.getCustomFilter
    }
    return getter(state.currentfilters, id, 1)
  })
  
  const values = useSelector(state => {
    return state.filteredData.values
  })

  const filterArray = useSelector(state => {
    return state.rowClickCheck.filterArray
  })

  const filters = useSelector(state => {
    return state.currentfilters.filters
  })

  const convert2DateObject = (relativeDateObject, filter) => {
    let anchor = moment()
    if (relativeDateObject.anchor === 'last_data') {
      anchor = moment(_.get(filter, 'info.max'));
    }
    if (/^\d{4}/.exec(relativeDateObject.anchor)) {
      anchor = moment(relativeDateObject.anchor, 'YYYY-MM-DDTHH:mm:ss')
    }
    anchor = anchor.add(relativeDateObject.move, relativeDateObject.unit)
    if (relativeDateObject.behavior === 'snap_beginning') {
      anchor = anchor.startOf(relativeDateObject.unit === 'week' ? 'isoWeek' : relativeDateObject.unit)
    } else if (relativeDateObject.behavior === 'snap_ending') {
      anchor = anchor.endOf(relativeDateObject.unit === 'week' ? 'isoWeek' : relativeDateObject.unit)
    }
    return anchor
  }

  const getRanges = (filter) => {
    let ranges = {}
    let availableTimeSegment = [moment(min), moment(max)]
    let keys = _.filter(AppConfig.DATE_AGGREGATE_TYPES.ALL_INTERVAL_KEYS, interval => { return interval !== 'all' })
    _.forEach(keys, function (interval) {
      let obj = AppConfig.DATE_AGGREGATE_TYPES.RELATIVE_DATE_SETTINGS[interval]
      let intervalAsTimeSegment = [convert2DateObject(obj[0], filter), convert2DateObject(obj[1], filter)]
      if(checkTimeOverlap([intervalAsTimeSegment, availableTimeSegment])) {
        ranges[interval] =intervalAsTimeSegment
      }
    })
    return ranges
  }

  const checkTimeOverlap = (timeSegments) => {
    return (timeSegments[0][0].isBefore(timeSegments[1][0]) 
      ? timeSegments[0][1].isAfter(timeSegments[1][0]) 
      : timeSegments[1][1].isAfter(timeSegments[0][0]))
  }

  let min = _.get(filter, 'info.min')
  if (min !== 0 && !min) {
    min = 10
  }
  let max = _.get(filter, 'info.max')
  if (max !== 0 && !max) {
    max = 10000
  }
  let step = _.get(filter, 'info.step')
  if (step !== 0 && !step) {
    step = 1
  }
  const dataType = _.get(filter, 'dataType')
  const [applyAutoFilter, setApplyAutoFilter] = useState(false)

  const nodeId = filter.node.id

  const query = useSelector(
    state => {
      return state.rowClickCheck.filterQuery
    }
  )

  const urlCreator = useCallback(()=>{
    let category = _.get(filter, 'id')
    let url = '/filtervalues/?filters='
    let query = ''
    if(appliedFilters.length && category !== undefined) {
      for (var i = 0; i < appliedFilters.length; i++) {
        query = _.get(appliedFilters[i], 'query')
        url += `${encodeURIComponent(query)}&`
      }
      url += `id=${category}`
      return url
    } else if(filterArray.length && category !== undefined) {
      for (var i = 0; i < filterArray.length; i++) {
        let value = _.values(filterArray[i])[0]
        let key = _.keys(filterArray[i])[0]
        let idx = _.findIndex(filters, { alias: key })
        let id = _.get(filters, `${idx}.id`)
        if(i !== filterArray.length-1) { url += `${id}${encodeURIComponent('='+value+'&')}` } else { url += `${id}${encodeURIComponent('='+value)}&` }
      }
      if(selectedValues.length) {
        for (var i = 0; i < selectedValues.length; i++) {
          url += `${category}${encodeURIComponent('='+selectedValues[i])}&`
        }
      }
      url += `id=${category}`
      return url
    }
    return null
  }, [filter, appliedFilters, filterArray])

  const url = urlCreator()

  const handleApply = useCallback(() => {
    let func = 'setValues'
    let id = _.get(filter, 'id')
    if (filter.type === 'variable') {
      func = 'setVariableFilterValues'
      id = _.get(filter, '_id_')
    } else if (filter.type === 'custom') {
      func = 'setCustomFilterValues'
      id = _.get(filter, '_id_')
    }
  
    setShowFilterItems(false)
    dispatch({
      type: 'FILTER_SERVICE_CALL',
      func: func,
      params: {
        id: id,
        clone: 1,
        values: selectedValues
      }
    })
  }, [dispatch, filter, selectedValues, setShowFilterItems])
  
  const handleFilteredData = useCallback(() => {
    dispatch(fetchFilteredData(url))
  }, [dispatch])

  useEffect(() => {
    if (applyAutoFilter && isRequiredFilter) {
      handleApply()
      handleFilteredData()
      setShowFilterWarning(false)
    } else{
      if(firstTimeCheck) {
        setAllValues(_.get(filter, 'info.values'))
        setFirstTimeCheck(false)
      } else{
        handleFilteredData()
      }
    }
  }, [applyAutoFilter, handleApply, handleFilteredData, isRequiredFilter, setShowFilterWarning])
  
  useEffect(()=>{
    if(values !== undefined) {
      setAllValues(values)
    } else {
      setAllValues(_.get(filter, 'info.values'))
    }
  }, [filter, values])

  useEffect(()=>{
    if(firstTimeCheck) {
      setSelectedValues(_.get(filter, 'queryValues'))
      setAllValues(_.get(filter, 'info.values'))
      setFirstTimeCheck(false)
    } else {
      setAllValues(_.get(filter, 'info.values'))
    }
  }, [])

  useEffect(()=>{
    for(var i = 0; i < affectedFromList.length; i++) {
      if(affectedFromList[i] === id && affectedFromCounter[i] === 0 && !firstTimeCheck) {
        goPrev()
      }
    }
  }, [affectedFromCounter, affectedFromList, firstTimeCheck])

  const handleSelectedValues = (val) => {
    setSelectedValues(val)
    if (isRequiredFilter) {
      setApplyAutoFilter(false)
    } else{
      if(firstTimeCheck) {
        setAllValues(_.get(filter, 'info.values'))
        setFirstTimeCheck(false)
      }
    }
  }

  const searchValues = useCallback(
    query => {
      if (_.get(filter, 'info.count') < 100) {
        let values = _.get(filter, 'info.values')
        let newValues = _.filter(values, function (value) {
          let lquery = query.toLowerCase()
          let lvalue = value.toLowerCase()
          return lvalue.indexOf(lquery) > -1
        })
        setAllValues(newValues)
      } else {
        let params = {
          id: _.get(filter, 'id'),
          q: query,
          exclude: selectedValues
        }
        let queryParams = FilterService._dictToUrl(params)

        let url = `/filtervalues/?${queryParams}`
        client
          .get(url)
          .then(response => {
            if (response) {
              setAllValues(_.get(response, 'data.data.attributes.values'))
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    [filter, selectedValues]
  )

  let filterType = _.get(filter, 'inputType')
  let header = null
  if (
    ['checkbox', 'multiple_select', 'single_select', 'radio'].includes(
      filterType
    )
  ) {
    header = (
      <SearchBar
        placeholder={t('search')}
        maxLength={50}
        cancelText={t('close')}
        onChange={value => searchValues(value)}
      />
    )
  }

  let isSingle = false
  let FilterItemWidget = CheckboxWidget
  if (['checkbox', 'multiple_select'].includes(filterType)) {
    FilterItemWidget = CheckboxWidget
  } else if (['radio', 'single_select'].includes(filterType)) {
    FilterItemWidget = RadioWidget
  } else if (filterType === 'textbox') {
    FilterItemWidget = TextboxWidget
  } else if (
    ['range', 'range_input', 'single-slider', 'single_slider'].includes(
      filterType
    )
  ) {
    isSingle = ['single-slider', 'single_slider'].includes(filterType)
    FilterItemWidget = RangeInputWidget
  } else if (['datepicker', 'datepicker_input'].includes(filterType)) {
    FilterItemWidget = DatePickerWidget
  }

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => {
              setShowFilterItems(false)
              if (isRequiredFilter && isStoryBoard) {
                goPrev()
              } else if (isRequiredFilter) {
                setShowFilterWarning(true)                              
              } else {
                setShowFilters(true)
              }
            }}
          />
        ]}
        // since isStoryBoard(attributes) does not exist for all showcases it returns undefined sometimes
        rightContent={[
          <span
            style={{ color: '#333' }}
            key={1}
            onClick={() => {
              setShowFilterWarning(false)
              handleApply(selectedValues)
            }}
          >
            {t('Apply')}
          </span>
        ]}
      >
        <span>{t(_.get(filter, 'alias'))}</span>
      </NavBar>
      {header}
      <FilterItemWidget
        setSelectedValues={handleSelectedValues}
        selectedValues={selectedValues}
        allValues={allValues}
        min={min}
        max={max}
        step={step}
        dataType={dataType}
        isSingle={isSingle}
        ranges = {getRanges(filter)}
        field={_.get(filter, 'fieldName')}
        affectedFromCounter={affectedFromCounter}
        setAffectedFromCounter={setAffectedFromCounter}
        affectedFromList={affectedFromList}
        id={id}
        nodeId={nodeId}
      ></FilterItemWidget>
    </>
  )
}

export default React.memo(FilterItemScreen)
