import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InputItem, Button, Flex, WhiteSpace } from 'antd-mobile'
import axios from 'axios'
import { client } from '../../store/configureStore'
import { showAlert } from '../../helpers/utils'
import AppConfig from '../../config/appConfig'
import classes from './ResetPasswordPage.module.css'

import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ResetPasswordPage = () => {
  const { t } = useTranslation()
  const { appWhiteLogo} = useSelector(state => state.settings)
  const location=useLocation()
  const history = useHistory()
  const headers = {
    'Content-Type': 'application/vnd.api+json; charset=UTF-8',
    Accept: 'application/json, text/javascript, */*; q=0.01',
  }

  const [token, setToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassord]=useState('')
  const [loading, setLoading] = useState(false)

  const validateToken = async (token) => {
    const passwordResetUrl = `${client.defaults.baseURL}/password-reset/validate-token/`
    const data = {
      type: 'passwordresetvalidatetoken',
      attributes: {
        token: token,
      },
    }

    try {
      await axios.post(passwordResetUrl, { data }, { headers })
    } catch (error) {
      history.push('/login') 
    }
  }
  
  useEffect(() => {
    const tokenFromURL = location.search.replace('?token=', '')
    if (tokenFromURL) {
      setToken(tokenFromURL);
      validateToken(tokenFromURL);
    } else{
      history.push('/login')
    }
  }, [location.search])
  
  const handleChangePassword = async () => {
    if (!newPassword || !confirmPassword ) {
      showAlert(t('error'), t('invalidInput'), t('close'))
      return
    }

    if (newPassword.length < 8 || confirmPassword.length < 8) {
      showAlert(t('error'), t('passwordNotLongError'), t('close'))
      return
    }

    if (newPassword !== confirmPassword) {
      showAlert(t('error'), t('passwordsDoNotMatch'), t('close'))
      return
    }
    changePassword()
  }
  
  const changePassword = async () => {

    setLoading(true)

    const passwordChangeUrl = `${client.defaults.baseURL}/password-reset/confirm/`

    const data = {
      type: 'passwordresetconfirm',
      attributes: {
        token: token,
        password: newPassword
      },
    };
    try {
      const response = await axios.post(passwordChangeUrl, { data }, { headers })
      if(response.status===200){
        history.push('/login')
      }
    } catch (error) {
      showAlert(t('error'), t('changePasswordError'), t('close'))
      console.error(error)
    }finally {
      setLoading(false)
    }
  }
  return (
        <Flex direction='column' className={classes.container}>
        <>
          <Flex justify='center' className={classes.subContainer}>
            <img className={classes.logo} src={`${AppConfig.baseUrl}${appWhiteLogo || '/assets/images/logo-white.svg'}`} alt='logo' />
          </Flex>
          <Flex direction='column' className={classes.subContainer}>
            <InputItem
              className={classes.input}
              type='password'
              placeholder={t('newPassword')}
              onChange={newPassword => setNewPassword(newPassword)}
              value={newPassword}
            />
            <WhiteSpace size='lg' />
            <InputItem
              className={classes.input}
              type='password'
              placeholder={t('newPasswordConfirmation')}
              onChange={confirmPassword => setConfirmPassord(confirmPassword)}
              value={confirmPassword}
            />
            <WhiteSpace size='lg' />
            <Button
              loading={loading}
              className={classes.button}
              type='primary'
              onClick={handleChangePassword}
            >
              {t('confirm')}
            </Button>
            <WhiteSpace size='lg' />
          </Flex>
        </>
    </Flex> 
  )
}

export default React.memo(ResetPasswordPage)