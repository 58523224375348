import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { ChartContext } from '../dashlet-widget/DashletWidget'
import * as echarts from 'echarts'
require('echarts-wordcloud')
require('echarts-gl')

const EchartsContainer = ({
  option: { dashlet, data, theme, relatedFilter },
  actions
}) => {
  const dataString = JSON.stringify(data)
  const dashletString = JSON.stringify(dashlet)
  const themeString = JSON.stringify(theme)
  const relatedFilterString = JSON.stringify(relatedFilter)

  const isSlave = data?.meta?.type === 'slave'

  const myChart = useRef({})
  const { updateChart }= useContext(ChartContext)

  const send = useCallback(
    function () {
      var args = Array.prototype.slice.call(arguments)
      var funcName = args.shift()
      try {
        actions[funcName](...args)
      } catch (error) {
        console.log(error)
      }
    },
    [actions]
  )

  myChart.current.send = send
  const [chartVal, setChart] = useState(null)
  useEffect(() => {
    if (myChart.current) {
      const chartOption = window.createChart.bind(myChart.current)(
        dataString,
        dashletString,
        themeString,
        relatedFilterString
      )
      myChart.current.send = send
      let chart = echarts.init(myChart.current, theme, { renderer: 'canvas' })
      setChart(chart)
      if (myChart.current.model && myChart.current.model.geojson) {
        let uuid = Math.floor((1 + Math.random()) * 0x10000).toString()
        echarts.registerMap(uuid, myChart.current.model.geojson)
        chartOption.series[0].map = uuid
      }
      chartOption.beforeSetup.call(myChart.current, chartOption, chart)
      chart.setOption(chartOption)
      chartOption.onChartReady.call(myChart.current, chartOption, chart)
      updateChart(chart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (myChart.current && chartVal) {
      const chartOption = window.createChart.bind(myChart.current)(
        dataString,
        dashletString,
        themeString,
        relatedFilterString
      )
      myChart.current.send = send
      if (myChart.current.model && myChart.current.model.geojson) {
        let uuid = Math.floor((1 + Math.random()) * 0x10000).toString()
        echarts.registerMap(uuid, myChart.current.model.geojson)
        chartOption.series[0].map = uuid
      }
      try {
        if (isSlave) {
          chartVal.setOption(chartOption, false, false)
        } else {
          chartVal.setOption(chartOption, true, false)
        }
      } catch(e) {
        // console.log(e)
      }
    }
  }, [data])

  return (
    <div
      ref={myChart}
      style={{ flex: 1, width: '100%', height: '100%'}}
    />
  )
}

export default React.memo(EchartsContainer)
