import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'
import CardTitle from '../CardTitle'
import classes from './CardHeader.module.css'
import {  useParams, useHistory, useRouteMatch } from 'react-router-dom'

const CardHeader = ({
  noTitle,
  model,
  actions,
  showResetIcon,
  showFilterIcon,
  dashletCount,
  handleScreenshot
}) => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const  { dashletId } = useParams()


  const dashletList = useMemo(() => {
    const drillthroughdashlets = _.get(model, 'drillthroughdashlets')
    let dashletList = [] //drillthrough dashlets
    if (_.get(drillthroughdashlets, 'length')) {
      dashletList.push({ title: model.title, id: model.id })
      drillthroughdashlets.forEach(dashlet => {
        dashletList.push({
          title: dashlet.title,
          id: _.get(dashlet, 'to_dashlet.id')
        })
      })
    }

    return dashletList
  }, [model])

  let hideTitle
  if (noTitle) {
    hideTitle = true
  } else if (dashletId && !dashletList.length) {
    hideTitle = true
  }

  const handleFullScreen = useCallback(() => {
    history.push(`${url}/${model.id}`)
  }, [model, history, url])

  return (
    <div
      className={classes.container}
      style={{
        justifyContent: hideTitle ? 'flex-end' : 'space-between'
      }}
    >
      {!hideTitle && (
        <CardTitle dashletList={dashletList} model={model} actions={actions} />
      )}
      <div className={classes.iconContainer}>
        {(dashletId || dashletCount === 1) && showFilterIcon && (
          <i
            className={`fa fa-arrow-down fa-lg ${classes.icon}`}
            aria-hidden='true'
            onClick={actions.drilldown}
          ></i>
        )}
        {(dashletId || dashletCount === 1) && showResetIcon && (
          <i
            className={`fa fa-refresh fa-lg ${classes.icon}`}
            aria-hidden='true'
            onClick={actions.resetSelection}
          ></i>
        )}
        {/* {dashletId && (
          <i
           
            className={`fa fa-share-alt fa-lg ${classes.icon}`}
            aria-hidden='true'
            onClick={handleScreenshot}
          ></i>
        )} */}
        {!dashletId && dashletCount > 1 && (
          <i
            className={`fa fa-expand fa-lg ${classes.icon}`}
            aria-hidden='true'
            onClick={handleFullScreen}
          ></i>
        )}
      </div>
    </div>
  )
}

export default React.memo(CardHeader)
