import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { InputItem, Button, Flex, WhiteSpace, Modal } from 'antd-mobile'
import axios from 'axios'
import { client } from '../../store/configureStore'
import { validateEmail, showAlert } from '../../helpers/utils'
import _ from 'lodash'
import AppConfig from '../../config/appConfig'
import classes from './ForgotPasswordPage.module.css'

const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  const { appWhiteLogo} = useSelector(state => state.settings)

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [showRedirectScreen, setShowRedirectScreen] = useState(false)

  const sendResetEmail = async () => {
    const isValidEmail = validateEmail(email)
    if (!isValidEmail) {
      showAlert(t('error'), t('invalidEmailError'), t('close'))
      return
    }
    setLoading(true)
    const passwordResetUrl = `${client.defaults.baseURL}/password-reset/`
    const data = {
      type: 'passwordreset',
      attributes: {
        email
      }
    }
    const headers = {
      'Content-Type': 'application/vnd.api+json; charset=UTF-8',
      Accept: 'application/json, text/javascript, */*; q=0.01'
    }

    try {
      const response = await axios.post(passwordResetUrl, { data }, { headers })
      if (_.get(response, 'status') === 200) {
        setShowRedirectScreen(true)
      } else {
        throw new Error('resetPasswordError')
      }
    } catch (error) {
      Modal.alert(t('error'), t('resetPasswordError'), [
        { text: t('cancel'), onPress: () => false },
        {
          text: t('close'),
          onPress: () => {
            // dispatch(logoutUser(true))
            // history.push('/login')
          }
        }
      ])
    }
    setLoading(false)
  }

  return (
    <Flex direction='column' className={classes.container}>
      {showRedirectScreen ? (
        <div>
          <span>{t('resetPasswordLinkHasBeenSent')}</span>
          <Link to='/login'>{t('goBackToLoginScreen')}</Link>
        </div>
      ) : (
        <>
          <Flex justify='center' className={classes.subContainer}>
            <img className={classes.logo} src={`${AppConfig.baseUrl}${appWhiteLogo || '/assets/images/logo-white.svg'}`} alt='logo' />
          </Flex>
          <Flex direction='column' className={classes.subContainer}>
            <InputItem
              className={classes.input}
              type='text'
              placeholder={t('email')}
              onChange={email => setEmail(email)}
              value={email}
            />
            <WhiteSpace size='lg' />
            <Button
              loading={loading}
              className={classes.button}
              type='primary'
              onClick={sendResetEmail}
            >
              {t('send')}
            </Button>
            <WhiteSpace size='lg' />
            <Link to='/login' style={{ color: '#fff' }}>
              {t('goBackToLoginScreen')}
            </Link>
          </Flex>
        </>
      )}
    </Flex>
  )
}

export default React.memo(ForgotPasswordPage)