import React, { useMemo } from 'react'
import { Drawer, List } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../../store/actions/auth'
import AppConfig from '../../config/appConfig'

const MenuSidebar = ({ showSidebar, closeSidebar }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  let { firstName, lastName } = useSelector(state => state.auth)
  const { appWhiteLogo, appDarkLogo, SSOEnabled, SSOLogoutUrl } = useSelector(state => state.settings)

  const menuItems = [
    {
      icon: 'fa fa-user fa-lg',
      text: `${firstName} ${lastName}`,
      path: '/profile'
    },
    {
      icon: 'fa fa-bar-chart',
      text: t('showcases'),
      path: '/showcases'
    },
    {
      icon: 'fa fa-pie-chart',
      text: t('dashboards'),
      path: '/dashboards'
    },
    // {
    //   icon: 'fa fa-paint-brush',
    //   text: t('theme'),
    //   path: '/themes'
    // },
    {
      icon: 'fa fa-sign-out fa-lg',
      text: t('logout'),
      path: '/login'
    }
  ]

  const sidebar = useMemo(
    () => (
      <List style={{ marginTop: '45px' }}>
        <div
          style={{
            backgroundColor: SSOEnabled ? '#f6f7f2' :'#221b44',
            padding: 5
          }}
        >
          <img
            src={`${AppConfig.baseUrl}${(SSOEnabled ? appDarkLogo : appWhiteLogo) || '/assets/images/logo-white.svg'}`}
            alt='logo'
            style={{ width: '100%' }}
          />
        </div>
        {menuItems.map(({ icon, text, path }) => (
          <Link to={path} style={{ color: 'inherit' }} key={text}>
            <List.Item
              multipleLine
              onClick={() => {
                closeSidebar()
                text === t('logout') && dispatch(logoutUser(false, SSOLogoutUrl))
              }}
            >
              <i className={icon} />
              <span style={{ marginLeft: 15 }}>{text}</span>
            </List.Item>
          </Link>
        ))}
      </List>
    ),
    [SSOEnabled, appDarkLogo, appWhiteLogo, menuItems, closeSidebar, t, dispatch, SSOLogoutUrl]
  )

  return (
    <Drawer
      className='my-drawer'
      style={{
        minHeight: document.documentElement.clientHeight,
        zIndex: showSidebar ? 6 : -1,
        position: 'fixed'
      }}
      enableDragHandle
      contentStyle={{ color: '#A6A6A6', textAlign: 'center', paddingTop: 42 }}
      sidebar={sidebar}
      open={showSidebar}
      onOpenChange={closeSidebar}
    >
      <span />
    </Drawer>
  )
}

export default MenuSidebar
