import React, { useState, useEffect, useCallback } from 'react'
import { fetchData } from '../../../store/actions/data'
import { useSelector, useDispatch } from 'react-redux'
import formatter from '../../../helpers/Formatting'
import ChartWidget from '../chart/ChartWidget'
import _ from 'lodash'
import { FilterService } from '../../../store/reducers/currentfilters'
import { ActivityIndicator, Flex } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import Card from '../../dashlet-card/Card/Card'
import classes from './InfocellWidget.module.css'
import iconSet from "./selection.json";
import Icon from "icomoon-react";
import { useTheme } from '../../../custom-hooks/useTheme'

const InfocellWidget = ({ model, dashboard }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showcases } = useSelector(state => state)
  const filterQuery = useSelector(state =>
    FilterService.getQueryParams(state.currentfilters)
  )
  const { showcaseTheme } = useTheme(true)

  let isSparkline = _.get(model, 'measures.0.is_sparkline')
  const measures = model.measures
  if (_.get(measures, 'length') > 1) {
    isSparkline = false
  }

  let scData = _.get(showcases, 'entries.included')
  let iconData = {}
  let iconColor = {}
  scData.map(element => {
    if (element.type === 'dashletconditionalformattings') {
      let id = _.get(element, 'relationships.dashlet.data.id')
      let name = _.get(element, 'attributes.icon_name')
      let color = _.get(element, 'attributes.icon_color')
      iconData[id] = name
      let isDefaultColor = _.get(element, 'attributes.use_default_icon_color')
      iconColor[id] = isDefaultColor ? showcaseTheme.color[0] : color
    }
  })

  const [infocells, setInfocells] = useState([])
  const [dashletData, setDashletData] = useState(null)
  const [noData, setNoData] = useState(false)

  let direction
  if (model.info_cell_column_count >= 2) {
    direction = 'row'
  } else {
    direction = 'column'
  }


  const getData = useCallback(
    async filterQuery => {
      try {
        const response = await dispatch(
          fetchData(
            model.id,
            false,
            model.type,
            _.get(dashboard, 'id'),
            filterQuery
          )
        )
        const data = _.get(response, 'payload.data')
        if (data) {
          setDashletData(data)
        } else {
          setNoData(true)
        }
      } catch (error) {
        setNoData(true)
        console.log(error)
      }
    },
    [dashboard, dispatch, model.id, model.type]
  )

  useEffect(() => {
    getData(filterQuery)
  }, [filterQuery, getData])

  const addPrefixSuffix = useCallback(
    (value, measure) => {
      let prefix = _.get(measure, 'prefix')
      let suffix = _.get(measure, 'suffix')
      if (measure.inherit_prefix_suffix) {
        const node = _.find(model.view.nodes, [
          'id',
          _.get(measure, 'measure.id')
        ])
        prefix = _.get(node, 'prefix')
        suffix = _.get(node, 'suffix')
      }
      prefix = prefix || ''
      suffix = suffix || ''
      value = `${prefix} ${value} ${suffix}`
      return value
    },
    [model.view.nodes]
  )

  const getTitle = useCallback(
    measure => {
      let alias = measure.alias
      const customAlias = measure.custom_alias
      const isMeasure = measure.is_measure

      if (customAlias) {
        alias = customAlias
        if (['All Rows', 'Tüm Satırlar'].includes(alias)) {
          return t('all_rows')
        }
      } else if (isMeasure) {
        const percentage = measure.is_percentage ? ' (%)' : ''
        const func = measure.func
        const function_abbreviation = t(func)
        alias = `${function_abbreviation}${percentage} (${alias})`
      }
      return alias
    },
    [t]
  )

  const setData = useCallback(() => {
    if (_.get(dashletData, 'data')) {
      const data = dashletData.data.filter(
        datum => _.get(datum, 'attributes.meta.type') === 'default'
      )
      const infocellList = []
      for (let datum of data) {
        const measureId = _.get(datum, 'attributes.meta.measure_id')
        const measure = measures.find(measure => measure.id === measureId)
        const title = getTitle(measure)
        let percentageOfTotal = _.get(
          datum,
          'attributes.meta.percentage_of_total'
        )
        percentageOfTotal = _.round(percentageOfTotal, 2)

        let rawValue = _.get(datum, 'attributes.rows.m0.0')
        let value = formatter(
          _.get(measure, 'custom_abbreviation'),
          _.get(measure, 'formatting'),
          rawValue
        )
        value = addPrefixSuffix(value, measure)

        const infocellObj = {
          title,
          value,
          id: datum.id
        }

        if (
          model.info_cell_show_percentage_of_total &&
          percentageOfTotal !== 100
        ) {
          infocellObj.percentageOfTotal = t('showPercentage', {
            percentage: percentageOfTotal
          })
        }

        if (data.length === 1) {
          infocellObj.title = model.title
        }
        infocellList.push(infocellObj)
      }

      setInfocells(infocellList)
    }
  }, [addPrefixSuffix, dashletData, measures, model, getTitle, t])

  useEffect(() => {
    setData()
  }, [setData])

  if (infocells.length) {
    return (
      <Card model={model}>
        <div className={classes.container}>
          {isSparkline && (
            <div className='w-p100'>
              <ChartWidget
                model={model}
                dashboard={dashboard}
                isSparkline={true}
              />
            </div>
          )}
          <div className={classes.subContainer} style={{ flexDirection: direction }}>
            {infocells.map(({ id, value, title, percentageOfTotal }) => {
              const htmlTagRegex = /(<([^>]+)>)/gi
              const isTitleHtml = title.match(htmlTagRegex)
              let iconId = id.slice(0, 18)
              return (
                <div key={id}>
                  <div className={classes.icon}>
                    <div>
                      {iconData[iconId] === 'user77' ? (
                        null
                      ) : (
                        <Icon iconSet={iconSet} color={iconColor[iconId]} size={50} icon={iconData[iconId]} />
                      )}
                    </div>
                    <div className={classes.valueContainer}>
                      <h2 className={classes.value}>
                        {value} {percentageOfTotal}
                      </h2>
                    </div>
                  </div>
                  <div key={id} className={classes.measure}>
                    <div className={classes.titleContainer}>
                      {isTitleHtml ? (
                        <div className={classes.title} dangerouslySetInnerHTML={{ __html: title }} />
                      ) : (
                        <span className={classes.title}>{title}</span>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Card>
    )
  }

  if (noData) {
    return <p>{t('no_data_dashlet', { dashlet: model.title })}</p>
  }

  return null
}

export default React.memo(InfocellWidget)
