import React, { useState, useEffect, useCallback } from 'react'
import { fetchHTMLData } from '../../../store/actions/data'
import { useSelector, useDispatch } from 'react-redux'
import { FilterService } from '../../../store/reducers/currentfilters'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Flex } from 'antd-mobile'
import Card from '../../dashlet-card/Card/Card'
import AppConfig from '../../../config/appConfig'
import { useParams } from 'react-router-dom'
import classes from './HtmlWidget.module.css'

const HTMLWidget = ({ model, dashboard }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showcaseId } = useParams()

  const filterQuery = useSelector(state =>
    FilterService.getQueryParams(state.currentfilters)
  )
  const [dashletData, setDashletData] = useState([])
  const [noData, setNoData] = useState(false)
  const [htmlData, setHtmlData] = useState(null)

  const getData = useCallback(
    async filterQuery => {
      setNoData(false)
      try {
        const response = await dispatch(
          fetchHTMLData(model.id, dashboard.id, filterQuery)
        )
        const data = _.get(response, 'payload.data')
        if (data) {
          setDashletData(data)
          setNoData(false)
        } else {
          setNoData(true)
        }
      } catch (error) {
        console.error(error)
        setNoData(true)
      } finally {
      }
    },
    [dashboard.id, dispatch, model.id]
  )

  useEffect(() => {
    getData(filterQuery)
  }, [getData, filterQuery])

  useEffect(() => {
    let htmlData = _.get(dashletData, 'data.attributes.parsed_data')

    let mustFiltersText = t('useAnyFilter')
    if (htmlData === '__filters_must_be_applied__') {
      const mustFiltersList = _.get(model, 'must_have_filter_ids')
      if (mustFiltersList && mustFiltersList.length) {
        const dashboardFilters = _.get(dashboard, '_dashboard_filters')
        const mustFilters = dashboardFilters.filter(filter =>
          mustFiltersList.includes(filter.id)
        )
        const filterAlias = mustFilters.map(filter => filter.alias)
        mustFiltersText = filterAlias.join(', ')
        mustFiltersText = t('filter_by_x_to_see_visualization', {
          filter: mustFiltersText
        })
      }
      htmlData = `<p>${t(
        'dashlet_wont_show_without_filters'
      )} ${mustFiltersText}</p>`
    }
    setHtmlData(htmlData)
  }, [dashboard, dashletData, model, t])


  let html = htmlData
  if (htmlData) {
    const mobileLink = htmlData.match(/\[mobile(.*)\]/)
    if (Array.isArray(mobileLink)) {
      const showcaseInfo = mobileLink[0]
      html = htmlData.replace('[' + showcaseInfo, '')
      const showcaseInfoArray = mobileLink[1].split('|')
      const id = showcaseInfoArray[1]
      if(id) {
        let href = `${AppConfig.baseUrl}/#/showcase/${id}`
        if (id.includes('SCI') && showcaseId) {
          href = `${AppConfig.baseUrl}/#/showcase/${showcaseId}/${id}`
        }
        html = `<a style='display: block; width: 100%; height: 100%;' href=${href}> ${html} </a>`
      }
    }

    const heightInfo = htmlData.match(/\[quartersize]/) ||  htmlData.match(/\[halfsize]/)
    if (Array.isArray(heightInfo)) {
      let showcaseInfo = heightInfo[0]
      html = htmlData.replace(showcaseInfo, '')
      html = html.replace('[', '')
      html = html.replace(']', '')
    }

    const customHeightInfo = htmlData.match(/\[(.*)mobileheight\]/) 
    if (Array.isArray(customHeightInfo)) {
      let showcaseInfo = customHeightInfo[0]
      html = htmlData.replace(showcaseInfo, '')
      html = html.replace('[', '')
      html = html.replace(']', '')
    }
  }

  if (html) {
    return (
      <Card model={model} noTitle>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={classes.container}
        ></div>
      </Card>
    )
  }

  if (noData) {
    return <p>{t('no_data')}</p>
  }

  return null
}

export default React.memo(HTMLWidget)
