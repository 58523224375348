import React from 'react'
import _ from 'lodash'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { createTitle } from '../../helpers/utils'
import { useSelector } from 'react-redux'

const CardTitle = ({ dashletList, model, actions }) => {
  const currentfilters = useSelector(state => state.currentfilters)
  const appliedFilters = _.get(currentfilters, 'filters').filter(filter => _.get(filter, 'defaultValues.length'))

  if (_.get(dashletList, 'length')) {
    const dashlets = dashletList.map(dashlet => ({
      label: createTitle(dashlet, appliedFilters),
      value: dashlet.id
    }))

    return (
      <Dropdown
        options={dashlets}
        onChange={dashlet => {
          actions.drillThrough(_.get(model, 'id'), dashlet.value)
        }}
        value={_.get(model, 'id')}
      />
    )
  }

  const title = createTitle(model, appliedFilters)
  return (
    <h3
      style={{
        padding: 0,
        margin: 0,
        marginBottom: 5,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {title}
    </h3>
  )
}

export default React.memo(CardTitle)
