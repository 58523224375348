import React, { useCallback } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AppConfig from '../../../config/appConfig'
import { Button } from 'antd-mobile'
import classes from './AppliedFilterList.module.css'
import moment from 'moment'

const AppliedFilterList = ({ filters, variables, customs, setFilteringObject }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleDelete = useCallback(
    filter => {
      let func = 'setValues'
      if (filter.isVariableFilter) {
        func = 'setVariableFilterValues'
      } else if (filter.isCustomFilter) {
        func = 'setCustomFilterValues'
      }
      dispatch({
        type: 'FILTER_SERVICE_CALL',
        func: func,
        params: { id: filter.id, clone: 1, values: [] }
      })
      dispatch({
        type: 'FILTER_SERVICE_CALL',
        func: 'setSlaveState',
        params: { newSlaveState:'' }
      })
      setFilteringObject({})
    },
    [dispatch]
  )

  let appliedFilters = []
  if (filters && _.isArray(filters) && filters.length > 0) {
    filters.forEach(filter => {
      if (filter.queryValues.length > 0 && filter.affectedByFilters === undefined) {
        filter.queryValues = []
        filter.defaultValues = []
        filter.values = []
        filter._defaultValues = []
        filter.query = ''
        filter.nodeQuery = ''
      }
    })
    _.each(filters, filter => {
      if (
        filter.queryValues.length > 0 &&
        !filter.isHidden &&
        !_.get(filter, 'parentFilter')
        ) {
          let queryValues = filter.queryValues
        if (
          AppConfig['DATE_AGGREGATE_TYPES']['ALL_INTERVAL_KEYS'].includes(
            queryValues[0]
          )
        ) {
          queryValues = [t(queryValues[0])]
        }
        if (AppConfig.DATE_TYPES.includes(_.get(filter, 'dataType'))) {
          try {
            let valuesToBeShown = [];
            _.each(queryValues, queryValue => {
              let relativeDateObject = JSON.parse(queryValue);
              let anchor = moment();
              let format = 'YYYY-MM-DD';

              if (['hour', 'minute'].includes(relativeDateObject.unit)) {
                format = 'YYYY-MM-DD HH:mm:ss';
              }
              if (relativeDateObject.anchor === 'last_data') {
                anchor = moment(_.get(filter, 'info.max'));
              }
              anchor = anchor.add(
                relativeDateObject.move,
                relativeDateObject.unit,
              );
              if (relativeDateObject.behavior === 'snap_beginning') {
                anchor = anchor.startOf(
                  relativeDateObject.unit === 'week'
                    ? 'isoWeek'
                    : relativeDateObject.unit,
                );
              } else if (relativeDateObject.behavior === 'snap_ending') {
                anchor = anchor.endOf(
                  relativeDateObject.unit === 'week'
                    ? 'isoWeek'
                    : relativeDateObject.unit,
                );
              }
              valuesToBeShown.push(anchor.format(format));
            });
            queryValues = valuesToBeShown;
          } catch (e) {
            // console.log(e)
          }
        }
        appliedFilters.push({
          id: filter.id || filter._id,
          alias: filter.custom_alias || filter.alias,
          values: queryValues
        })
      }
    })
  }

  if (variables && _.isArray(variables) && variables.length > 0) {
    _.each(variables, filter => {
      if (
        filter.queryValues &&
        filter.queryValues.length > 0 &&
        !filter.isHidden
      ) {
        let queryValues = filter.queryValues
        if (
          AppConfig['DATE_AGGREGATE_TYPES']['ALL_INTERVAL_KEYS'].includes(
            queryValues[0]
          )
        ) {
          queryValues = [t(queryValues[0])]
        }
        appliedFilters.push({
          id: filter._id_,
          alias: filter.custom_alias || filter.alias,
          values: queryValues,
          isVariableFilter: true
        })
      }
    })
  }

  if (customs && _.isArray(customs) && customs.length > 0) {
    _.each(customs, filter => {
      if (
        filter.queryValues &&
        filter.queryValues.length > 0 &&
        !filter.isHidden
      ) {
        let queryValues = filter.queryValues
        if (
          AppConfig['DATE_AGGREGATE_TYPES']['ALL_INTERVAL_KEYS'].includes(
            queryValues[0]
          )
        ) {
          queryValues = [t(queryValues[0])]
        }
        appliedFilters.push({
          id: filter._id_,
          alias: filter.custom_alias || filter.alias,
          values: queryValues,
          isCustomFilter: true
        })
      }
    })
  }

  if (appliedFilters.length) {
    return (
      <div className={classes.container}>
        {appliedFilters.map((item, index) => (
          <Button
            key={index}
            type='ghost'
            inline
            size='small'
            className={classes.button}
          >
            <i
              className={`fa fa-times-circle fa-lg ${classes.icon}`}
              aria-hidden='true'
              onClick={() => handleDelete(item)}
            ></i>
            <span className={classes.text}>
              {item.alias} : {item.values.join(' | ')}
            </span>
          </Button>
        ))}
      </div>
    )
  }
  return null
}

export default React.memo(AppliedFilterList)
