import React, { useState } from 'react'
import { NavBar, List } from 'antd-mobile'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MenuSidebar from '../../components/menu-sidebar/MenuSidebar'
import { useHistory } from 'react-router-dom'
import ReactCountryFlag from 'react-country-flag'
import _ from 'lodash'
import i18next from 'i18next'

const languageOptions = {
  tr: {
    flag: 'TR',
    text: 'Türkçe'
  },
  en: {
    flag: 'US',
    text: 'English'
  }
}

const ProfilePage = () => {
  const { t } = useTranslation()
  const language =
    useSelector(state => _.get(state, 'settings.language')) || i18next.language
  const history = useHistory()

  const [showSidebar, setShowSidebar] = useState(false)

  const handleNavigate = (event, route) => {
    history.push(`profile/${route}`)
  }

  const menuItems = [
    {
      route: 'personal-info',
      text: 'personalInformation'
    },
    {
      route: 'change-password',
      text: 'changePassword'
    },
    {
      route: 'reset-application',
      text: 'clearCacheMenu'
    },
    {
      route: 'session-control',
      text: 'sessionControl'
    },
    {
      route: 'notification-settings',
      text: 'notificationSettings'
    }
  ]

  return (
    <div>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-bars'
            className='fa fa-bars fa-lg'
            onClick={() => setShowSidebar(!showSidebar)}
          />
        ]}
      >
        <span>{t('profile')}</span>
      </NavBar>
      <MenuSidebar
        showSidebar={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
      />
      <List className='my-list'>
        {menuItems.map(({ route, text }) => (
          <List.Item
            key={text}
            arrow='horizontal'
            onClick={event => handleNavigate(event, route)}
          >
            {t(text)}
          </List.Item>
        ))}
        <List.Item
          key='language'
          arrow='horizontal'
          onClick={event => handleNavigate(event, 'language-setting')}
        >
          <span style={{ marginRight: '10px' }}>
            {languageOptions[language].text}
          </span>{' '}
          <ReactCountryFlag countryCode={languageOptions[language].flag} svg />
        </List.Item>
        <List.Item extra='2020.11'>{t('version')}</List.Item>
      </List>
    </div>
  )
}

export default React.memo(ProfilePage)
