import { client, dataStore } from '../configureStore'
import _ from 'lodash'
import AppConfig from '../../config/appConfig'

export function fetchShowcases (pageSize = 10) {
  return async (dispatch, getStore) => {
    client.defaults.baseURL = AppConfig.apiUrl
    dataStore.reset()

    const {
      currentPage: page = 1,
      searchValue = ''
    } = getStore().listofshowcases.searchParameters

    try {
      const response = await client.get(
        `/listofshowcases/?ordering=-updated_at&page=${page}&page_size=${pageSize}&search=${searchValue}`
      )
      const newShowcases = _.get(response, 'data')
      const currentPage = newShowcases?.meta?.pagination?.page
      const totalPages = newShowcases?.meta?.pagination?.pages

      if (currentPage === totalPages) {
        dispatch({ type: 'SET_HAS_MORE_PAGE_TO_FALSE' })
      }

      let listofshowcases = getStore().listofshowcases.entries?.data || []
      listofshowcases = {
        links: newShowcases.links,
        meta: newShowcases.meta,
        data: [...listofshowcases, ...newShowcases.data]
      }

      dispatch({
        type: 'CREATE_SHOWCASES',
        listofshowcases
      })

      dataStore.syncWithMeta(listofshowcases)
      let showcaseList = dataStore.findAll('listofshowcases')

      dispatch({
        type: 'SET_ALL_SHOWCASES',
        showcaseList
      })
    } catch (error) {
      throw new Error(error)
    }
  }
}
