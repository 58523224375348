import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { store } from './store/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import 'font-awesome/css/font-awesome.min.css'
import './i18n'
import { persistor } from './store/configureStore'
import ErrorBoundary from './components/error/ErrorBoundary'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback=''>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </HashRouter>
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
