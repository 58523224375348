import {_} from 'lodash'

export function formatDateFilter (dimension, value) {
  if (dimension.dateAggregateMethod !== 'trunc') {
    return ''
  }
  let values
  let filterQuery = ''
  // datetime
  if (dimension.dateAggregateDataType === 'datetime') {
    values = [regrowDate(value, false, true), regrowDate(value, true, true)]
  } else if (dimension.dateAggregateDataType === 'date') {
    values = [regrowDate(value, false, false), regrowDate(value, true, false)]
  }
  if (values) {
    filterQuery = _.map(values, (val) => {
      return dimension.dimension + '=' + val
    }).join('&')
  }
  return filterQuery
}

function regrowDate (date, toMax, isDatetime) {
  // First calculate last day of the month.
  let lastDay = '-31'
  if (typeof date !== 'undefined') {
    if (typeof date === 'number') {
      date = String(date)
    }
    if (date.length === 7) {
      let year = date.split('-')[0]
      let month = date.split('-').length > 1 ? date.split('-')[1] : 12
      if (month === '02') {
        lastDay = ((year % 400 === 0) || (year % 100 !== 0 && year % 4 === 0)) ? '-29' : '-28'
      } else {
        lastDay = ((month <= 7 && month % 2 === 1) || (month >= 8 && month % 2 === 0)) ? '-31' : '-30'
      }
    }

    let appendices = []
    let defaultAppendices = toMax ? ['-12', lastDay, ' 23', ':59', ':59'] : ['-01', '-01', ' 00', ':00', ':00']
    let numAppendicesToAdd = [date.length < 19, date.length < 16, date.length < 13, date.length < 10, date.length < 7]
    numAppendicesToAdd = numAppendicesToAdd.reduce(function (sum, val) {
      return sum + val
    }, 0)
    for (let i = 0; i < numAppendicesToAdd; i++) {
      appendices.unshift(defaultAppendices.pop())
    }
    date = date + appendices.join('')

    if (!isDatetime) {
      date = date.split(' ')[0]
    }
  }

  return date
}
