import React from 'react'
import { TabBar } from 'antd-mobile'
import { useTranslation } from 'react-i18next'

const BottomTab = ({ tabButton, setTabButton }) => {
  const { t } = useTranslation()

  return (
    <TabBar
      unselectedTintColor='#999'
      tintColor='#000'
      barTintColor='#F6F7F2'
      hidden={false}
    >
      <TabBar.Item
        icon={
          <i
            className='fa fa-ellipsis-h fa-lg'
            onClick={() => setTabButton('all')}
          ></i>
        }
        selectedIcon={
          <i
            className='fa fa-ellipsis-h fa-lg'
            onClick={() => setTabButton('all')}
          ></i>
        }
        title={t('all')}
        key='all'
        selected={tabButton === 'all'}
        onPress={() => setTabButton('all')}
      ></TabBar.Item>
      <TabBar.Item
        icon={
          <i
            className='fa fa-bookmark-o fa-lg'
            onClick={() => setTabButton('favorites')}
          />
        }
        selectedIcon={
          <i
            className='fa fa-bookmark-o fa-lg'
            onClick={() => setTabButton('favorites')}
          />
        }
        title={t('favorites')}
        key='favorites'
        selected={tabButton === 'favorites'}
        onPress={() => setTabButton('favorites')}
      ></TabBar.Item>
      <TabBar.Item
        icon={
          <i
            className='fa fa-clock-o fa-lg'
            onClick={() => setTabButton('recent')}
          ></i>
        }
        selectedIcon={
          <i
            className='fa fa-clock-o fa-lg'
            onClick={() => setTabButton('recent')}
          ></i>
        }
        title={t('recentlyOpened')}
        key='recentlyOpened'
        selected={tabButton === 'recent'}
        onPress={() => setTabButton('recent')}
      ></TabBar.Item>
    </TabBar>
  )
}

export default BottomTab
