import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  InputItem,
  NavBar,
  Button,
  List,
  Flex,
  WhiteSpace,
  WingBlank
} from 'antd-mobile'
import { client } from '../../../store/configureStore'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { showAlert } from '../../../helpers/utils'
import classes from './ChangePasswordPage.module.css'
import { logoutUser } from '../../../store/actions/auth'

const ChangePasswordPage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')

  const user = useSelector(state => state.auth)

  const changePassword = async () => {
    setLoading(true)
    const { userId, email, language, isIntroClosed, username } = user
    const data = {
      id: userId,
      type: 'users',
      attributes: {
        password,
        new_password: newPassword1,
        email_address: email,
        is_intro_closed: isIntroClosed,
        language,
        username
      }
    }
    const headers = {
      'Content-Type': 'application/vnd.api+json',
    }
    try {
      const response = await client.patch(
        `/users/${userId}/`,
        { data },
        { headers }
      )
      if (_.get(response, 'status') === 200) {
        dispatch(logoutUser())
        history.push('/login')
      }
    } catch (error) {
      if (_.get(error, 'response.status') === 403) {
        showAlert(t('error'), t('oldPasswordIsWrong'), t('close'))
      } else {
        showAlert(t('error'), t('changePasswordError'), t('close'))
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangePassword = () => {
    if (!password || !newPassword1 || !newPassword2) {
      showAlert(t('error'), t('invalidInput'), t('close'))
      return
    }

    if (newPassword1.length < 8 || newPassword2.length < 8) {
      showAlert(t('error'), t('passwordNotLongError'), t('close'))
      return
    }

    if (newPassword1 !== newPassword2) {
      showAlert(t('error'), t('passwordsDoNotMatch'), t('close'))
      return
    }

    changePassword()
  }

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => history.push('/profile')}
          />
        ]}
      >
        {t('changePassword')}
      </NavBar>
      <WingBlank>
        <List>
          <Flex>
            <span className={classes.label}>{t('password')}</span>
            <InputItem
              type='password'
              placeholder={t('required')}
              onChange={value => setPassword(value)}
              value={password}
            />
          </Flex>
          <Flex>
            <span className={classes.label}>{t('newPassword')}</span>
            <InputItem
              type='password'
              placeholder={t('required')}
              onChange={value => setNewPassword1(value)}
              value={newPassword1}
            />
          </Flex>
          <Flex>
            <span className={classes.label}>{t('newPassword')}</span>
            <InputItem
              type='password'
              placeholder={t('required')}
              onChange={value => setNewPassword2(value)}
              value={newPassword2}
            />
          </Flex>
        </List>
        <WhiteSpace size='lg' />
        <Button
          loading={loading}
          size='small'
          type='primary'
          onClick={handleChangePassword}
        >
          {t('change')}
        </Button>
      </WingBlank>
    </>
  )
}

export default ChangePasswordPage
