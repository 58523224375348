import _ from 'lodash'
import moment from 'moment'
import AppConfig from '../../config/appConfig'
import DS from "../../models/model-data";
import {dictToUrl} from "../../helpers/utils";

export const FilterService = {
  attributes: [
    'alias', 'field_name', 'is_reversed', 'is_hidden', 'input_type', 'customAlias',
    'html_type', 'info', 'data_type', 'view_id', 'node', 'default_values', 'isDateLookupFilter', 'true_bool_label', 'false_bool_label',
    'like_option', '_children_ids', 'parent_filter', 'affected_by_filters', 'affected_from', 'is_excluded'
  ],
  getInitialState: function () {
    return {
      filters: [],
      lastAppliedFilters: [],
      variables: [],
      customs: [],
      slaveState: ''
    }
  },
  setSlaveState: function (state, params) {
    let newSlaveState = params.newSlaveState
    return {...state, slaveState: newSlaveState}
  },
  getFilter: function (state, id, clone) {
    if (!clone) {
      clone = 1
    }
    let filters = _.get(state, 'filters')
    let cloneFilters = _.filter(filters, function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })
    let filter = _.find(cloneFilters, {'id': id})
    if (!filter && !isNaN(id)) {
      filter = cloneFilters.find(filter => _.get(filter, 'node.id') === id)
    }
    return filter
  },
  getVariableFilter (state, variableId, clone) {
    if (!clone) {
      clone = 1
    }
    let variables =  _.get(state, 'variables')
    let cloneFilters = _.filter(variables, function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })
    return _.find(cloneFilters, {'_id_': variableId})
  },
  getCustomFilter (state, customId, clone) {
    if (!clone) {
      clone = 1
    }
    let customs =  _.get(state, 'customs')
    let cloneFilters = _.filter(customs, function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })
    return _.find(cloneFilters, {'_id_': _.toString(customId)})
  },
  createFilters: function (state, params) {
    let filters = params.filters
    let variables = params.variables
    let customs = params.customs
    let clone = params.clone
    let newState = Object.assign({}, {...state}, {filters: state.filters, variables: state.variables, customs:state.customs})
    _.each(filters, function (filter) {
      let stateFilter = this._createFilter(newState, filter, clone)
      if (stateFilter) {
        newState.filters.push(stateFilter)
      }
    }.bind(this))
    _.each(variables, function (filter) {
      let stateFilter = this._createFilter(newState, filter, clone, false, true)
      if (stateFilter) {
        newState.variables.push(stateFilter)
      }
    }.bind(this))
    _.each(customs, function (filter) {
      let stateFilter = this._createFilter(newState, filter, clone, true, false)
      if (stateFilter) {
        newState.customs.push(stateFilter)
      }
    }.bind(this))
    return newState
  },
  _createFilter: function (state, filter, clone=1, custom=false, isVariableFilter=false) {
    if (custom) {
      let _custom = {}
      _custom['set'] =function (path, value) {
        _.set(_custom, path, value)
      }
      _custom.set('alias', _.get(filter, 'custom_alias'))
      _custom.set('custom_alias', _.get(filter, 'custom_alias'))
      _custom.set('id', _.get(filter, 'id'))
      _custom.set('_id_', _.toString(_.get(filter, '_id_')))
      _custom.set('__model', filter)
      _custom.set('clone', clone)
      _custom.set('viewId', parseInt(_.get(filter, 'viewId')))
      _custom.set('query', '')
      _custom.set('nodeQuery', '')
      _custom.set('type', 'custom')
      _custom.set('isCustomFilter', true)
      _custom.set('isExpressionFilter', true)
      _custom.set('values', [])
      _custom.set('selectedValueAliases', [])
      _custom.set('defaultValues', [])
      _custom.set('notApplied', {})
      _custom.set('queryValues', [])
      let values = _.map(_.get(filter, 'expression.queries'),function(query) {
        return _.get(query, 'alias')
      })
      _.set(_custom, 'info', {
        'values': values,
      })
      delete _custom['set']
      delete _custom['get']
      return _custom
    }
    else if (isVariableFilter) {
      if (!this.getVariableFilter(state, _.get(filter, '_id'), clone)) {
        let _filter = {}
        _filter['set'] = function (path, value) {
          _.set(_filter, path, value)
        }
        _filter.set('clone', parseInt(clone))
        _filter.set('__model', filter)
        _filter.set('id', (_.get(filter, 'id')))
        _filter.set('_id_', (_.get(filter, '_id')))
        this.attributes.forEach(function (attribute) {
          _filter[_.camelCase(attribute)] = _.get(filter, attribute)
        })
        let defaultValues = _.get(filter, 'default_values')
        _filter.set('type', 'variable')
        _filter.set('query', '')
        _filter.set('viewId', parseInt(_.get(filter, 'viewId')))
        _filter.set('values', defaultValues)
        _filter.set('_defaultValues', defaultValues)
        _filter.set('queryValues', defaultValues)
        _filter.set('order', _.get(filter, 'order'))
        _filter.set('alias', _.get(filter, 'alias'))
        _filter.set('isVariableFilter', true)
        _filter.set('query', this._createEncodedUrl(_filter, defaultValues, 'id', true))
        _filter.set('nodeQuery', this._createEncodedUrl(_filter, defaultValues, '_id_', true))
        _filter.set('inputType', _.get(filter, 'html_type'))

        let values = []
        let min = _.get(filter, 'variable.min')
        let max = _.get(filter,'variable.max')
        if (_.get(filter, 'variable.data_type') === 'date' || _.get(filter, 'variable.data_type') === 'datetime') {
          min = _.get(filter, 'variable.min_date')
          max = _.get(filter, 'variable.max_date')
        }
        if (_.get(filter, 'variable.data_type') === 'string' && _.get(filter, 'variable.value_type') === 'multiple') {
          values = _.get(filter, 'variable.choices')
        }
        _.set(_filter, 'info', {
          'min': min,
          'max': max,
          'values': values,
          'step': _.get(filter, 'variable.step')
        })




        delete _filter['set']
        delete _filter['get']
        return _filter
      }
    } else {
      if (!this.getFilter(state, _.get(filter, '_id'), clone)) {
        let _filter = {
          clone: parseInt(clone),
          id: _.get(filter, '_id'),
          _id_: _.get(filter, '_id')
        }
        _.set(filter, 'node.filter', null)
        this.attributes.forEach(function (attribute) {
          _filter[_.camelCase(attribute)] = _.get(filter, attribute)
        })
        let node = _.get(filter, 'node')
        if(node) {
          let node_serializer = DS.belongsTo('node')
          node = node_serializer(node)
          _.set(_filter, 'node', node)
        }


        let defaultValues = _.get(filter, 'default_values')

        if (AppConfig['INTEGER_TYPES'].includes(_.get(filter, 'data_type'))) {
          defaultValues = defaultValues.map(function (defaultValue) {
            return ['min', 'max'].includes(defaultValue) ? defaultValue : Number(defaultValue)
          })
        }

        if (defaultValues && !defaultValues.includes('__null__', '__notnull__') && !_.get(filter, 'isDateLookupFilter') && AppConfig['DATE_TYPES'].includes(_.get(filter, 'data_type'))) {
          if (AppConfig['DATE_AGGREGATE_TYPES']['ALL_INTERVAL_KEYS'].includes(_.get(filter,'custom_date_range'))) {
            defaultValues = [_.get(filter,'custom_date_range'),_.get(filter,'custom_date_range')]
          }
        }

        if (_.get(filter, 'isDateLookupFilter')) {
          _.set(filter, 'filter_id', _.get(filter, '_filter_id'))
          _.set(filter, 'lookup', _.get(filter, 'lookup'))
        }
        _.set(_filter, 'parentFilter', _.get(filter, 'parent_filter'))
        _.set(_filter, 'values', defaultValues)
        _.set(_filter, '_defaultValues', defaultValues)
        _.set(_filter, 'queryValues', defaultValues)
        _.set(_filter, 'query', this._createEncodedUrl(_filter, defaultValues))
        _.set(_filter, 'nodeQuery', this._createEncodedUrl(_filter, defaultValues, 'node.id'))
        return _filter
      } else {
        return null
      }
    }
  },
  _dictToUrl: function (a) {
    return dictToUrl(a)
  },
  _createEncodedUrl: function (filter, values, arg = 'id', isVariableFilter = false) {
    if (filter && typeof values !== 'undefined') {
      let param = {}
      let filterQueryName = _.has(filter, arg) ? _.get(filter, arg) : _.get(filter, 'id')
      let filterQueryNameReversed = [filterQueryName, 'reversed'].join('__')
      if (_.get(filter, 'isDateLookupFilter')) {
        filterQueryName = [_.get(filter, 'filter_id'), _.get(filter, 'lookup')].join('__')
        filterQueryNameReversed = [_.get(filter, 'filter_id'), _.get(filter, 'lookup'), 'reversed'].join('__')
      }
      if (values && values.length > 0) {
        param[filterQueryName] = values
      }
      if (_.get(filter, 'isReversed') === true) {
        param[filterQueryNameReversed] = _.get(filter, 'isReversed')
      }
      if (isVariableFilter) {
        if (values && values.length > 0) {
          return decodeURI(this._dictToUrl({'variable': decodeURI(this._dictToUrl(param, true))}, true))
        } else {
          return ''
        }
      }

      return decodeURI(this._dictToUrl(param))
    }
  },
  canRefresh (state, params) {
    let view = params.view
    let clone = params.clone ? params.clone : 1
    let exclude = params.exclude ? params.exclude : []

    let lastAppliedViewFilters = _.filter(_.get(state, 'lastAppliedFilters'), function (filter) {
      return _.get(filter, 'viewId') === parseInt(view)
    })
    let appliedFilters = _.filter(lastAppliedViewFilters, function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })

    if (_.get(exclude, 'length')) {
      appliedFilters = _.filter(appliedFilters, function (filter) {
        return !_.map(exclude, 'node.id').includes(_.get(filter, 'node.id'))
      })
    }
    return !!appliedFilters.length
  },
  getRanges: function (maxDate) {
    let ranges = {
      'current_year': [moment().startOf('year'), moment().endOf('month')],
      'current_month': [moment().startOf('month'), moment().endOf('month')],
      'current_week': [moment().startOf('week'), moment().endOf('week')],
      'current_day': [moment().startOf('day'), moment()]
    }

    _.each(AppConfig.DATE_AGGREGATE_TYPES.NEXT_INTERVALS, interval => {
      let maxDateValue = this.get('moment').moment()
      let splitIntervalValue = interval.value.split('_')
      let subValue = splitIntervalValue[0]
      if (!['all', 'filtered'].includes(subValue)) {
        let subType = splitIntervalValue[1]
        let title = interval.title
        let startOfRange = maxDateValue.clone().startOf('day').add(1, 'days')
        if (subType === 'months' || subType === 'years') {
          maxDateValue = startOfRange
        }
        let endOfRange = maxDateValue.clone().startOf('day').add(subValue, subType)
        ranges[title] = [startOfRange, endOfRange]
      }
    })


    _.each(AppConfig.DATE_AGGREGATE_TYPES.INTERVALS, function (interval) {
      let maxDateCloned = maxDate.clone()
      let splitIntervalValue = interval.value.split('_')
      let subValue = splitIntervalValue[0]
      if (!['all', 'filtered'].includes(subValue)) {
        let subType = splitIntervalValue[1]
        let online = splitIntervalValue[2] === 'online'
        if (subType === 'years' && !online) {
          maxDateCloned = maxDateCloned.subtract(1, 'month').endOf('month')
        } else if (subType === 'years' && online) {
          maxDateCloned = maxDateCloned.subtract(1, 'day').endOf('day')
        } else if (subType === 'months' && !online && subValue === '1') {
          maxDateCloned = maxDateCloned.subtract(1, 'months').endOf('month')
        } else if (subType === 'months' && !online) {
          maxDateCloned = maxDateCloned.startOf('day')
        } else if (subType === 'months' && online && subValue === '1') {
          maxDateCloned = moment().endOf('day').subtract(1, 'day')
        } else if (subType === 'months' && online) {
          maxDateCloned = moment()
        }
        let title = interval.title
        let startOfRange
        let endOfRange = maxDateCloned.clone()

        if (subType === 'months' && subValue === '1' && !online) {
          startOfRange = maxDateCloned.clone().startOf('month')
        } else {
          startOfRange = maxDateCloned.clone().subtract(subValue, subType)
        }
        ranges[title] = [startOfRange, endOfRange]
      }
    })

    return ranges
  },
  regrowDate (date, toMax, isDatetime) {
    // First calculate last day of the month.
    let lastDay = '-31'
    if (typeof date !== 'undefined') {
      if (typeof date === 'number') {
        date = String(date)
      }
      if (date.length === 7) {
        let year = date.split('-')[0]
        let month = date.split('-').length > 1 ? date.split('-')[1] : 12
        if (month === '02') {
          lastDay = ((year % 400 === 0) || (year % 100 !== 0 && year % 4 === 0)) ? '-29' : '-28'
        } else {
          lastDay = ((month <= 7 && month % 2 === 1) || (month >= 8 && month % 2 === 0)) ? '-31' : '-30'
        }
      }

      let appendices = []
      let defaultAppendices = toMax ? ['-12', lastDay, ' 23', ':59', ':59'] : ['-01', '-01', ' 00', ':00', ':00']
      let numAppendicesToAdd = [date.length < 19, date.length < 16, date.length < 13, date.length < 10, date.length < 7]
      numAppendicesToAdd = numAppendicesToAdd.reduce(function (sum, val) {
        return sum + val
      }, 0)
      for (let i = 0; i < numAppendicesToAdd; i++) {
        appendices.unshift(defaultAppendices.pop())
      }
      date = date + appendices.join('')

      if (!isDatetime) {
        date = date.split('T')[0]
      }
    }

    return date
  },
  regrowDateValues (values, isDatetime = false) {
    if (_.get(values, 'length')) {
      // values = values.map(function (value) {
      //     return decodeURIComponent(value);
      // });
      values = values.sort()
      values = [values[0], values[values.length - 1]]
      if (![10, 16].includes(values[0].length) || (isDatetime && values[0].length !== 16)) {
        values[0] = this.regrowDate(values[0], false, isDatetime)
        values[1] = this.regrowDate(values[1], true, isDatetime)
      }
    }
    return values
  },
  setSingleFilterValues (state, params) {
    let id = params.id
    let clone = params.clone
    let values = params.values
    let filter = this.getFilter(state, id, clone)
    let queryValues = values

    return Object.assign({}, state, {
      filters: state.filters.map(function (stateFilter) {
        if (stateFilter === filter) {
          if (!values.includes('__null__', '__notnull__') && !_.get(filter, 'isDateLookupFilter') && AppConfig['DATE_TYPES'].includes(_.get(filter, 'dataType'))) {
            if (AppConfig['DATE_AGGREGATE_TYPES']['ALL_INTERVAL_KEYS'].includes(values[0])) {
              let maxDate = moment(_.get(filter,'info.max'))
              values = this.getRanges(maxDate)[values[0]]
              let format = 'YYYY-MM-DD'
              if (_.get(filter, 'dataType') === 'datetime') {
                format = 'YYYY-MM-DD HH:mm:ss'
              }
              values[0] = values[0].format(format)
              values[1] = values[1].format(format)
            } else {
              values = this.regrowDateValues(values, _.get(filter, 'dataType') === 'datetime')
              queryValues = values
            }
          }
          return Object.assign({}, stateFilter, {
            'defaultValues': values,
            'values': values,
            'queryValues': queryValues,
            'query': this._createEncodedUrl(filter, queryValues),
            'nodeQuery': this._createEncodedUrl(filter, queryValues, 'node.id')
          })
        } else {
          return stateFilter
        }
      }.bind(this))
    })
  },
  setValues (state, params) {
    let id = params.id
    let clone = params.clone
    let values = params.values
    let filter = this.getFilter(state, id, clone)
    if (filter) {
      if (_.get(filter, 'parentFilter')) {
        let parentFilter = this.getFilter(state, _.get(filter, 'parentFilter.id'), clone)
        if (parentFilter) {
          return this.setValues(state, {id: _.get(filter,'parentFilter.id'), clone: clone, values: values})
        }
      } else {
        let modifiedState = this.setSingleFilterValues(state, {id, clone, values})
        if (_.get(filter, 'childrenIds')) {
          _.get(filter, 'childrenIds').forEach(function (childrenId) {
            let childrenFilter = this.getFilter(modifiedState, childrenId, clone)
            if (childrenFilter) {
              modifiedState = this.setSingleFilterValues(modifiedState, {id: childrenId, clone: clone, values: values})
            }
          }.bind(this))
        }
        return modifiedState
      }
    }
  },
  setVariableFilterValues(state, params) {
    let id = params.id
    let clone = params.clone
    let values = params.values
    if (!clone) {
      clone = 1
    }
    let queryValues = values
    let filter = this.getVariableFilter(state, id, clone)
    if(filter) {
      return Object.assign({}, state, {
          variables: state.variables.map(function (stateFilter) {
            if (stateFilter === filter) {
              return Object.assign({}, stateFilter, {
                'defaultValues': values,
                'values': values,
                'queryValues': queryValues,
                'query': this._createEncodedUrl(filter, values, 'id', true),
                'nodeQuery': this._createEncodedUrl(filter, values, 'id', true)
              })
            } else {
              return stateFilter
            }
          }.bind(this))
        }
      )
    }
  },
  setCustomFilterValues(state, params) {
    let id = params.id
    let clone = params.clone
    let values = params.values
    if (!clone) {
      clone = 1
    }
    let queryValues = values
    let filter = this.getCustomFilter(state, id, clone)
    if(filter) {
      return Object.assign({}, state, {
          customs: state.customs.map(function (stateFilter) {
            if (stateFilter === filter) {
              return Object.assign({}, stateFilter, {
                'defaultValues': values,
                'values': values,
                'queryValues': queryValues,
                'query': this.createCustomFilterQuery(filter, values),
                'nodeQuery': this.createCustomFilterQuery(filter, values)
              })
            } else {
              return stateFilter
            }
          }.bind(this))
        }
      )
    }
  },
  createCustomFilterQuery (filter, values) {

    let query = {}
    let id = _.get(filter, '_id_')
    if (_.get(values, 'length')) {

      let queryIds = _.without(_.map(values, (value) => {
        let query =  _.find(_.get(filter,'__model.expression.queries'), {'alias': value})
        if (query) {
          return _.get(query,'id')
        } else {
          return false
        }
      }), false)

      query[id] = queryIds
      return decodeURI(this._dictToUrl({'custom': decodeURI(this._dictToUrl(query, true))}, true))
    } else {
      return null
    }
  },
  getQueryParams (state, clone, arg = 'query') {
    if (!clone) {
      clone = 1
    }
    let queryParams = ''
    let cloneFilters = _.filter(_.get(state, 'filters'), function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })
    let queries = _.map(cloneFilters, function (filter) {
      return _.get(filter, arg)
    }).filter(function (e) { return !!e })

    let variableCloneFilters = _.filter(_.get(state, 'variables'), function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })

    let variableQueries = _.map(variableCloneFilters, function (filter) {
      return _.get(filter, arg)
    }).filter(function (e) { return !!e })

    _.forEach(variableQueries, function(variableQuery) {
      queries.push(variableQuery)
    })

    let customCloneFilters = _.filter(_.get(state, 'customs'), function (filter) {
      return _.get(filter, 'clone') === parseInt(clone)
    })

    let customQueries = _.map(customCloneFilters, function (filter) {
      return _.get(filter, arg)
    }).filter(function (e) { return !!e })

    _.forEach(customQueries, function(customQuery) {
      queries.push(customQuery)
    })


    if (queries.length) {
      queryParams = queries.join('&')
    }
    return encodeURIComponent(queryParams)
  },
  getFilterValues(state) {
    return _.concat(state.filters, state.customs, state.variables)
  },
  __isValue (value) {
    return typeof value !== 'undefined' && value !== ''
  },
  isMustHaveFilterMet (state, filterId, clone) {
    if (!filterId) {
      return true
    }
    let isMustHaveFilterSet = this.isFilterSet(state, filterId.toString(), clone)
    let isAnyFilterSet = this.isAnyFilterSet(state, clone)
    return ((filterId > 0 && isMustHaveFilterSet) || (filterId === -1 && isAnyFilterSet) || filterId === 0)

  },
  isFilterSet (state, id, clone) {
    let filter = this.getFilter(state, id, clone) || {values: []}
    let variableFilter = this.getVariableFilter(state, id, clone) || {values: []}
    let customFilter = this.getCustomFilter(state, id, clone) || {values: []}
    let isAnyValueSet = false
    filter.values.forEach(function (value) {
      isAnyValueSet = isAnyValueSet || this.__isValue(value)
    }.bind(this))
    variableFilter.values.forEach(function (value) {
      isAnyValueSet = isAnyValueSet || this.__isValue(value)
    }.bind(this))
    customFilter.values.forEach(function (value) {
      isAnyValueSet = isAnyValueSet || this.__isValue(value)
    }.bind(this))
    return isAnyValueSet
  },
  isAnyFilterSet (state, clone) {
    let allChangedFilters = this.getFilterValues(state, clone)
    let isAnyFilterSet = false
    allChangedFilters.forEach(function (filter) {
      isAnyFilterSet = isAnyFilterSet || this.isFilterSet(state, filter.id, filter.clone) || this.isFilterSet(state, _.get(filter, '_id_'), filter.clone)
    }.bind(this))
    return isAnyFilterSet
  },

}


export default function currentfilters (state = FilterService.getInitialState(), action) {
  switch (action.type) {
    case 'FILTER_SERVICE_CALL':
      try {
        return FilterService[action.func](state, action.params)
      } catch (e) {
        console.error(e)
        return state
      }
    case 'PURGE_STATE':
    case 'PURGE_FILTERS':
      return FilterService.getInitialState()
    default:
      return state
  }
}
