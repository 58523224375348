import _ from 'lodash'
import i18n from '../i18n'

export default function (DS) {

  let _mergedFunction = function () {
    let merged = []
    _.forEach(_.get(this, 'merged_with'), mergedWith => {
      if (_.get(this, 'id') !== _.get(mergedWith, 'id')) {
        merged.push(_.get(mergedWith, 'id'))
        let reversedMerged = _.get(mergedWith, 'merged_with')
        let reverseList = []
        _.forEach(reversedMerged, reversedMergedMeasure => {
          reverseList.push(_.get(reversedMergedMeasure, 'id'))
        })
        merged.push(...reverseList)
      }
    })
    return _.uniq(merged).sort()
  }

  return {
    id: DS.attr('string'),
    alias: DS.attr('string'),
    custom_alias: DS.attr('string'),
    //dashlet: DS.belongsTo('dashlet'),
    measure: DS.belongsTo('node'),
    criterion: DS.attr('string'),
    func: DS.attr('string'),
    position: DS.attr('string'),
    visibility: DS.attr('boolean'),
    showInLegend: DS.attr('boolean'),
    non_default_chart_type: DS.attr('string'),
    dimension_type: DS.attr('string'),
    data_type: DS.attr('string'),
    order: DS.attr('number'),
    is_measure: DS.attr('boolean'),
    is_count_measure: DS.attr('boolean'),
    inherit_prefix_suffix: DS.attr('boolean'),
    expression: DS.belongsTo('sqlexpression'),
    formatting: DS.attr('string'),
    custom_abbreviation: DS.attr('string'),
    sort_by: DS.attr('string'),
    sparkline_node: DS.attr('number'),
    sparkline_type: DS.attr('string'),
    sparkline_date_aggregate: DS.attr('string'),
    sparkline_interval: DS.attr('string'),
    is_sparkline: DS.attr('boolean'),
    sparkline_filter_base: DS.attr('string'), // Options are last, prev, next
    prefix: DS.attr('string'),
    suffix: DS.attr('string'),
    extremes: DS.attr('string'),
    thresholds: DS.attr('string'),
    conditional_formatting: DS.attr('boolean'),
    conditional_formatting_entries: DS.hasMany('dashletconditionalformatting', {async: false}),
    is_color_measure: DS.attr('boolean'),
    is_size_measure: DS.attr('boolean'),
    convert_to_sequence_number: DS.attr('boolean'),
    filters: DS.hasMany('dashletmeasurefilter', {inverse: 'measure'}),
    difference_by_date_comparison: DS.attr('boolean'),
    difference_by_date_comparison_type: DS.attr('string'),
    difference_by_date_comparison_temporal: DS.belongsTo('node'),
    difference_by_date_comparison_variables: DS.attr(),
    is_percentage_function: DS.attr('boolean'),
    percentage_mode: DS.attr('string', {defaultValue: 'percentage'}),
    percentage_dimension_index: DS.attr('number'),
    entityType: 'measure',
    isExpression: DS.computed(function () {
      return !_.get(this, 'is_measure') && !_.get(this, 'is_count_measure')
    }),
    _prefix: DS.computed(function () {
      if (_.get(this, 'inherit_prefix_suffix')) {
        return _.get(this, 'measure.prefix')
      }
      return _.get(this, 'prefix') || ''
    }),
    _suffix: DS.computed(function () {
      if (_.get(this, 'inherit_prefix_suffix')) {
        return _.get(this, 'measure.suffix')
      }
      return _.get(this, 'suffix') || ''
    }),
    _formatting: DS.computed(function () {
      if (_.get(this, 'formatting'))
        return _.get(this, 'formatting')
      else
        return _.get(this, 'measure.formatting')
    }),
    axisTitle: DS.computed(function () {
      let alias = _.get(this, 'alias')
      let customAlias = _.get(this, 'custom_alias')
      let isMeasure = _.get(this, 'is_measure')
      let percentage = _.get(this, 'is_percentage') ? ' (%)' : ''
      let func = _.get(this, 'func')
      let function_abbreviation = null
      if (customAlias) {
        alias = customAlias
        if (['All Rows', 'Tüm Satırlar'].includes(alias)) {
          return i18n.t('all_rows')
        }
      } else {
        if (isMeasure) {
          function_abbreviation = i18n.t(func)
          alias = `${function_abbreviation}${percentage} (${alias})`
        } else {
          alias = `${alias}`
        }
      }
      return alias

    }),
    _merged: DS.computed(function () {
      return _mergedFunction.bind(this)()
    }),
    _merged_axis_unique_id: DS.computed(function () {
      return _mergedFunction.bind(this)().join('-')
    })

  }
}
