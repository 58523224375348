const initialState = {showBars:true}

export default function auth (state = initialState, action) {
  switch (action.type) {
    case 'SET_BARS_FALSE':
      return { ...state, showBars: false}
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
