export const rowClickedCounter = (num) => {  
  return async (dispatch)  => {
    try { 
      dispatch({
        type: 'ROW_CLICKED',
        Check: num
      })
    }
    catch (error) {
      console.error(error)
    }
  }
}

export const rowClickedFilterQuery = (query) => {  
  return async (dispatch)  => {
    try { 
      dispatch({
        type: 'FILTER_QUERY',
        filterQuery: query
      })
    }
    catch (error) {
      console.error(error)
    }
  }
}

export const rowClickedFilterCheckboxQuery = (query) => {  
  return async (dispatch)  => {
    try { 
      dispatch({
        type: 'FILTER_CHECKBOX_QUERY',
        filterCheckbox: query
      })
    }
    catch (error) {
      console.error(error)
    }
  }
}

export const rowClickedFilterArray = (newItem) => {  
  return async (dispatch)  => {
    try { 
      dispatch({
        type: 'FILTER_ARRAY_ADD',
        newItem: newItem
      })
    }
    catch (error) {
      console.error(error)
    }
  }
}
