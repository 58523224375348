import React, { useCallback } from 'react'
import { InputItem, List } from 'antd-mobile'

const TextboxWidget = ({ setSelectedValues, selectedValues }) => {
  const setValue = useCallback(
    item => {
      setSelectedValues([item])
    },
    [setSelectedValues]
  )

  return (
    <List>
      <InputItem
        type='text'
        onChange={value => setValue(value)}
        value={selectedValues[0]}
      />
    </List>
  )
}

export default React.memo(TextboxWidget)
