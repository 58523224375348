import numeral from 'numeral'
import AppConfig from '../config/appConfig'
import _ from 'lodash'
import moment from 'moment'

function formatter (customAbbreviation, formatting, data) {
  data = parseFloat(data)
  let value
  if (data < 0.000001 && data >= 0 && formatting !== 'noformatting') {
    // 1. For small values return zero fast.
    // 2. For small e-values (like 4.234e-12) numeral returns NaN.
    return 0
  }
  switch (formatting) {
    case null:
    case undefined:
    case 'abbreviation':
      value = numeral(data).format(AppConfig.numeralConfig.abbrevations)
      break
    case 'dotcomma':
      value = numeral(data).format(AppConfig.numeralConfig.dotComma)
      break
    case 'noformatting':
      value = data
      break
    case 'custom':
      value = numeral(data).format(customAbbreviation)
      break
    default:
      value = data
  }
  return value
}

export function hexToRgb (hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

export function ellipsis(value, cut) {
  if (value && cut < value.length) {
    let string = value.substr(0, cut).trim()
    return `${string}...`
  } else {
    return value
  }
}

const nonFormatted = ['minute', 'hour', 'week']
const extractParts = {
  'quarter': {
    'part': 'Q',
    'format': 'Qo',
  },
  'month': {
    'part': 'M',
    'format': 'MMMM',
  },
  'day': {
    'part': 'D',
    'format': 'dddd',
  },
}

export function dateFormatting (column, value) {
  value = value.toString()
  let isExtract = false
  let aggragate = null
  let formatting = _.get(column, 'formatting')
  let dateAggregate = _.get(column, 'date_aggregate')
  if (dateAggregate) {
    isExtract = _.head(dateAggregate.split('_')) === 'extract'
    aggragate = _.last(dateAggregate.split('_'))
  }
  if (value) {
    if (isExtract) {
      let customFormat = null
      if (nonFormatted.includes(aggragate) === false) {
        let extractPart = _.get(extractParts, aggragate)
        customFormat = _.get(extractPart, 'format')
        let part = _.get(extractPart, 'part')
        value = moment(value, part).format(customFormat)
      }
    } else {
      let dataType = _.get(column, 'data_type')
      if (
        formatting === 'no_formatting' ||
        formatting === '' ||
        formatting === null
      ) {
        let format = 'YYYY-MM-DD HH:mm:ss'
        if (dataType === 'date') {
          format = 'YYYY-MM-DD'
        }
        if (dataType === 'time') {
          format = 'HH:mm:ss'
        }
        value = moment(value).format(format)
      }
      if (formatting === 'from_now') {
        value = moment(value).fromNow()
      }
      if (formatting === 'only_date') {
        value = moment(value).format('L')
      }
      if (formatting === 'only_time') {
        value = moment(value).format('LTS')
      }
      if (formatting === 'date_time') {
        value = moment(value).format('LLL')
      }
      if (formatting === 'custom') {
        value = moment(value).format(_.get(column, 'custom_abbreviation'))
      }
    }
  }
  
  return value
}

export default formatter
