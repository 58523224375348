const initialState = {
  themesList: [],
  selectedThemeId: 'default'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_THEMES':
      return {
        ...state,
        themesList: action.themes
      }
    case 'SET_THEME':
      return {
        ...state,
        selectedThemeId: action.selectedThemeId
      }
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
