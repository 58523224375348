import Dashlet from './dashlet'
import DashletDimension from './dashletdimension'
import DashletDrilldownDimension from './dashletdrilldowndimension'
import DashletMeasure from './dashletmeasure'
import DashletDatacell from './dashletdatacell'
import DashletReferenceLine from './dashletreferenceline'
import DashletHintMeasure from './dashlethintmeasure'
import Node from './node'
import View from './view'

export default function(DS, type) {
  return {
    'dashlet': Dashlet(DS),
    'dashletmeasure': DashletMeasure(DS),
    'dashlethintmeasure': DashletHintMeasure(DS),
    'dashletdimension': DashletDimension(DS),
    'dashletdrilldowndimension': DashletDrilldownDimension(DS),
    'dashletdatacell': DashletDatacell(DS),
    'dashletreferencelines': DashletReferenceLine(DS),
    'node': Node(DS),
    'view': View(DS)
  }[type]
}