export function fetchView (viewID) {
  return {
    type: 'FETCH_VIEW',
    payload: {
      request: {
        url: `/views/${viewID}/`
      }
    }
  }
}

