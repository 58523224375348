import React from 'react'
import classes from './Cell.module.css'
import { Button } from 'antd-mobile'

const verticalAlignmentLookup = {
  top: 'flex-start',
  middle: 'center',
  bottom: 'flex-end'
}

const createMarkup = (value) => {
  return {__html: value}
}

const Cell = ({
  displayValue,
  textColor,
  cellBGColor,
  cellFontWeight,
  cellValue,
  handler,
  cellWidth,
  minCellWidth,
  onCellClick,
  textProps,
  horizontalAlignment,
  verticalAlignment
}) => {
  return (
    <div
      onClick={onCellClick}
      className={classes.cell}
      style={{
        width: cellWidth,
        minWidth: minCellWidth,
        backgroundColor: cellBGColor || '#fff',
        borderRight: '1px solid rgba(34, 36, 38, 0.1)'
      }}
    >
      {cellValue.collapsible && (
        <div className={classes.buttonContainer}>
          <Button size='small' onClick={() => handler(displayValue, cellValue)}>
            <i
              className={cellValue.isCollapsed ? 'fa fa-minus' : 'fa fa-plus'}
            />
          </Button>
        </div>
      )}
      <div
        style={{
          justifyContent: verticalAlignmentLookup[verticalAlignment]
        }}
      >
        <span
          style={{
            color: textColor || '#333',
            fontWeight: cellFontWeight || 'normal',
            textAlign:
              horizontalAlignment === 'default'
                ? 'center'
                : horizontalAlignment,
            ...textProps
          }}
          dangerouslySetInnerHTML={createMarkup(displayValue)}
        >
        </span>
      </div>
    </div>
  )
}

export default React.memo(Cell)
