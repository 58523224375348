import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import AppConfig from '../../config/appConfig'
import { Picker, List, DatePickerView } from 'antd-mobile'
import enUs from 'antd-mobile/lib/date-picker-view/locale/en_US'
import _ from 'lodash'

const DatePickerWidget = ({
  selectedValues,
  setSelectedValues,
  min,
  max,
  dataType,
  ranges
}) => {
  const { t } = useTranslation()
  const minimumDate = selectedValues.length > 1 ? selectedValues[0] : min
  const maximumDate = selectedValues.length > 1 ? selectedValues[1] : max
  const isCustom = AppConfig['DATE_AGGREGATE_TYPES'][
    'ALL_INTERVAL_KEYS'
  ].includes(minimumDate)

  const custom = isCustom ? minimumDate : 'custom'

  const selectCustom = value => {
    if (value === 'custom') {
      const minDate = min
      const maxDate = max
      setSelectedValues([minDate, maxDate])
    } else {
      setSelectedValues([value, value])
    }
  }

  const setValue = (value, minmax) => {
    if (!value || value === '') {
      value = minmax
    }
    if (minmax === 'min') {
      setSelectedValues([value, maximumDate])
    } else if (minmax === 'max') {
      setSelectedValues([minimumDate, value])
    }
  }

  const dateIntervalKeys = [{ label: t('custom'), value: 'custom' }]
  _.forEach(Object.keys(ranges), value => {
    dateIntervalKeys.push({ label: t(value), value })
  })

  const isDateTime = dataType === 'datetime'

  const datePickerFieldTitles = !isDateTime
    ? <div style ={{ marginLeft: 13 }}>
        <div style={{
          display: 'table',
          tableLayout: 'fixed',
          width: '100%'
        }}>
          <div style={{ display: 'table-cell' }}> <h4 style = {{ textAlign: 'center' }}>{t('year')}</h4></div>
          <div style={{ display: 'table-cell' }}> <h4 style = {{ textAlign: 'center' }}>{t('month')}</h4></div>
          <div style={{ display: 'table-cell' }}> <h4 style = {{ textAlign: 'center' }}>{t('day')}</h4></div>
        </div>
    </div>

    : <div style={{
      display: 'table',
      tableLayout: 'fixed',
      width: '100%'
    }}>
      <div style={{ display: 'table-cell' }}><h4 style = {{ textAlign: 'center' }}>{t('year')}</h4></div>
      <div style={{ display: 'table-cell' }}><h4 style = {{ textAlign: 'center' }}>{t('month')}</h4></div>
      <div style={{ display: 'table-cell' }}><h4 style = {{ textAlign: 'center' }}>{t('day')}</h4></div>
      <div style={{ display: 'table-cell' }}><h4 style = {{ textAlign: 'center' }}>{t('hour')}</h4></div>
      <div style={{ display: 'table-cell' }}><h4 style = {{ textAlign: 'center' }}>{t('minute')}</h4></div>
    </div>;

  return (
    <div>
      <Picker
        locale={enUs}
        data={[dateIntervalKeys]}
        cols={1}
        title={t('dateInterval')}
        cascade={false}
        value={custom === 'custom' ? [custom] : ''}
        onOk={itemValue => selectCustom(itemValue[0])}
        okText={t('ok')}
        dismissText={t('cancel')}
      >
        <List.Item arrow='horizontal'>{t('dateInterval')}</List.Item>
      </Picker>
      {custom === 'custom' && (
        <>
          <h3 style={{ marginLeft: 13, marginBottom: 5 }}>{t('Min')}</h3>
          {datePickerFieldTitles}
          <DatePickerView
            locale={enUs}
            mode={isDateTime ? 'datetime' : 'date'}
            minDate={new Date(min)}
            maxDate={new Date(max)}
            value={new Date(minimumDate)}
            onChange={value => {
              setValue(moment(value).format('YYYY-MM-DD HH:mm:ss'), 'min')
            }}
          />
          <h3 style={{ marginLeft: 13, marginBottom: 5 }}>{t('Max')}</h3>
          {datePickerFieldTitles}
          <DatePickerView
            locale={enUs}
            mode={isDateTime ? 'datetime' : 'date'}
            minDate={new Date(min)}
            maxDate={new Date(max)}
            value={new Date(maximumDate)}
            onChange={value =>
              setValue(moment(value).format('YYYY-MM-DD HH:mm:ss'), 'max')
            }
          />
        </>
      )}
    </div>
  )
}

export default DatePickerWidget
