import React, { useState, useEffect } from 'react'
import { SearchBar, Button } from 'antd-mobile'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classes from './Searchbar.module.css'

const Search = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = () => {
    window.scrollTo(0, 0)
    dispatch({
      type: 'SET_SEARCH_PARAMETERS',
      searchParams: { searchValue }
    })
  }

  useEffect(() => {
    if (searchValue === '') {
      dispatch({
        type: 'SET_SEARCH_PARAMETERS',
        searchParams: { searchValue: '' }
      })
    }
  }, [searchValue, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch({
      type: 'SET_SEARCH_PARAMETERS',
      searchParams: { searchValue  }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <div className={classes.searchbarContainer}>
      <SearchBar
        className={classes.searchbar}
        value={searchValue}
        maxLength={50}
        cancelText={t(' ')}
        onChange={value => setSearchValue(value)}
        onSubmit={handleSearch}
        onClear={() => setSearchValue('')}
      />
      <Button onClick={handleSearch} size='small' className={classes.button}>
        {t('search')}
      </Button>
    </div>
  )
}

export default React.memo(Search)
