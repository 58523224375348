export function fetchData (
  id,
  refresh,
  type,
  dashboard,
  filterQuery,
  dateAggregate = 'auto'
) {
  let dataUrl = `/data/?dashboard=${dashboard}&id=${id}&type=${type}`
  if (type !== 'info_cell') {
    dataUrl = dataUrl + `&date_aggregate=${dateAggregate}`;
  }
  if (refresh) {
    dataUrl = dataUrl + `&refresh_cache=${refresh}`
  }
  if (filterQuery) {
    dataUrl = dataUrl + `&filters=${filterQuery}`
  }
  return {
    type: 'LOAD_DATA',
    payload: {
      request: {
        url: dataUrl
      }
    }
  }
}

export function fetchMapData (id, refresh, dashboard, filterQuery) {
  let dataUrl = `/data/?dashboard=${dashboard}&id=${id}&type=map`
  if (refresh) {
    dataUrl = dataUrl + `&refresh_cache=${refresh}`
  }
  if (filterQuery) {
    dataUrl = dataUrl + `&filters=${filterQuery}`
  }
  return {
    type: 'LOAD_DATA',
    payload: {
      request: {
        url: dataUrl
      }
    }
  }
}

export function fetchHTMLData (id, dashboard, filterQuery) {
  let dataUrl = `/htmlwidgetdata/?dashboard=${dashboard}&id=${id}`
  if (filterQuery) {
    dataUrl = dataUrl + `&filters=${filterQuery}`
  }
  return {
    type: 'LOAD_DATA',
    payload: {
      request: {
        url: dataUrl
      }
    }
  }
}

export function fetchGridData (
  model,
  refresh,
  type,
  dashboard,
  filterQuery,
  filteringQuery,
  page = 1
) {
  const { limit_to = 10, id } = model
  let dataUrl = `/data/?dashboard=${dashboard}&id=${id}&type=${type}&page=${page}&page_size=${limit_to === null ? 10 : limit_to}`
  if (refresh) {
    dataUrl = dataUrl + `&refresh_cache=${refresh}`
  }
  if (filterQuery) {
    dataUrl = dataUrl + `&filters=${filterQuery}`
  }
  if (filteringQuery) {
    dataUrl = dataUrl + `&filtering=${encodeURIComponent(filteringQuery)}`
  }
  return {
    type: 'LOAD_DATA',
    payload: {
      request: {
        url: dataUrl
      }
    }
  }
}

export function fetchExpandedRow (
  id,
  pivotFilter,
  pivotHeaders,
  pivotHeadersDim
) {
  let partial = Math.floor(Math.random() * 90000) + 10000
  return {
    type: 'LOAD_EXP_DATA',
    payload: {
      request: {
        url: `/data/?expanded_type=row&id=${id}&partial=${partial}&type=pivot&rowLevel=1&pivot_headers=${pivotHeaders}pivot_headers_dimension=${pivotHeadersDim}filtering=${pivotFilter}`
      }
    }
  }
}

export function createData (data) {
  return {
    type: 'CREATE_DATA',
    data
  }
}
