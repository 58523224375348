import React from 'react'
import { Button, WingBlank, NavBar, Modal } from 'antd-mobile'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { logoutUser } from '../../../store/actions/auth'
import classes from './SessionControlPage.module.css'

const SessionControlPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const handleEndSessions = () => {
    Modal.alert(t('warning'), t('logoutAlert'), [
      { text: t('cancel'), onPress: () => false },
      {
        text: t('ok'),
        onPress: () => {
          dispatch(logoutUser(true))
          history.push('/login')
        }
      }
    ])
  }

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => history.push('/profile')}
          />
        ]}
      >
        {t('sessionControl')}
      </NavBar>
      <WingBlank>
        <p className={classes.info}>{t('logoutFromAllDevicesMessage')}</p>
        <Button size='small' type='warning' onClick={handleEndSessions}>
          {t('logoutFromAllDevices')}
        </Button>
      </WingBlank>
    </>
  )
}

export default SessionControlPage
