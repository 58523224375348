const initialState = {fetching: false, entries: {}}

export default function views (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_VIEW':
      state.fetching = true
      return {...state}
    case 'FETCH_VIEW_SUCCESS':
      state.fetching = false
      return {...state, 'entries': action.listofshowcases}
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
