import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import axiosMiddleware from 'redux-axios-middleware'
import ReduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import listofshowcases from './reducers/listofshowcases'
import listofdashboards from './reducers/listofdashboards'
import showcases from './reducers/showcases'
import dashboards from './reducers/dashboards'
import auth from './reducers/auth'
import views from './reducers/views'
import data from './reducers/data'
import showcasemenu from './reducers/showcasemenu'
import dashboardmenu from './reducers/dashboardmenu'
import currentfilters from './reducers/currentfilters'
import theme from './reducers/theme'
import favoriteShowcases from './reducers/favoriteShowcases'
import favoriteDashboards from './reducers/favoriteDashboards'
import recentShowcases from './reducers/recentShowcases'
import recentDashboards from './reducers/recentDashboards'
import settings from './reducers/settings'
import showBars from './reducers/showBars'
import singledashboard from './reducers/singledashboard'
import filteredData from "./reducers/filteredData"
import rowClickCheck from "./reducers/rowClickCheck"
import axios from 'axios'
import { JsonApiDataStore } from 'jsonapi-datastore'
import AppConfig from '../config/appConfig'
import _ from 'lodash'

let composeEnhancers = null
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
} else {
  composeEnhancers = compose
}

const configureClient = () => {
  const client = axios.create({
    baseURL: AppConfig.apiUrl,
    responseType: 'json'
  })

  client.defaults.headers.common['Content-Type'] = 'application/vnd.api+json'
  client.defaults.headers.common['Accept'] = 'application/json, text/javascript, */*; q=0.0'

  return client
}

export const client = configureClient()


const authPersistConfig = {
  key: 'ember_simple_auth-session',
  keyPrefix: '',
  serialize: (data) => {
    return JSON.stringify(data, function(key, value) {
      if(key === 'authenticated') {
        return JSON.parse(value)
      }
      return value
    })
  },
  deserialize: (data) => {
    let parsed = JSON.parse(data)
    if(_.has(parsed, 'authenticated')) {
      _.set(parsed, 'token', JSON.stringify(_.get(parsed, 'authenticated.token')))
      _.set(parsed, 'authenticated',JSON.stringify(_.get(parsed, 'authenticated')))
    }
    return parsed
  },
  storage: storage,
}

const configureStore = () => {

  const rootReducer = combineReducers({
    auth:persistReducer(authPersistConfig, auth),
    listofshowcases,
    listofdashboards,
    showcases,
    dashboards,
    data,
    showcasemenu,
    dashboardmenu,
    currentfilters,
    views,
    theme,
    favoriteShowcases,
    favoriteDashboards,
    singledashboard,
    recentShowcases,
    recentDashboards,
    settings,
    showBars,
    filteredData,
    rowClickCheck
  })

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['settings', 'favoriteShowcases', 'favoriteDashboards', 'recentShowcases', 'recentDashboards', 'theme']
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(ReduxThunk, axiosMiddleware(client)))
  )

  return store
}

export async function findRecord (type, id) {
  let result = null
  let url = `/${type}/${id}/`
  let response = await client.get(url)
  if (_.get(response, 'data.data')) {
    dataStore.sync(_.get(response, 'data'))
    result = dataStore.find(type, id)
  }
  return result
}

export const store = configureStore()
export const persistor = persistStore(store)
export const dataStore = new JsonApiDataStore()
