export default function (DS) {
  return {
    id: DS.attr('string'),
    // dashlet: DS.belongsTo('dashlet'),
    type: DS.attr('string'),
    axis: DS.attr('string'),
    value: DS.attr('number'),
    color: DS.attr('string'),
    label: DS.attr('string'),
    multiplier: DS.attr('number'),
    dash_style: DS.attr('string'),
    func: DS.attr('string'),
    is_expression: DS.attr('boolean'),
    measure: DS.belongsTo('node'),
    expression: DS.belongsTo('sqlexpression')
  }
}
