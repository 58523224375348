const initialState = {
  language: '',
  SSOEnabled: false,
  appWhiteLogo: '',
  appDarkLogo: '',
  SSOAuthUrl: '',
  SSOLogoutUrl: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        language: action.language
      }
    case 'SET_GLOBAL_SETTINGS':
        return {
          ...state,
          ...action.settings
        }
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
