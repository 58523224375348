import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { WhiteSpace } from 'antd-mobile'
import { authenticate } from '../../store/actions/auth'
import { showAlert, parseQuery } from '../../helpers/utils'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from '../../store/actions/settings'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import _ from 'lodash'
import i18next from 'i18next'
import classes from './LoginPage.module.css'
import AppConfig from '../../config/appConfig'
import CustomLogin from '../../components/login/CustomLogin/CustomLogin'
import LoginOptions from '../../components/login/LoginOptions/LoginOptions'

const LANGUAGE_OPTIONS = [
  { value: 'tr', label: 'Türkçe' },
  { value: 'en', label: 'English' }
]

const LoginPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { search } = useLocation()
  const {
    language,
    appWhiteLogo,
    appDarkLogo,
    SSOEnabled,
    SSOAuthUrl,
  } = useSelector(state => _.get(state, 'settings'))

  const [loading, setLoading] = useState(false)
  const [loginType, setLoginType] = useState('')

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language)
    } else {
      dispatch(changeLanguage(i18next.language))
    }
  }, [language, dispatch])

  const authenticateUser = useCallback(
    async formData => {
      setLoading(true)
      try {
        await dispatch(authenticate(formData))
        history.push('/showcases')
      } catch (error) {
        const message = _.get(error, 'message')
        let readableSuspensionMessage
        if (message === 'wrong_creds') {
          showAlert(t('error'), t('wrong_creds'), t('close'))
        } else if (['banned1', 'banned10', 'banned60'].includes(message)) {
          readableSuspensionMessage = t(message)
        } else if (message.includes('remaining_banned')) {
          const splitted = message.split('|')
          readableSuspensionMessage = t(splitted[0], {
            seconds: splitted[1]
          })
        }
        if (readableSuspensionMessage) {
          showAlert(t('error'), readableSuspensionMessage, t('close'))
        }
      } finally {
        setLoading(false)
      }
    },
    [dispatch, history, t]
  )

  useEffect(() => {
    let splitter = '?code='
    if (search.includes(splitter)) {
      const queryParams = parseQuery(search)
      let formData = {
        _type: 'oidc',
        code: _.get(queryParams, "code"),
        state: _.get(queryParams, "state")
      }
      authenticateUser(formData)
    }
  }, [authenticateUser, search])

  const setAppLanguage = language => {
    dispatch(changeLanguage(language))
    i18next.changeLanguage(language)
  }

  let children = (
    <CustomLogin
      loading={loading}
      authenticateUser={authenticateUser}
      SSOEnabled={SSOEnabled}
      goBackToLoginOptions={() => setLoginType('')}
    />
  )
  if (SSOEnabled && !loginType) {
    children = (
      <LoginOptions
        handleLoginType={type => setLoginType(type)}
        SSOAuthUrl={SSOAuthUrl}
      />
    )
  }

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: SSOEnabled ? '#f6f7f2' : '#221b44' }}
    >
      <div className={classes.subContainer}>
        <img
          className='w-p100'
          src={`${AppConfig.baseUrl}${(SSOEnabled
            ? appDarkLogo
            : appWhiteLogo) || '/assets/images/logo-white.svg'}`}
          alt='logo'
        />
      </div>
      <div className={`${classes.subContainer} ${classes.inputContainer}`}>
        {children}
        <WhiteSpace size='lg' />
        {!SSOEnabled && (
          <div className={classes.optionsContainer}>
            <Link to='/forgot-password' className={classes.resetPasswordLink}>
              {t('forgetPassword')}
            </Link>
            <div className='lang-dropdown'>
              <Dropdown
                options={LANGUAGE_OPTIONS}
                onChange={({ value }) => setAppLanguage(value)}
                value={language}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginPage
