import _ from 'lodash'
// eslint-disable-next-line no-unused-vars
import DS from "./model-data";
// import i18n from '../i18n/index'

export default function(DS) {
  return {
    duplicate_id: DS.attr('string'),
    title: DS.attr('string', {'maxLength':255}),
    passive_title: DS.attr('string', {'maxLength':255}),
    type: DS.attr('string'),
    is_default_datacells_dashlet: DS.attr('boolean'),
    is_sampling: DS.attr('boolean'),
    sampling: DS.belongsTo('sampling'),
    chart_type: DS.attr('string'),
    gauge_type: DS.attr('string'),
    gauge_thick_circle: DS.attr('boolean'),
    gauge_direction: DS.attr('string'),
    gauge_value_background: DS.attr('boolean'),
    wordcloud_shape_type:  DS.attr('string'),
    wordcloud_font_size_range:  DS.attr(),
    secondary_type: DS.attr('string'),
    legend_type: DS.attr('string'),
    show_values_in_legend: DS.attr('boolean'),
    totals_type: DS.attr('string'),
    totals_position: DS.attr('string'),
    zoom_type: DS.attr('string'),
    auto_title: DS.attr('number'),
    regression_type: DS.attr('string'),
    regression_zeros: DS.attr('string'),
    show_trendline: DS.attr('boolean'),
    show_moving_average: DS.attr('boolean'),
    trendline_algorithm: DS.attr('string', {defaultValue: 'SMA'}),
    trendline_algorithm_period: DS.attr('number', {defaultValue: 8}),
    show_trendline_angle: DS.attr('boolean'),
    trendline_label_background_color: DS.attr('boolean'),
    dynamic_dimensions: DS.attr('boolean'),
    show_datalabels: DS.attr('boolean'),
    manual_data_label: DS.attr('boolean'),
    logarithmic_axis: DS.attr('boolean'),
    immediate_slicer: DS.attr('boolean'),
    zero_yaxis: DS.attr('boolean'),
    axis_stringency: DS.attr('string'),
    show_drill_through_dashlets_in_tabs: DS.attr('boolean'),
    show_drill_through_dashlets_in_layers: DS.attr('boolean'),
    hide_void_dimensions: DS.attr('boolean'),
    hide_zero_valued_dimensions: DS.attr('boolean'),
    show_chart_title: DS.attr('boolean'),
    show_axis_titles: DS.attr('boolean'),
    must_have_filter: DS.attr('number'), // == -1 any filter, == 0 unused, > 0 node id
    inverted: DS.attr('boolean'),
    description: DS.attr('string'),
    updated_at: DS.attr('date'),
    created_at: DS.attr('date'),
    view: DS.belongsTo('view'),
    date_aggregate: DS.attr('string'),
    formatted_hint_content: DS.attr('string'),
    measures: DS.hasMany('dashletmeasure', {async: false, sort: 'order'}),
    color_measures: DS.hasMany('dashletcolormeasure', {async: false, sort: 'order'}),
    size_measures: DS.hasMany('dashletsizemeasure', {async: false, sort: 'order'}),
    hint_measures: DS.hasMany('dashlethintmeasure', {async: false, sort: 'order'}),
    calculated_measures: DS.hasMany('dashletcalculatedmeasure', {async: false, sort: 'order'}),
    dimensions: DS.hasMany('dashletdimension', {async: false, sort: 'order'}),
    pivot_sortings: DS.hasMany('dashletpivotsorting', {async: false, sort: 'created_at'}),
    color_dimensions: DS.hasMany('dashletcolordimension', {async: false, sort: 'order'}),
    geometry_fields: DS.hasMany('dashletgeometryfield', {async: false, sort: 'alias'}),
    drilldowndimensions: DS.hasMany('dashletdrilldowndimension', {async: false, sort: 'sort'}),
    drillthroughdashlets: DS.hasMany('drillthroughdashlet', {async: false, sort: 'sort'}),
    datacells: DS.hasMany('dashletdatacell', {async: false, sort: 'order'}),
    selected_dynamic_dimensions: DS.hasMany('dashletdynamicdimension', {async: false, sort: 'order'}),
    selected_dynamic_measures: DS.hasMany('dashletdynamicmeasure', {async: false, sort: 'order'}),
    gismap_layers: DS.hasMany('dashletgismaplayer', {async: false, sort: 'order'}),
    restrictions: DS.hasMany('dashletrestriction', {async: false, inverse:'dashlet'}),
    expression_restrictions: DS.hasMany('dashletexpressionrestriction', {async: false}),
    reference_measures: DS.hasMany('dashletreferencemeasure', {async: false, sort: 'order'}),
    conditional_formatting_entries: DS.hasMany('dashletconditionalformatting', {async: false}),
    thumbnail: DS.attr('string'),
    title_infocell: DS.attr('string'),
    icon: DS.attr('string'),
    icon_style: DS.attr('string'),
    color: DS.attr('string'),
    unit_text: DS.attr('string'),
    unit_placement: DS.attr('string'),
    dashboards: DS.attr(),
    thresholds: DS.attr('string'),
    threshold_type: DS.attr('string'),
    owner: DS.attr('number'),
    limit_results: DS.attr('boolean'),
    initial_filter: DS.attr('boolean', {defaultValue: null}),
    limit_to: DS.attr('number'),
    reverse_limit_direction: DS.attr('boolean'),
    highlight_one_dimension_attribute: DS.attr('boolean'),
    show_rest_as_one_dimension: DS.attr('boolean'),
    owner_full_name: DS.attr('string'),
    reference_line: DS.attr(),
    reference_lines: DS.hasMany('dashletreferencelines', {async: false, sort: 'order'}),
    manual_axis_values: DS.attr('boolean', {defaultValue: false}),
    manual_axis_min: DS.attr('number'),
    manual_axis_max: DS.attr('number'),
    show_formatted_hints: DS.attr('boolean'),
    master_detail_type: DS.attr('string'),
    auto_refresh: DS.attr(),
    auto_refresh_period: DS.attr('number'),
    legend_color_scale: DS.attr(),
    color_count: DS.attr('number', {defaultValue: 1}),
    legend_color_scale_max: DS.attr('string'),
    legend_color_scale_mid: DS.attr('string'),
    legend_color_scale_min: DS.attr('string'),
    map_scroll_wheel_zoom: DS.attr('boolean'),
    visual_map_position: DS.attr('string'),
    hide_visual_map: DS.attr('boolean', {defaultValue:false}),
    show_map_label_figure_type: DS.attr('string', {defaultValue: 'dimension'}),
    is_percentage_yaxis: DS.attr('boolean'),
    percentage_dimension_index: DS.attr('number'),
    show_only_percentage:  DS.attr('boolean'),
    show_filters_separately: DS.attr('boolean'),
    analytics_correlation_threshold: DS.attr('number'),
    color_dimension: DS.attr('string'),
    default_measure: DS.attr(),
    wordwrap: DS.attr('boolean'),
    is_char_limited: DS.attr('boolean'),
    char_limit: DS.attr('number', {defaultValue: 100}),
    is_auto_generated: DS.attr('boolean'),
    default_measure_func: DS.attr(),
    has_color_dimension: DS.attr('boolean'),
    use_dynamic_measures: DS.attr('boolean', {defaultValue: false}),
    use_vertical_measures: DS.attr('boolean', {defaultValue: false}),
    custom_column_titles: DS.attr('boolean'),
    custom_column_titles_values_text: DS.attr('string'),
    custom_column_titles_measures_text: DS.attr('string'),
    auto_width: DS.attr('boolean', {defaultValue: true}),
    resizable: DS.attr('boolean'),
    inverted_color: DS.attr('boolean'),
    table_celled: DS.attr('boolean'),
    table_striped: DS.attr('boolean'),
    table_column_horizontal_alignment: DS.attr('string'),
    table_column_vertical_alignment: DS.attr('string'),
    table_column_alignment_headers: DS.attr('string'),
    background_color: DS.attr('string'),
    table_size: DS.attr('string'),
    _embed_code: DS.attr('string'),
    is_datasource_cacheable: DS.attr('boolean'),
    is_cacheable: DS.attr('boolean', {defaultValue: true}),
    auto_preview: DS.attr('boolean', {defaultValue: true}),
    heatmap_radius_enabled: DS.attr('boolean'),
    boost: DS.attr('boolean'),
    encrypt_data: DS.attr('boolean'),
    heatmap_radius: DS.attr('number', {defaultValue: 35}),
    fixed_zoom_position: DS.attr('boolean'),
    difference_by_date_comparison: DS.attr('boolean'),
    difference_by_date_comparison_option: DS.attr('number'),
    temporal_node: DS.belongsTo('node'),
    motion_dimension: DS.belongsTo('node'),
    temporal_node_year: DS.attr('number'),
    has_motion_dimension: DS.attr('boolean'),
    motion_interval: DS.attr('number', {defaultValue: 3}),
    motion_dimension_date_aggregate: DS.attr('string'),
    obfuscate_other_dimension_values: DS.attr('boolean'),
    _meta: DS.attr(),
    monitors: DS.hasMany('dashletmonitor'),
    y_axis_manual_label: DS.attr('boolean'),
    hide_y_axis: DS.attr('boolean', {defaultValue: false}),
    hide_x_axis_lines: DS.attr('boolean', {defaultValue: false}),
    hide_y_axis_lines: DS.attr('boolean', {defaultValue: false}),
    x_axis_manual_label: DS.attr('boolean'),
    x_axis_manual_label_type: DS.attr('string'),
    x_axis_manual_label_rotation: DS.attr('number'),
    x_axis_manual_label_ellipsis: DS.attr('number', {defaultValue: 10}),
    x_axis_manual_label_is_ellipsis: DS.attr('boolean', {defaultValue: true}),
    x_axis_manual_label_font_size: DS.attr('string'),
    x_axis_manual_label_rotate: DS.attr('boolean'),
    x_axis_manual_label_auto_space: DS.attr('boolean'),
    formatting: DS.attr('string'),
    x_axis_manual_label_font_bold: DS.attr('boolean', {defaultValue: false}),
    data_label_font_size: DS.attr('string', {defaultValue: 12}),
    data_label_font_bold: DS.attr('boolean', {defaultValue: true}),
    data_label_show_vertical: DS.attr('boolean', {defaultValue: false}),
    data_label_show_interval: DS.attr('boolean', {defaultValue: false}),
    data_label_ellipsis: DS.attr('number', {defaultValue: 10}),
    measure_thresholds_affected_by_filter: DS.attr('boolean'),
    permissions: DS.attr(),
    shares: DS.attr(),
    owner_id: DS.attr('number'),
    navigator_min_range: DS.attr('number', {defaultValue: null}),
    is_public: DS.attr('boolean'),
    modifier_full_name: DS.attr('string'),
    modifier_id: DS.attr('number'),
    is_all_rows_expanded: DS.attr('boolean', {defaultValue: false}),
    expand_level: DS.attr('number', {defaultValue: 0}),
    show_drill_through_dashlets_as_merged: DS.attr('boolean'),
    intercept_first_dimension_filter: DS.attr('boolean'),
    is_rosetype: DS.attr('boolean', {defaultValue: false}),
    rose_type: DS.attr('string', {defaultValue: 'radius'}),
    has_sidewall_width: DS.attr('boolean', {defaultValue: false}),
    sidewall_width: DS.attr('string', {defaultValue: 'normal'}),
    info_cell_figure_font_size: DS.attr('string'),
    info_cell_figure_is_bold: DS.attr('boolean', {defaultValue: true}),
    info_cell_title_font_size: DS.attr('string'),
    info_cell_title_is_bold: DS.attr('boolean'),
    info_cell_show_percentage_of_total: DS.attr('boolean'),
    info_cell_column_count: DS.attr('number'),
    info_cell_percentage_filter_lock: DS.attr('boolean'),
    info_cell_percentage_filter_lock_item: DS.belongsTo('filter'),
    pt_header_font_size: DS.attr('string'),
    pt_data_font_size: DS.attr('string'),
    pt_header_font_is_bold: DS.attr('boolean', {defaultValue: true}),
    pt_data_font_is_bold: DS.attr('boolean'),
    pt_page_size: DS.attr('number'),
    view_id: DS.attr('number'),
    manual_chart_rotation: DS.attr('number'),
    is_locked: DS.attr('boolean'),
    graph_neighbor_link_threshold: DS.attr('number'),
    graph_type: DS.attr('string', {defaultValue: 'relaxed'}),
    _drilldown_level_: DS.attr('number'),
    slice_in_only_single_selection: DS.attr('boolean'),
    dimensionsWithDrilldown: DS.computed(function () {
      let drilldown_level = typeof _.get(this, '_drilldown_level_') !== 'undefined' ? _.get(this, '_drilldown_level_') : -1
      let dimensions = _.toArray(_.get(this, 'dimensions'))
      let dimension_serializer = DS.hasMany('dashletdimension')
      dimensions = dimension_serializer(dimensions)
      if (drilldown_level !== -1) {
        let dd_dimension = _.toArray(_.get(this, 'drilldowndimensions'))[drilldown_level]
        let dimension_serializer = DS.belongsTo('dashletdrilldowndimension')
        dd_dimension = dimension_serializer(dd_dimension)
        dimensions[0] = dd_dimension
      }
      return dimensions
    }),
    userLang: DS.attr('string')
  }
}
