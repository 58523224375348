import _ from 'lodash'
import { client } from '../configureStore'
import { changeLanguage } from './settings'
import i18next from 'i18next'
import numeral from 'numeral'
import 'numeral/locales/tr'

export const createUser = token => {
  return async dispatch => {
    client.defaults.headers.common['Authorization'] = `Token ${token}`
    const user = await client.get('/users/currentUser/')

    if (_.get(user, 'data')) {
      const {
        first_name: firstName,
        last_name: lastName,
        username,
        email_address: email,
        language,
        is_intro_closed: isIntroClosed,
        groups,
        allow_in_app_notifications,
        allow_email_notifications
      } = _.get(user, 'data.data.attributes')

      let userData = {
        token,
        firstName,
        lastName,
        username,
        userId: _.get(user, 'data.data.id'),
        email,
        language,
        isIntroClosed,
        groups,
        allow_in_app_notifications,
        allow_email_notifications
      }

      dispatch({
        type: 'CREATE_USER',
        userData
      })

      dispatch(changeLanguage(language))
      i18next.changeLanguage(language)
      numeral.locale(language)

    }
  }
}

export const authenticate = formData => {
  return async dispatch  => {
    try {
      const response = await client.post('/login/', formData)
      const token = _.get(response, 'data.token')
      if (token) {
        dispatch(createUser(token))
      }
    } catch (error) {
      const message = _.get(error, 'response.data.0')
      if (message) {
        throw new Error(message)
      }
    }
  }
}

export function logoutUser (allDevices = false, logoutUrl) {
  return async dispatch => {
    try {
      let endSessionsUrl = `/logout/`
      if (allDevices) {
        endSessionsUrl = `/logout-from-all/`
      }
      await client.delete(endSessionsUrl)
    } catch (error) {
      console.error(error)
    } finally {
      client.defaults.headers.common['Authorization'] = ''
      dispatch({
        type: 'LOGOUT'
      })
      if(logoutUrl !== null) {
        window.location.href = logoutUrl
      }
    }
  }
}
