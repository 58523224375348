import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, WingBlank, NavBar, Modal } from 'antd-mobile'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { persistor,  dataStore } from '../../../store/configureStore'
import { logoutUser } from '../../../store/actions/auth'
import classes from './ResetApplicationPage.module.css'

const ResetApplicationPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const resetApplication = async () => {
    try {
      dataStore.reset()
      await persistor.purge()
      await dispatch(logoutUser())
    } catch (error) {
      console.error(error)
    }
  }

  const handleClearCache = () => {
    Modal.alert(t('warning'), t('resetAppAlert'), [
      { text: t('cancel'), onPress: () => false },
      {
        text: t('ok'),
        onPress: resetApplication
      }
    ])
  }

  const resetInfo = [
    'selectedTheme',
    'serverUrl',
    'favoriteShowcases',
    'recentlyOpenedShowcases',
    'languageSetting'
  ]

  return (
    <>
      <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-arrow-left'
            className='fa fa-arrow-left fa-lg'
            onClick={() => history.push('/profile')}
          />
        ]}
      >
        {t('clearCacheMenu')}
      </NavBar>
      <WingBlank>
        <p className={classes.info}>{t('resetInfo')}</p>
        <ul className={classes.listContainer}>
          {resetInfo.map((info, index) => (
            <ul className={classes.listItem} key={index}>
              {`- ${t(info)}`}
            </ul>
          ))}
        </ul>
        <Button size='small' type='warning' onClick={handleClearCache}>
          {t('clearCache')}
        </Button>
      </WingBlank>
    </>
  )
}

export default ResetApplicationPage
