import {get} from 'lodash'
const initialState = {token:null}

export default function auth (state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USER':
      return { ...state, ...action.userData , ...{authenticated:{token:get(action, 'userData.token'), authenticator: "authenticator:turboard-authenticator"}}}
    case 'LOGOUT':
      return { ...state, token: null, authenticated:{}}
    case 'PURGE_STATE':
      return initialState
    default:
      return state
  }
}
