import React, { useState, useEffect, useMemo } from 'react'
import { NavBar, Grid, ActivityIndicator } from 'antd-mobile'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import MenuSidebar from '../../components/menu-sidebar/MenuSidebar'
import { useTranslation } from 'react-i18next'
import BottomTab from '../../components/dashboard-list/BottomTab/BottomTab'
import useWindowDimensions from '../../custom-hooks/useWindowDimenisons'
import Card from '../../components/dashboard-list/Card/Card'
import { fetchDashboards } from '../../store/actions/listofdashboards'
import InfiniteScroll from 'react-infinite-scroll-component'
import Searchbar from '../../components/dashboard-list/SearchBar/SearchBar'
import classes from './DashboardListpage.module.css'

const DashboardList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let { width } = useWindowDimensions()
  const listofdashboards = useSelector(state => state.listofdashboards)
  const { dashboardList } = listofdashboards

  const {
    searchValue,
    currentPage,
    hasMoreDashboards,
    owner,
    ordering
  } = listofdashboards.searchParameters

  const favoriteDashboardList = useSelector(state => state.favoriteDashboards)
  const recentDashboardList = useSelector(state => state.recentDashboards)

  const [headerBarTitle, setHeaderBarTitle] = useState('allDashboards')
  const [showSidebar, setShowSidebar] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filteredDashboardList, setFilteredDashboardList] = useState(dashboardList)
  const [noData, setNoData] = useState(false)
  const [tabButton, setTabButton] = useState('all')
  const [showSearchbar, setShowSearchbar] = useState(false)

  const showBars = useSelector((state) => {
    return _.get(state, 'showBars.showBars')
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [tabButton])

  let pageSize = 10
  if (width >= 960) {
    pageSize = 20
  }

  useEffect(() => {
    if (tabButton === 'all') {
      setHeaderBarTitle('allDashboards')
      const fetchDashboardList = async () => {
        setLoading(true)
        dispatch({ type: 'DESTROY_DASHBOARD_LIST' })
        dispatch({ type: 'SET_CURRENT_PAGE', currentPage: 1 })
        try {
          await dispatch(fetchDashboards(pageSize))
        } catch (error) {
          setNoData(true)
        } finally {
          setLoading(false)
        }
      }

      fetchDashboardList()
    }
  }, [searchValue, dispatch, tabButton, pageSize, owner, ordering])

  useEffect(() => {
    if (tabButton === 'favorites') {
      setHeaderBarTitle('favoriteDashboards')
      dispatch({ type: 'DESTROY_DASHBOARD_LIST' })
      const filteredList = favoriteDashboardList
        .filter(item => item?.title)
        .filter(item =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      setFilteredDashboardList(filteredList)
    } else if (tabButton === 'recent') {
      setHeaderBarTitle('recentlyOpenedDashboards')
      dispatch({ type: 'DESTROY_DASHBOARD_LIST' })
      const filteredList = recentDashboardList
        .filter(item => item?.title)
        .filter(item =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      setFilteredDashboardList(filteredList)
    }
  }, [
    searchValue,
    dispatch,
    favoriteDashboardList,
    recentDashboardList,
    tabButton
  ])

  useEffect(() => {
    if (dashboardList) {
      setLoading(false)
    }
    if (Array.isArray(dashboardList) && !dashboardList.length) {
      setNoData(true)
    } else {
      setNoData(false)
    }
    if (tabButton === 'all') {
      setFilteredDashboardList(dashboardList)
    }
  }, [dashboardList, tabButton])

  const loader = useMemo(
    () => (
      <div className={`center-element ${classes.miniLoaderContainer}`}>
        <ActivityIndicator animating size='small' />
      </div>
    ),
    []
  )

  let columnNum = Math.ceil(width / 350)

  let message
  if (
    tabButton === 'favorites' &&
    !favoriteDashboardList.length &&
    !showSearchbar
  ) {
    message = 'noFavoriteDashboards'
  } else if (
    tabButton === 'recent' &&
    !recentDashboardList.length &&
    !showSearchbar
  ) {
    message = 'noRecentDashboards'
  }

  let children
  if ((loading && currentPage === 1) || noData) {
    children = (
      <div className={`center-element ${classes.loaderContainer}`}>
        {loading ? (
          <ActivityIndicator animating size='large' />
        ) : (
          <span>{t('no_home_dashboard')}</span>
        )}
      </div>
    )
  } else if (message) {
    children = (
      <div className={`center-element ${classes.loaderContainer}`}>
        <span>{t(message)}</span>
      </div>
    )
  } else {
    children = (
      <div className={classes.container} style={{marginTop: showSearchbar ? 45 : 0}}>
        <InfiniteScroll
          dataLength={filteredDashboardList?.length || 0}
          next={() => {
            if (tabButton !== 'all') {
              return
            }

            const fetchNewDashboards = async () => {
              dispatch({
                type: 'SET_CURRENT_PAGE',
                currentPage: currentPage + 1
              })
              try {
                await dispatch(fetchDashboards(pageSize))
              } catch (error) {
                setNoData(true)
              }
            }

            if (hasMoreDashboards && tabButton === 'all') {
              fetchNewDashboards()
            }
          }}
          hasMore={tabButton === 'all' && hasMoreDashboards}
          loader={loader}
        >
          <Grid
            square={true}
            hasLine={false}
            data={filteredDashboardList}
            columnNum={columnNum}
            renderItem={dataItem => <Card dataItem={dataItem} />}
          />
        </InfiniteScroll>
      </div>
    )
  }

  return (
    <div className='h-p100'>
      { showBars && <NavBar
        className='navigationBar'
        mode='light'
        leftContent={[
          <i
            key='fa-bars'
            className='fa fa-bars fa-lg'
            onClick={() => setShowSidebar(showSide => !showSide)}
          ></i>
        ]}
        rightContent={[
          <i
            key='fa-bars'
            className='fa fa-search fa-lg'
            onClick={() => {
              if (showSidebar) {
                setShowSidebar(false)
              }
              dispatch({ type: 'RESET_SEARCH_PARAMS' })
              setShowSearchbar(showSearch => !showSearch)
            }}
          ></i>
        ]}
      >
        <span>{t(headerBarTitle)}</span>
      </NavBar>}
      {showSearchbar && <Searchbar tabButton={tabButton} />}
      <MenuSidebar
        showSidebar={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
      />
      {children}
      {/* {showBars && <BottomTab tabButton={tabButton} setTabButton={setTabButton} />} */}
    </div>
  )
}

export default DashboardList
