import types from './index'
import _ from 'lodash'

export default class DS {
  static attr(type, options) {
    return function (obj) {
      if (obj === null && options && options.defaultValue) {
        return options.defaultValue
      }
      return obj
    }
  }

  static belongsTo(type) {
    return function (obj, parent) {
      let serialized = {}
      let constructor_class = types(DS, type)
      if (constructor_class) {
        _.each(constructor_class, (func, key) => {
          if (typeof func !== 'function') {
            serialized[key] = func
          } else {
            serialized[key] = func(_.get(obj, key), obj)
          }
        })
      }
      return serialized
    }
  }

  static hasMany(type) {
    return function (obj_list, parent) {
      let serialized = []
      if (types(DS, type)) {
        _.each(obj_list, (obj) => {
          let serializer_class = DS.belongsTo(type)
          serialized.push(serializer_class(obj))
        })
      }
      return serialized
    }
  }

  static computed(func) {
    return function (obj, parent) {
      return func.bind(parent)()
    }
  }
}