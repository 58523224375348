
export default function(DS) {
  return {
    id: DS.attr('string'),
    alias: DS.attr('string'),
    custom_alias: DS.attr('string'),
    //dashlet: DS.belongsTo('dashlet', {inverse: 'dimensions'}),
    dimension: DS.belongsTo('node'),
    filter: DS.belongsTo('filter'),
    has_custom_sorting_values: DS.attr('boolean'),
    position: DS.attr('string'),
    query_name: DS.attr('string'),
    order: DS.attr('number'),
    is_dimension: DS.attr('boolean'),
    _node_types: DS.attr(),
    expression: DS.belongsTo('sqlexpression'),
    highlighted_attribute: DS.attr('string'),
    attributes_for_symmetrical_double_bar: DS.attr({defaultValue: []}),
    sort: DS.attr('string'),
    _node_sort: DS.attr('string'),
    field_name: DS.attr('string'),
    data_type: DS.attr('string'),
    date_aggregate: DS.attr('string'),
    sdo_gtype: DS.attr('number'),
    sdo_gtype_name: DS.attr('string'),
    entityType: "dimension"
  }
}
