import React from 'react'
import { TabBar } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import { useStoryBoard } from '../../../custom-hooks/useStoryBoard'

const BottomTab = () => {
    const { t } = useTranslation()
    const { prevItem, goPrev, nextItem, goNext} = useStoryBoard()

    return (
        <TabBar
            unselectedTintColor='#999'
            tintColor='#000'
            barTintColor='#F6F7F2'
            hidden={false}
        >
            {prevItem ? <TabBar.Item
                icon={
                    <i
                        style={{color: 'rgb(51, 51, 51)'}}
                        className='fa fa-2x fa-long-arrow-left'
                        onClick={() => goPrev()}
                    />
                }
                title={t('previous')}
                key='prev'
                onPress={() => "ok"}
            /> : ''}
            {nextItem ? <TabBar.Item
                icon={
                    <i
                        style={{color: 'rgb(51, 51, 51)'}}
                        className='fa fa-2x fa-long-arrow-right'
                        onClick={() => goNext()}
                    />
                }
                title={t('next')}
                key='next'
                onPress={() => "ok"}
            /> : ''}
        </TabBar>
    )
}

export default BottomTab
