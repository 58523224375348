import { get } from 'lodash'
import { client } from '../configureStore'

export const fetchSettings = () => {
  return async (dispatch)  => {
    try {
      const response = await client.get('/appsettings/global/')
      if (!response) {
        throw new Error('no_global_settings')
      }
      const { openid_sso_enabled, openid_sso_authorization_url, logo_showcase, openid_sso_logout_url, logo_white_showcase } = get(
        response,
        'data.data.attributes'
      )
      dispatch({
        type: 'SET_GLOBAL_SETTINGS',
        settings: {
          SSOEnabled: openid_sso_enabled,
          appWhiteLogo: logo_showcase,
          appDarkLogo: logo_white_showcase,
          SSOAuthUrl: openid_sso_authorization_url,
          SSOLogoutUrl: openid_sso_logout_url
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const changeLanguage = language => {
  return {
    type: 'CHANGE_LANGUAGE',
    language
  }
}
