export default {
  numeralConfig: {
    dotComma: '0,0.[00]',
    abbrevations: '0.[0a]',
    abbreviations: '0.[0a]',
    currency: '$0,0.00',
    bytes: '0.000b',
    percentages: '0 %'
  },
  DATE_AGGREGATE_TYPES: {
    ALL: [
      {
        title: 'Auto Aggregate',
        value: 'auto',
        method: 'trunc',
        lookup: 'auto',
        engines: []
      },
      {
        title: 'No Aggregate',
        value: 'none',
        method: 'none',
        lookup: 'none',
        engines: []
      },
      {
        title: 'Years, YYYY',
        value: 'extract_year',
        method: 'extract',
        lookup: 'year',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Quarters, Q',
        value: 'extract_quarter',
        method: 'extract',
        lookup: 'quarter',
        engines: ['MYSQL', 'MSSQL', 'POSTGRESQL', 'VERTICA']
      },
      {
        title: 'Months, MM',
        value: 'extract_month',
        method: 'extract',
        lookup: 'month',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Weeks, WW',
        value: 'extract_week',
        method: 'extract',
        lookup: 'week',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA']
      },
      {
        title: 'Days, DD',
        value: 'extract_day',
        method: 'extract',
        lookup: 'day',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Week Days, D',
        value: 'extract_week_day',
        method: 'extract',
        lookup: 'week_day',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA']
      },
      {
        title: 'Hours, HH',
        value: 'extract_hour',
        method: 'extract',
        lookup: 'hour',
        only_datetime: true,
        engines: ['MYSQL', 'MSSQL', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Minutes, MM',
        value: 'extract_minute',
        method: 'extract',
        lookup: 'minute',
        only_datetime: true,
        engines: ['MYSQL', 'MSSQL', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Years, YYYY',
        value: 'trunc_year',
        method: 'trunc',
        next: 'trunc_month',
        lookup: 'year',
        format: 'YYYY',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Years->Months, YYYY-MM',
        value: 'trunc_month',
        method: 'trunc',
        lookup: 'month',
        next: 'trunc_day',
        format: 'YYYY-MM',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Years->Months->Days, YYYY-MM-DD',
        value: 'trunc_day',
        method: 'trunc',
        lookup: 'day',
        next: 'trunc_hour',
        format: 'YYYY-MM-DD',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Years->Months->Days Hours, HH',
        value: 'trunc_hour',
        method: 'trunc',
        lookup: 'hour',
        only_datetime: true,
        next: 'trunc_minute',
        format: 'YYYY-MM-DD HH',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      },
      {
        title: 'Years->Months->Days Hours->Minutes, HH:mm',
        value: 'trunc_minute',
        method: 'trunc',
        lookup: 'minute',
        only_datetime: true,
        next: 'none',
        format: 'YYYY-MM-DD HH:mm',
        engines: ['MYSQL', 'MSSQL', 'ORACLE', 'POSTGRESQL', 'VERTICA', 'IMPALA']
      }
    ],
    ALL_INTERVAL_KEYS: [
      'all',
      'last_10_years',
      'last_year',
      'last_6_months',
      'last_3_months',
      'last_month',
      'last_week',
      'last_day',
      'last_hour',
      'current_day',
      'current_week',
      'current_month',
      'current_year',
      'last_month_online',
      'last_3_months_online',
      'last_year_online',
      'next_day',
      'next_week',
      'next_month',
      'next_year'
    ],
    RELATIVE_DATE_SETTINGS: {
      last_10_years: [{ anchor: 'now', unit: 'year', move: -10, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'year', move: -1, behavior: 'snap_ending' }],
      last_year: [{ anchor: 'now', unit: 'year', move: -1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'year', move: -1, behavior: 'snap_ending' }],
      last_6_months: [{ anchor: 'now', unit: 'month', move: -6, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'month', move: -1, behavior: 'snap_ending' }],
      last_3_months: [{ anchor: 'now', unit: 'month', move: -3, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'month', move: -1, behavior: 'snap_ending' }],
      last_month: [{ anchor: 'now', unit: 'month', move: -1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'month', move: -1, behavior: 'snap_ending' }],
      last_week: [{ anchor: 'now', unit: 'week', move: -1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'week', move: -1, behavior: 'snap_ending' }],
      last_day: [{ anchor: 'now', unit: 'day', move: -1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'day', move: -1, behavior: 'snap_ending' }],
      last_hour: [{ anchor: 'now', unit: 'hour', move: -1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'hour', move: -1, behavior: 'snap_ending' }],
      current_day: [{ anchor: 'now', unit: 'day', move: 0, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'day', move: 0, behavior: 'snap_ending' }],
      current_week: [{ anchor: 'now', unit: 'week', move: 0, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'week', move: 0, behavior: 'snap_ending' }],
      current_month: [{ anchor: 'now', unit: 'month', move: 0, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'month', move: 0, behavior: 'snap_ending' }],
      current_year: [{ anchor: 'now', unit: 'year', move: 0, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'year', move: 0, behavior: 'snap_ending' }],
      last_24_hour_online: [{ anchor: 'now', unit: 'day', move: -1, behavior: 'online' }, { anchor: 'now', unit: 'day', move: 0, behavior: 'online' }],
      last_month_online: [{ anchor: 'now', unit: 'month', move: -1, behavior: 'online' }, { anchor: 'now', unit: 'month', move: 0, behavior: 'online' }],
      last_3_months_online: [{ anchor: 'now', unit: 'month', move: -3, behavior: 'online' }, { anchor: 'now', unit: 'month', move: 0, behavior: 'online' }],
      last_year_online: [{ anchor: 'now', unit: 'year', move: -1, behavior: 'online' }, { anchor: 'now', unit: 'year', move: 0, behavior: 'online' }],
      next_day: [{ anchor: 'now', unit: 'day', move: 1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'day', move: 1, behavior: 'snap_ending' }],
      next_week: [{ anchor: 'now', unit: 'week', move: 1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'week', move: 1, behavior: 'snap_ending' }],
      next_month: [{ anchor: 'now', unit: 'month', move: 1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'month', move: 1, behavior: 'snap_ending' }],
      next_year: [{ anchor: 'now', unit: 'year', move: 1, behavior: 'snap_beginning' }, { anchor: 'now', unit: 'year', move: 1, behavior: 'snap_ending' }]
    },
    INTERVALS: [
      {
        title: 'all',
        value: 'all',
        only_datetime: false,
        sparkline_title: 'all'
      },
      {
        title: 'last_10_years',
        value: '10_years',
        only_datetime: false,
        sparkline_title: '10_years'
      },
      {
        title: 'last_year',
        value: '1_years',
        only_datetime: false,
        sparkline_title: '1_year'
      },
      {
        title: 'last_year_online',
        value: '1_years_online',
        only_datetime: false,
        sparkline_title: '1_year_online'
      },
      {
        title: 'last_6_months',
        value: '6_months',
        only_datetime: false,
        sparkline_title: '6_months'
      },
      {
        title: 'last_3_months',
        value: '3_months',
        only_datetime: false,
        sparkline_title: '3_months'
      },
      {
        title: 'last_3_months_online',
        value: '3_months_online',
        only_datetime: false,
        sparkline_title: '3_months_online'
      },
      {
        title: 'last_month',
        value: '1_months',
        only_datetime: false,
        sparkline_title: '1_month'
      },
      {
        title: 'last_month_online',
        value: '1_months_online',
        only_datetime: false,
        sparkline_title: '1_month_online'
      },
      {
        title: 'last_week',
        value: '7_days',
        only_datetime: false,
        sparkline_title: '1_week'
      },
      {
        title: 'last_day',
        value: '1_days',
        only_datetime: true,
        sparkline_title: '1_day'
      },
      {
        title: 'last_hour',
        value: '1_hours',
        only_datetime: true,
        sparkline_title: '1_hour'
      }
    ]
  },
  DATE_TYPES: ['date', 'time', 'datetime'],
  INTEGER_TYPES: ['numeric', 'smallint', 'integer', 'bigint', 'double'],
  baseUrl: window.API_HOST,
  apiUrl: `${window.API_HOST}/api/v1`,
  // baseUrl: 'https://t.ebalina.com',    //for easy debugging
  // apiUrl: `${'https://t.ebalina.com'}/api/v1`
}
